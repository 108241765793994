import type {Wallet} from '../../NCWalletServer';

export const WALLET_CREATE = Symbol();
export const WALLET_VISIBILITY_CHANGE = Symbol();
export const WALLET_BALANCE_UPDATE = Symbol();

export type WalletStoreEventMap = {
  [WALLET_CREATE]: (wallet: Wallet) => void;
  [WALLET_VISIBILITY_CHANGE]: (wallet: Wallet) => void;
  [WALLET_BALANCE_UPDATE]: () => void;
};
