import type {Millisecond} from '@ncwallet-app/core';
import {sized, useRoot, useTheme, variance} from '@ncwallet-app/core';
import {
  ADVERT_TIMEOUT_IN_SEC,
  INTERVAL,
} from '@ncwallet-app/core/src/PushAdvert/PushAdvertService';
import {TouchableOpacity} from '@ncwallet-app/ui';
import {CrossSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import {now} from 'mobx-utils';
import React, {useEffect, useState} from 'react';
import {Text} from 'react-native';

export type PushAdvertSplashCloseProps = {
  onClose: () => void;
  deadline?: Millisecond;
};

export default observer(function PushAdvertSplashClose({
  onClose,
  deadline,
}: PushAdvertSplashCloseProps) {
  const {localization} = useRoot();
  const theme = useTheme();
  const [secondsLeft, setSecondsLeft] = useState<number>(ADVERT_TIMEOUT_IN_SEC);

  const currentTime = now(INTERVAL);

  useEffect(() => {
    if (deadline) {
      const second = Math.floor((deadline - currentTime) / 1000);
      setSecondsLeft(second >= 0 ? second : 0);
    }
  }, [currentTime, deadline]);

  return (
    <Container onPress={onClose} finished={secondsLeft === 0}>
      {secondsLeft !== 0 && (
        <Seconds>
          {`${secondsLeft} ${localization.getTranslation('secShort').toLowerCase()}`}
        </Seconds>
      )}
      <CloseIcon color={theme.palette.whitey} />
    </Container>
  );
});

const CloseIcon = sized(CrossSvg, 14, 14);

const Container = variance(TouchableOpacity)(theme => ({
  root: {
    paddingHorizontal: 16,
    paddingVertical: 7,
    backgroundColor: theme.palette.blackout,
    opacity: 0.4,
    borderRadius: 50,
    elevation: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  finished: {
    width: 30,
    height: 30,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
}));

const Seconds = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.whitey,
    marginRight: 7,
  },
}));
