import type {WalletId, WalletListItemData} from '@ncwallet-app/core';
import {useStyles, variance} from '@ncwallet-app/core';
import {SafeAreaFlatList, Separator, useIsDimensions} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import type {FlatListProps, ListRenderItem} from 'react-native';
import {Platform, View} from 'react-native';

import EmptyWalletList from '../../../shared/EmptyWalletList';
import WalletListItem from './WalletListItem/WalletListItem';
import WalletListSkeleton from './WalletListSkeleton';

export type WalletListProps = Partial<FlatListProps<WalletListItemData>> & {
  getWallets: () => WalletListItemData[] | undefined;
  onWalletPress: (d: WalletListItemData) => void;
  hideWallet: (id: WalletId) => void;
  onCurrencyAddPress: () => void;
  onBuyCryptoPress?: () => void;
};

const keyExtractor = (entry: WalletListItemData) => entry.id;

export const WalletList = observer((props: WalletListProps) => {
  const {
    getWallets,
    onWalletPress,
    hideWallet,
    onCurrencyAddPress,
    onBuyCryptoPress,
    ...rest
  } = props;
  const data = getWallets();
  const isLg = useIsDimensions('lg');
  const renderItem: ListRenderItem<WalletListItemData> = useCallback(
    ({item}) => (
      <WalletListItem
        data={item}
        hideWallet={() => {
          hideWallet(item.id as WalletId);
        }}
        onPress={() => {
          onWalletPress(item);
        }}
      />
    ),
    [hideWallet, onWalletPress],
  );
  const renderEmptyData = useCallback(
    () =>
      !data ? (
        <WalletListSkeleton itemCount={4} />
      ) : (
        <EmptyWalletList
          onAddPress={onCurrencyAddPress}
          onBuyCryptoPress={onBuyCryptoPress}
        />
      ),
    [data, onBuyCryptoPress, onCurrencyAddPress],
  );

  const styles = useContentStyles();

  const list = (
    <SafeAreaFlatList
      disallowInterruption
      data={data}
      style={isLg && styles.largeStyles}
      contentContainerStyle={
        Platform.OS === 'ios' && styles.iosContentContainerStyle
      }
      extraData={onWalletPress}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={Separator}
      ListEmptyComponent={renderEmptyData}
      renderItem={renderItem}
      {...rest}
    />
  );

  if (Platform.OS === 'ios' && !isLg) {
    return (
      <>
        <BackgroundForIosRefreshControl />
        <ContainerForIosRefreshControl>{list}</ContainerForIosRefreshControl>
      </>
    );
  }

  return list;
});

export const useContentStyles = () =>
  useStyles(theme => ({
    iosContentContainerStyle: {
      backgroundColor: theme.palette.background,
    },
    largeStyles: {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
  }));

const ContainerForIosRefreshControl = variance(View)(() => ({
  root: {
    flex: 1,
    zIndex: 2,
  },
}));

const BackgroundForIosRefreshControl = variance(View)(theme => ({
  root: {
    position: 'absolute',
    backgroundColor: theme.palette.primary,
    height: '50%',
    width: '100%',
    zIndex: 1,
  },
}));
