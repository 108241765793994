import type {Disposer, Location, Millisecond} from '@ncwallet-app/core';
import {batchDisposers, useRoot} from '@ncwallet-app/core';
import {APP_STORE_LINK, GOOGLE_PLAY_LINK} from '@ncwallet-app/core/src/Links';
import {Op} from '@ncwallet-app/core/src/Math';
import type {Url} from '@ncwallet-app/core/src/units/Uri';
import getWebOsKind from '@ncwallet-app/core/src/util/getWebOsKind';
import {OSKind} from '@ncwallet-app/core/src/util/OSKind';
import {autorun, observable, runInAction} from 'mobx';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Platform} from 'react-native';

import {STORE_REDIRECTION_DELAY} from './constants';
import type {RateModalBindingState} from './RateModalBindingState';

export default function useStoreRedirection(
  getIsFocused: () => boolean,
  goBack: () => void,
  state: RateModalBindingState,
  location: Location,
): [getDeadline: () => Millisecond | undefined, cancelTimeout: () => void] {
  const [deadline] = useState(() => observable.box<Millisecond>());
  const getDeadline = useCallback(() => deadline.get(), [deadline]);

  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  const cancelTimeout = useCallback(() => {
    if (timeoutId.current !== undefined) {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
      runInAction(() => {
        deadline.set(undefined);
      });
    }
  }, [deadline]);

  const root = useRoot();
  useEffect(() => {
    const theOnlyLink =
      Platform.OS === 'ios' || Platform.OS === 'macos'
        ? APP_STORE_LINK
        : Platform.OS === 'android' ||
            (Platform.OS === 'web' && getWebOsKind() === OSKind.Android)
          ? GOOGLE_PLAY_LINK
          : undefined;

    if (!theOnlyLink) {
      return;
    }

    const disposeStoreRedirection = autorun(() => {
      cancelTimeout();
      if (getIsFocused() && state.isRatingGood) {
        const now = root.time.now();
        timeoutId.current = setTimeout(async () => {
          await location.open(theOnlyLink as Url);
          goBack();
        }, STORE_REDIRECTION_DELAY);
        runInAction(() => {
          deadline.set(Op.add(now, STORE_REDIRECTION_DELAY));
        });
      }
    });

    return batchDisposers(cancelTimeout as Disposer, disposeStoreRedirection);
  }, [cancelTimeout, deadline, getIsFocused, goBack, location, root, state]);

  return [getDeadline, cancelTimeout];
}
