import type {AddressRedirectError} from '../../AddressUriHelper/AddressRedirectError';
import type {RateHistoryPeriod} from '../../dataStores';
import type {TransactionFilterKind} from '../../models';
import type {
  CryptoCurrencyCode,
  CurrencyCode,
  DecimalString,
  RateValue,
} from '../../Money';
import type {
  CommonErrorCode,
  CryptoAddress,
  WalletId,
  WalletLimitId,
  WalletLimitPeriod,
} from '../../NCWalletServer';
import type {
  AccountDocumentId,
  AccountDocumentType,
} from '../../NCWalletServer/AccountsDocuments';
import type {AccountDocumentFileName} from '../../NCWalletServer/AccountsDocuments/AccountDocumentFileInfo';
import type {TotpActionType} from '../../NCWalletServer/AccountsTotpActions';
import type {AddressNetwork} from '../../NCWalletServer/AddressInfo';
import type {Ip, IpInfoId} from '../../NCWalletServer/ipSettings/IpInfo';
import type {IpType} from '../../NCWalletServer/ipSettings/IpType';
import type {SessionId} from '../../NCWalletServer/sessions/Session';
import type {OtpSecret} from '../../Otp';
import type {Base32} from '../../Rfc4648';
import type {Millisecond} from '../../Time';
import type {TwoFaProviderKind} from '../../TwoFaHelper';
import type {PinCode} from '../../UserIdentity';
import type {Path} from '../Path';

export type StrictCommonState = [
  ...StrictCommonRouteMeta[],
  StrictCommonRouteMeta,
];

export type ShallowCommonState = [
  ...ShallowCommonRouteMeta[],
  ShallowCommonRouteMeta,
];

export type StrictCommonRouteMeta = {
  route: CommonRoute;
  path: Path;
};

export type ShallowCommonRouteMeta = {
  route: CommonRoute;
  path?: Path;
};

export type CommonRoute =
  | FallBackRoute
  | ListWalletsRoute
  | ListMenuCategoriesRoute
  | PromptThemeRoute
  | PromptLanguageRoute
  | ListNotificationsRoute
  | ListNotificationSettingsRoute
  | ShowProfileRoute
  | PromptNicknameRoute
  | PromptUserIdRoute
  | PromptEmailRoute
  | ListPersonalDataRoute
  | PromptNameAndBirthdateRoute
  | PromptResidentialAddressRoute
  | ListDocumentsRoute
  | PromptDocumentRoute
  | PromptBaseCryptoRoute
  | PromptBaseFiatRoute
  | ListSecuritySettingsRoute
  | ShowTwoFASettingsRoute
  | ShowTwoFaGeneratedSecretRoute
  | ListAuthAppsRoute
  | PromptOtpToEnableTwoFaRoute
  | PromptOtpToDisableTwoFaRoute
  | PromptOtpToChangeTwoFaSettingsRoute
  | ListAllowedIpsRoute
  | PromptUpdateIpRoute
  | PromptAddIpRoute
  | BlockedIpsListRoute
  | PromptToDeleteIpRoute
  | PromptToClearAllowedListRoute
  | PromptOtpToClearAllowedListRoute
  | PromptOtpToAddIpRoute
  | PromptAffiliateProgramRoute
  | PromptOtpToUpdateIpRoute
  | PromptOtpToDeleteIpRoute
  | ListSessionHistoryRoute
  | ListActiveSessionsRoute
  | PromptSessionDeletionRoute
  | CheckPinRoute
  | CheckBiometricsPinRoute
  | PromptOtpToBiometricsRoute
  | PromptBiometricSettingsRoute
  | PromptNewPinRoute
  | NotifyAboutSuccessfulPinChangeRoute
  | ListLimitsRoute
  | PromptNewLimitRoute
  | PromptLimitUpdateRoute
  | PromptOtpToLimitUpdateRoute
  | ShowLimitRoute
  | PromptLimitRemovalRoute
  | PromptOtpToLimitRemovalRoute
  | PromptOtpToLimitCreateRoute
  | PromptNewWalletRoute
  | PromptBuyCryptoRoute
  | ShowWalletRoute
  | ListInputAddressesRoute
  | PromptInputNetworkRoute
  | PromptReceiveNetworkRoute
  | ShowQrToReceiveCryptoRoute
  | PromptCryptoToReceiveRoute
  | PromptAddressFormatRoute
  | RedirectToSendRoute
  | PromptCryptoToSendRoute
  | PromptOutputAddressRoute
  | PromptOutputNetworkRoute
  | PromptAmountToSendRoute
  | ShowQrRoute
  | GeneratePayMeLinkRoute
  | PromptCommentToSendRoute
  | PromptCommissionForSendingRoute
  | PromptConfirmationToSendCryptoRoute
  | PromptOtpToSendCryptoRoute
  | NotifyAboutSuccessfulSendingRoute
  | PromptSourceCryptoToExchangeRoute
  | PromptExchangeReceiptRoute
  | PromptCryptoToExchangeRoute
  | PromptConfirmationForExchangeRoute
  | PromptOtpToExchangeRoute
  | NotifyAboutSuccessfulExchangeRoute
  | ListHistoryRoute
  | ListHistoryFiltersRoute
  | PromptWalletGroupToFilterHistoryRoute
  | PromptWalletToFilterHistoryRoute
  | PromptTransactionKindToFilterHistoryRoute
  | PromptPeriodToFilterHistoryRoute
  | PromptDateOfHistoryPeriodRoute
  | ShowTransactionRoute
  | ShowTransactionReportRoute
  | ShowQrCodeScannerRoute
  | ListQrCodeHistoryRoute
  | PromptRateRoute
  | PromptFeedbackRoute
  | DebugRoute
  | ListLogsRoute
  | ListSignOutReasonLogsRoute
  | ListEnvironmentsRoute
  | PromptEnvironmentFormRoute
  | PromptAccountDeletionRoute
  | PromptOtpToDeleteAccountRoute
  | IdentityVerificationUploadDocumentRoute
  | AddIdentityNewDocumentKindRoute
  | PromptIdentityVerificationAddressRoute
  | PromptToDeleteDocumentFileRoute
  | PromptSignOutRoute
  | PromptCloseAppRoute
  | TwoFaWelcomeRoute
  | PromptSelectTwoFaProviderRoute;

export type SimpleRoute<T> = {kind: T};

/**
 * Fall back to this route if a destination is not found
 *
 * Bindings: All
 */
export const FALL_BACK_ROUTE = 'FALL_BACK_ROUTE';
export type FallBackRoute = SimpleRoute<typeof FALL_BACK_ROUTE>;

/**
 * Home route
 *
 * Bindings: All
 */
export const LIST_WALLETS_ROUTE = 'LIST_WALLETS_ROUTE';
export type ListWalletsRoute = SimpleRoute<typeof LIST_WALLETS_ROUTE>;

/**
 * Bindings: Small
 *
 * Chained
 */
export const LIST_MENU_CATEGORIES_ROUTE = 'LIST_MENU_CATEGORIES_ROUTE';
export type ListMenuCategoriesRoute = SimpleRoute<
  typeof LIST_MENU_CATEGORIES_ROUTE
>;

/**
 * @summary Prompt theme
 *
 * Bindings: Small
 *
 * Chained
 */
export const PROMPT_THEME_ROUTE = 'PROMPT_THEME_ROUTE';
export type PromptThemeRoute = SimpleRoute<typeof PROMPT_THEME_ROUTE>;

/**
 * @summary Prompt language
 *
 * Bindings: Small
 *
 * Chained
 */
export const PROMPT_LANGUAGE_ROUTE = 'PROMPT_LANGUAGE_ROUTE';
export type PromptLanguageRoute = SimpleRoute<typeof PROMPT_LANGUAGE_ROUTE>;

/**
 * Bindings: Small
 *
 * Chained
 */
export const LIST_NOTIFICATIONS_ROUTE = 'LIST_NOTIFICATIONS_ROUTE';
export type ListNotificationsRoute = SimpleRoute<
  typeof LIST_NOTIFICATIONS_ROUTE
>;

/**
 * Bindings: All
 */
export const LIST_NOTIFICATION_SETTINGS_ROUTE =
  'LIST_NOTIFICATION_SETTINGS_ROUTE';
export type ListNotificationSettingsRoute = SimpleRoute<
  typeof LIST_NOTIFICATION_SETTINGS_ROUTE
>;

/**
 * Bindings: All
 */
export const SHOW_PROFILE_ROUTE = 'SHOW_PROFILE_ROUTE';

export type ShowProfileRoute = {
  kind: typeof SHOW_PROFILE_ROUTE;
  params?: {
    target?: 'email';
  };
};
/**
 * Bindings: Small
 */
export const PROMPT_NICKNAME_ROUTE = 'PROMPT_NICKNAME_ROUTE';
export type PromptNicknameRoute = {
  kind: typeof PROMPT_NICKNAME_ROUTE;
};

/**
 * Bindings: Small
 */
export const PROMPT_USER_ID_ROUTE = 'PROMPT_USER_ID_ROUTE';
export type PromptUserIdRoute = {
  kind: typeof PROMPT_USER_ID_ROUTE;
};

/**
 * todo stub
 *
 * Bindings: Small
 */
export const PROMPT_EMAIL_ROUTE = 'PROMPT_EMAIL_ROUTE';
export type PromptEmailRoute = {
  kind: typeof PROMPT_EMAIL_ROUTE;
  state?: {
    email?: string;
  };
};

/**
 * todo stub
 *
 * Bindings: Small
 */
export const LIST_PERSONAL_DATA_ROUTE = 'LIST_PERSONAL_DATA_ROUTE';
export type ListPersonalDataRoute = SimpleRoute<
  typeof LIST_PERSONAL_DATA_ROUTE
>;

/**
 * todo stub
 *
 * Bindings: Small
 */
export const PROMPT_NAME_AND_BIRTHDATE_ROUTE =
  'PROMPT_NAME_AND_BIRTHDATE_ROUTE';
export type PromptNameAndBirthdateRoute = {
  kind: typeof PROMPT_NAME_AND_BIRTHDATE_ROUTE;
  state?: {
    name?: string;
    surname?: string;
    birth?: Millisecond;
  };
};

export const PROMPT_TO_CHOOSE_IDENTITY_FILE_ROUTE =
  'PROMPT_TO_CHOOSE_IDENTITY_FILE_ROUTE';
export type PromptToChooseIdentityFileRoute = {
  kind: typeof PROMPT_TO_CHOOSE_IDENTITY_FILE_ROUTE;
  params: {
    type: AccountDocumentType;
  };
};

export const SHOW_DOCUMENT_CAMERA_ROUTE = 'SHOW_DOCUMENT_CAMERA_ROUTE';
export type ShowDocumentCameraRoute = {
  kind: typeof SHOW_DOCUMENT_CAMERA_ROUTE;
  params: {
    documentTypeId: AccountDocumentType;
  };
};

export const PROMPT_DOCUMENT_PERMISSION_ROUTE =
  'PROMPT_DOCUMENT_CAMERA_PERMISSION_ROUTE';
export type PromptDocumentPermissionRoute = {
  kind: typeof PROMPT_DOCUMENT_PERMISSION_ROUTE;
  params: {
    permissionKind: 'camera' | 'gallery';
  };
};

export const PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE =
  'PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE';
export type PromptToDeleteDocumentFileRoute = {
  kind: typeof PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE;
  params: {
    documentId: AccountDocumentId;
    file: AccountDocumentFileName;
  };
};

export const ADD_IDENTITY_NEW_DOCUMENT_KIND_ROUTE =
  'ADD_IDENTITY_NEW_DOCUMENT_KIND_ROUTE';
export type AddIdentityNewDocumentKindRoute = {
  kind: typeof ADD_IDENTITY_NEW_DOCUMENT_KIND_ROUTE;
};

export const IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE =
  'IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE';
export type IdentityVerificationUploadDocumentRoute = {
  kind: typeof IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE;
  params: {
    type: AccountDocumentType;
  };
};

/**
 * todo stub
 *
 * Bindings: Small
 */
export const PROMPT_RESIDENTIAL_ADDRESS_ROUTE =
  'PROMPT_RESIDENTIAL_ADDRESS_ROUTE';
export type PromptResidentialAddressRoute = {
  kind: typeof PROMPT_RESIDENTIAL_ADDRESS_ROUTE;
  params?: {
    country?: string;
  };
};

export const PROMPT_IDENTITY_VERIFICATION_ADDRESS_ROUTE =
  'PROMPT_IDENTITY_VERIFICATION_ADDRESS_ROUTE';
export type PromptIdentityVerificationAddressRoute = SimpleRoute<
  typeof PROMPT_IDENTITY_VERIFICATION_ADDRESS_ROUTE
>;

/**
 * todo stub
 *
 * Bindings: Small
 */
export const LIST_DOCUMENTS_ROUTE = 'LIST_DOCUMENTS_ROUTE';
export type ListDocumentsRoute = SimpleRoute<typeof LIST_DOCUMENTS_ROUTE>;

/**
 * todo stub
 *
 * Bindings: None
 */
export const PROMPT_DOCUMENT_ROUTE = 'PROMPT_DOCUMENT_ROUTE';
export type PromptDocumentRoute = SimpleRoute<typeof PROMPT_DOCUMENT_ROUTE>;

/**
 * Bindings: Small
 */
export const PROMPT_BASE_CRYPTO_ROUTE = 'PROMPT_BASE_CRYPTO_ROUTE';
export type PromptBaseCryptoRoute = SimpleRoute<
  typeof PROMPT_BASE_CRYPTO_ROUTE
>;

/**
 * Bindings: Small
 */
export const PROMPT_BASE_FIAT_ROUTE = 'PROMPT_BASE_FIAT_ROUTE';
export type PromptBaseFiatRoute = SimpleRoute<typeof PROMPT_BASE_FIAT_ROUTE>;

/**
 * Bindings: All
 */
export const LIST_SECURITY_SETTINGS_ROUTE = 'LIST_SECURITY_SETTINGS_ROUTE';
export type ListSecuritySettingsRoute = {
  kind: typeof LIST_SECURITY_SETTINGS_ROUTE;
  params?: TwoFaParams & {
    code?: string;
  };
};

export type TwoFaParams = {
  onTwoFaEnabled?: Path;
  providerKind?: TwoFaProviderKind;
};

/**
 * Bindings: Small
 */
export const SHOW_TWO_FA_SETTINGS_ROUTE = 'SHOW_TWO_FA_SETTINGS_ROUTE';
export type ShowTwoFASettingsRoute = SimpleRoute<
  typeof SHOW_TWO_FA_SETTINGS_ROUTE
>;

/**
 * Bindings: Small
 */
export const SHOW_TWO_FA_GENERATED_SECRET_ROUTE =
  'SHOW_TWO_FA_GENERATED_SECRET_ROUTE';
export type ShowTwoFaGeneratedSecretRoute = {
  kind: typeof SHOW_TWO_FA_GENERATED_SECRET_ROUTE;
  params?: TwoFaParams & {
    code?: string;
  };
};

/**
 * todo should be presented on small displays too
 *
 * Bindings: None
 */
export const LIST_AUTH_APPS_ROUTE = 'LIST_AUTH_APPS_ROUTE';
export type ListAuthAppsRoute = SimpleRoute<typeof LIST_AUTH_APPS_ROUTE>;

/**
 * Bindings: Small
 */
export const PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE =
  'PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE';
export type PromptOtpToEnableTwoFaRoute = {
  kind: typeof PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE;
  params: {
    secret?: Base32<OtpSecret>;
  } & TwoFaParams;
};

/**
 * Bindings: Small
 */
export const PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE =
  'PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE';
export type PromptOtpToDisableTwoFaRoute = {
  kind: typeof PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE;
  params?: {
    providerKind?: TwoFaProviderKind;
    redirectToEnableKind?: TwoFaProviderKind;
  };
};

export const PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE =
  'PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE';
export type PromptOtpToChangeTwoFaSettingsRoute = {
  kind: typeof PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE;
  params: {
    excluded: TotpActionType[];
    included: TotpActionType[];
  };
};

/**
 *
 * Bindings: Small
 */
export const LIST_ALLOWED_IPS_ROUTE = 'LIST_ALLOWED_IPS_ROUTE';
export type ListAllowedIpsRoute = SimpleRoute<typeof LIST_ALLOWED_IPS_ROUTE>;

/**
 *
 * Bindings: Small
 */
export const PROMPT_UPDATE_IP_ROUTE = 'PROMPT_UPDATE_IP_ROUTE';
export type PromptUpdateIpRoute = {
  kind: typeof PROMPT_UPDATE_IP_ROUTE;
  params: IpForm & {type: IpType};
};

export type IpForm = {
  name: string;
  ip: Ip;
  id: IpInfoId;
};

/**
 *
 * Bindings: Small
 */
export const PROMPT_ADD_IP_ROUTE = 'PROMPT_ADD_IP_ROUTE';
export type PromptAddIpRoute = {
  kind: typeof PROMPT_ADD_IP_ROUTE;
  params: {
    type: IpType;
  };
};

/**
 *
 * Bindings: Small
 */
export const PROMPT_AFFILIATE_PROGRAM_ROUTE = 'PROMPT_AFFILIATE_PROGRAM_ROUTE';
export type PromptAffiliateProgramRoute = SimpleRoute<
  typeof PROMPT_AFFILIATE_PROGRAM_ROUTE
>;

/**
 *
 * Bindings: Small
 */
export const LIST_BLOCKED_IPS_ROUTE = 'LIST_BLOCKED_IPS_ROUTE';
export type BlockedIpsListRoute = SimpleRoute<typeof LIST_BLOCKED_IPS_ROUTE>;

export const PROMPT_TO_DELETE_IP_ROUTE = 'PROMPT_TO_DELETE_IP_ROUTE';
export type PromptToDeleteIpRoute = {
  kind: typeof PROMPT_TO_DELETE_IP_ROUTE;
  params: {
    ip: Ip;
    id: IpInfoId;
    type: IpType;
  };
};

export const PROMPT_TO_CLEAR_ALLOWED_LIST_ROUTE =
  'PROMPT_TO_CLEAR_ALLOWED_LIST_ROUTE';
export type PromptToClearAllowedListRoute = SimpleRoute<
  typeof PROMPT_TO_CLEAR_ALLOWED_LIST_ROUTE
>;

export const PROMPT_OTP_TO_CLEAR_ALLOWED_LIST_ROUTE =
  'PROMPT_OTP_TO_CLEAR_ALLOWED_LIST_ROUTE';

export type PromptOtpToClearAllowedListRoute = SimpleRoute<
  typeof PROMPT_OTP_TO_CLEAR_ALLOWED_LIST_ROUTE
>;

export const PROMPT_OTP_TO_DELETE_IP_ROUTE = 'PROMPT_OTP_TO_DELETE_IP_ROUTE';
export type PromptOtpToDeleteIpRoute = {
  kind: typeof PROMPT_OTP_TO_DELETE_IP_ROUTE;
  params: {
    id: IpInfoId;
    type: IpType;
  };
};

export const PROMPT_OTP_TO_ADD_IP_ROUTE = 'PROMPT_OTP_TO_ADD_IP_ROUTE';
export type PromptOtpToAddIpRoute = {
  kind: typeof PROMPT_OTP_TO_ADD_IP_ROUTE;
  params: {
    ip: Ip;
    name: string;
    type: IpType;
  };
};

export const PROMPT_OTP_TO_UPDATE_IP_ROUTE = 'PROMPT_OTP_TO_UPDATE_IP_ROUTE';
export type PromptOtpToUpdateIpRoute = {
  kind: typeof PROMPT_OTP_TO_UPDATE_IP_ROUTE;
  params: {
    type: IpType;
  } & IpForm;
};

/**
 * Bindings: Small
 */
export const LIST_SESSION_HISTORY_ROUTE = 'LIST_SESSION_HISTORY_ROUTE';
export type ListSessionHistoryRoute = SimpleRoute<
  typeof LIST_SESSION_HISTORY_ROUTE
>;

/**
 * Bindings: Small
 */
export const LIST_ACTIVE_SESSIONS_ROUTE = 'LIST_ACTIVE_SESSIONS_ROUTE';
export type ListActiveSessionsRoute = SimpleRoute<
  typeof LIST_ACTIVE_SESSIONS_ROUTE
>;

export const PROMPT_SESSION_DELETION = 'PROMPT_SESSION_DELETION_ROUTE';
export type PromptSessionDeletionRoute = {
  kind: typeof PROMPT_SESSION_DELETION;
  params: {
    sessionId: SessionId;
  };
};

/**
 * Bindings: Small
 */
export const CHECK_PIN_ROUTE = 'CHECK_PIN_ROUTE';
export type CheckPinRoute = SimpleRoute<typeof CHECK_PIN_ROUTE>;

/**
 * Bindings: Small
 */
export const CHECK_BIOMETRICS_PIN_ROUTE = 'CHECK_BIOMETRICS_PIN_ROUTE';
export type CheckBiometricsPinRoute = SimpleRoute<
  typeof CHECK_BIOMETRICS_PIN_ROUTE
>;

/**
 * Bindings: Small
 */
export const PROMPT_OTP_TO_BIOMETRICS_ROUTE = 'PROMPT_OTP_TO_BIOMETRICS_ROUTE';
export type PromptOtpToBiometricsRoute = SimpleRoute<
  typeof PROMPT_OTP_TO_BIOMETRICS_ROUTE
>;

/**
 * Bindings: Small
 */
export const PROMPT_BIOMETRIC_SETTINGS_ROUTE =
  'PROMPT_BIOMETRIC_SETTINGS_ROUTE';
export type PromptBiometricSettingsRoute = SimpleRoute<
  typeof PROMPT_BIOMETRIC_SETTINGS_ROUTE
>;

/**
 * Bindings: Small
 */
export const PROMPT_NEW_PIN_ROUTE = 'PROMPT_NEW_PIN_ROUTE';
export type PromptNewPinRoute = {
  kind: typeof PROMPT_NEW_PIN_ROUTE;
  params?: {
    // pin should not be serialized in url params
    oldPin: PinCode;
    load?: boolean;
  };
};

/**
 * Bindings: Large
 */
export const NOTIFY_ABOUT_SUCCESSFUL_PIN_CHANGE =
  'NOTIFY_ABOUT_SUCCESSFUL_PIN_CHANGE';
export type NotifyAboutSuccessfulPinChangeRoute = SimpleRoute<
  typeof NOTIFY_ABOUT_SUCCESSFUL_PIN_CHANGE
>;

/**
 *
 * Bindings: Small
 */
export const LIST_LIMITS_ROUTE = 'LIST_LIMITS_ROUTE';
export type ListLimitsRoute = SimpleRoute<typeof LIST_LIMITS_ROUTE>;

/**
 *
 * Bindings: All
 */
export const PROMPT_NEW_LIMIT_ROUTE = 'PROMPT_NEW_LIMIT_ROUTE';
export type PromptNewLimitRoute = {
  kind: typeof PROMPT_NEW_LIMIT_ROUTE;
  params: {
    walletId: WalletId;
    currency: CryptoCurrencyCode;
    period: WalletLimitPeriod;
    perOnePeriodOnly?: boolean;
  };
};

export const PROMPT_OTP_TO_LIMIT_CREATE_ROUTE =
  'PROMPT_OTP_TO_LIMIT_CREATE_ROUTE';
export type PromptOtpToLimitCreateRoute = {
  kind: typeof PROMPT_OTP_TO_LIMIT_CREATE_ROUTE;
  params: {
    walletId: WalletId;
    limit: DecimalString;
    period: WalletLimitPeriod;
  };
};

/**
 *
 * Bindings: Small
 */
export const PROMPT_LIMIT_UPDATE_ROUTE = 'PROMPT_LIMIT_UPDATE_ROUTE';
export type PromptLimitUpdateRoute = {
  kind: typeof PROMPT_LIMIT_UPDATE_ROUTE;
  params: {
    limit: DecimalString;
    currency: CryptoCurrencyCode;
    walletId: WalletId;
    period: WalletLimitPeriod;
  };
};

export const PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE =
  'PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE';
export type PromptOtpToLimitUpdateRoute = {
  kind: typeof PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE;
  params: {
    walletId: WalletId;
    limit: DecimalString;
    period: WalletLimitPeriod;
  };
};

/**
 *
 * Bindings: Small
 */
export const SHOW_LIMIT_ROUTE = 'SHOW_LIMIT_ROUTE';
export type ShowLimitRoute = {
  kind: typeof SHOW_LIMIT_ROUTE;
  params: {
    walletId: WalletId;
    period: WalletLimitPeriod;
  };
};

export enum LimitRemovalAction {
  Delete,
  CancelDelete,
  DeleteWaitingForActivationLimit,
}

/**
 *
 * Bindings: Small
 */
export const PROMPT_LIMIT_REMOVAL_ROUTE = 'PROMPT_LIMIT_REMOVAL_ROUTE';
export type PromptLimitRemovalRoute = {
  kind: typeof PROMPT_LIMIT_REMOVAL_ROUTE;
  params: {
    limitId: WalletLimitId;
    action: LimitRemovalAction;
  };
};

export const PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE =
  'PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE';
export type PromptOtpToLimitRemovalRoute = {
  kind: typeof PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE;
  params: {
    limitId: WalletLimitId;
    action: LimitRemovalAction;
  };
};

/**
 * Bindings: All
 */
export const PROMPT_NEW_WALLET_ROUTE = 'PROMPT_NEW_WALLET_ROUTE';
export type PromptNewWalletRoute = {
  kind: typeof PROMPT_NEW_WALLET_ROUTE;
  params?: {search?: string};
};

/**
 * Bindings: All
 */
export const PROMPT_BUY_CRYPTO_ROUTE = 'PROMPT_BUY_CRYPTO_ROUTE';
export type PromptBuyCryptoRoute = {
  kind: typeof PROMPT_BUY_CRYPTO_ROUTE;
  params?: {search?: string};
};

/**
 * Bindings: All
 */
export const SHOW_WALLET_ROUTE = 'SHOW_WALLET_ROUTE';
export type ShowWalletRoute = {
  kind: typeof SHOW_WALLET_ROUTE;
  params: {
    walletId: WalletId;
    currencyCode: string;
    period?: RateHistoryPeriod;
  };
};

/**
 * Bindings: All
 */
export const LIST_INPUT_ADDRESSES_ROUTE = 'LIST_INPUT_ADDRESSES_ROUTE';
export type ListInputAddressesRoute = {
  kind: typeof LIST_INPUT_ADDRESSES_ROUTE;
  params: {
    walletId: WalletId;
    networkFilter: AddressNetwork;
    currencyFilter: CryptoCurrencyCode;
    address: CryptoAddress;
    depositType?: DepositType;
  } & AddressParams;
};

export const PROMPT_ADDRESS_FORMAT_ROUTE = 'PROMPT_ADDRESS_FORMAT_ROUTE';

export type PromptAddressFormatRoute = {
  kind: typeof PROMPT_ADDRESS_FORMAT_ROUTE;
  params: {
    walletId: WalletId;
  } & AddressParams;
};

export const PROMPT_RECEIVE_NETWORK_ROUTE = 'PROMPT_RECEIVE_NETWORK_ROUTE';

export type PromptReceiveNetworkRoute = {
  kind: typeof PROMPT_RECEIVE_NETWORK_ROUTE;
  params: {
    walletId: WalletId;
    networkFilter: AddressNetwork;
    currencyFilter: CryptoCurrencyCode;
    address: CryptoAddress;
    depositType?: DepositType;
  } & AddressParams;
};
export const PROMPT_INPUT_NETWORK_ROUTE = 'PROMPT_INPUT_NETWORK_ROUTE';
export type PromptInputNetworkRoute = {
  kind: typeof PROMPT_INPUT_NETWORK_ROUTE;
  params: {
    walletId: WalletId;
  } & AddressParams;
};

/**
 * Bindings: All
 */
export const SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE =
  'SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE';
export type ShowQrToReceiveCryptoRoute = {
  kind: typeof SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE;
  params: {
    walletId: WalletId;
    address: CryptoAddress;
    depositType?: DepositType;
  } & AddressParams;
};

export type AddressParams = {
  addressCurrency: CryptoCurrencyCode;
  addressNetwork: AddressNetwork;
};

export enum DepositType {
  Receive,
  Buy,
}

/**
 * Bindings: All
 */
export const PROMPT_CRYPTO_TO_RECEIVE_ROUTE = 'PROMPT_CRYPTO_TO_RECEIVE_ROUTE';
export type PromptCryptoToReceiveRoute = {
  kind: typeof PROMPT_CRYPTO_TO_RECEIVE_ROUTE;
  params?: {search?: string};
};

export const REDIRECT_TO_SEND_ROUTE = 'REDIRECT_TO_SEND_ROUTE';
export type RedirectToSendRoute = {
  kind: typeof REDIRECT_TO_SEND_ROUTE;
  params: RedirectToSendRouteParams;
};

export type RedirectToSendRouteParams = {
  crypto?: CryptoCurrencyCode;
  address: CryptoAddress;
  amount?: DecimalString;
  value?: DecimalString;
  externalFrom?: string;
  addressNetwork?: AddressNetwork;
  minFreeWithdrawAmount?: DecimalString;
};

/**
 * Bindings: All
 */
export const PROMPT_CRYPTO_TO_SEND_ROUTE = 'PROMPT_CRYPTO_TO_SEND_ROUTE';
export type PromptCryptoToSendRoute = {
  kind: typeof PROMPT_CRYPTO_TO_SEND_ROUTE;
  params?: {search?: string; addressTo?: string};
};

/**
 * Bindings: All
 */
export const PROMPT_OUTPUT_ADDRESS_ROUTE = 'PROMPT_OUTPUT_ADDRESS_ROUTE';
export type PromptOutputAddressRoute = {
  kind: typeof PROMPT_OUTPUT_ADDRESS_ROUTE;
  params: {
    walletId: WalletId;
    amount?: DecimalString;
    userTo?: string;
    addressTo?: string;
    // isEditable - user get to this screen from PromptConfirmationToSendCryptoRoute
    isEditable?: boolean;
    comment?: string;
    minFreeWithdrawAmount?: string;
  } & FeeParams &
    AddressParams;
};

export const PROMPT_OUTPUT_NETWORK_ROUTE = 'PROMPT_OUTPUT_NETWORK_ROUTE';
export type PromptOutputNetworkRoute = {
  kind: typeof PROMPT_OUTPUT_NETWORK_ROUTE;
  params: {
    addressTo?: string;
    amount?: DecimalString;
    isEditable?: boolean;
  } & BaseSendParams &
    FeeParams;
};

export type FeeParams = {
  fee?: DecimalString;
  feeChangeDisabled?: boolean;
};

/**
 * Bindings: Small
 */
export const PROMPT_AMOUNT_TO_SEND_ROUTE = 'PROMPT_AMOUNT_TO_SEND_ROUTE';
export type PromptAmountToSendRoute = {
  kind: typeof PROMPT_AMOUNT_TO_SEND_ROUTE;
  params: {
    addressTo: string;
    amount?: DecimalString;
    isEditable?: boolean;
  } & BaseSendParams &
    FeeParams;
};

/**
 * Bindings: Small
 */

export const SHOW_QR_ROUTE = 'SHOW_QR_ROUTE';
export type ShowQrRoute = {
  kind: typeof SHOW_QR_ROUTE;
  params: {
    walletId: WalletId;
    address: CryptoAddress;
  } & AddressParams;
};

/**
 * Bindings: Small
 */

export const GENERATE_PAY_ME_LINK_ROUTE = 'GENERATE_PAY_ME_LINK_ROUTE';
export type GeneratePayMeLinkRoute = {
  kind: typeof GENERATE_PAY_ME_LINK_ROUTE;
  params: {
    currency: CryptoCurrencyCode;
    address: CryptoAddress;
    amount?: DecimalString;
  };
};

/**
 * Bindings: Small
 */
export const PROMPT_COMMENT_TO_SEND_ROUTE = 'PROMPT_COMMENT_TO_SEND_ROUTE';
export type PromptCommentToSendRoute = {
  kind: typeof PROMPT_COMMENT_TO_SEND_ROUTE;
  params: {
    addressTo: string;
    amount: DecimalString;
    comment?: string;
  } & BaseSendParams &
    FeeParams;
};

export type BaseSendParams = {
  walletId: WalletId;
  currency: CryptoCurrencyCode;
  minFreeWithdrawAmount?: DecimalString;
} & AddressParams;

/**
 * Bindings: All
 */
export const PROMPT_COMMISSION_FOR_SENDING_ROUTE =
  'PROMPT_COMMISSION_FOR_SENDING_ROUTE';
export type PromptCommissionForSendingRoute = {
  kind: typeof PROMPT_COMMISSION_FOR_SENDING_ROUTE;
  params: {
    fee?: DecimalString;
    isEditable?: boolean;
    addressTo: string;
    amount: DecimalString;
    comment?: string;
    hasCommission?: true;
  } & BaseSendParams;
};

/**
 * Bindings: All
 */
export const PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE =
  'PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE';
export type PromptConfirmationToSendCryptoRoute = {
  kind: typeof PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE;
  params: {
    addressTo: string;
    amount: DecimalString;
    comment?: string;
  } & BaseSendParams &
    FeeParams;
};

/**
 * Bindings: All
 */
export const PROMPT_OTP_TO_SEND_CRYPTO_ROUTE =
  'PROMPT_OTP_TO_SEND_CRYPTO_ROUTE';
export type PromptOtpToSendCryptoRoute = {
  kind: typeof PROMPT_OTP_TO_SEND_CRYPTO_ROUTE;
  params: {withdrawId: string; providerKind?: TwoFaProviderKind} & SendReceipt;
};

export type SendReceipt = {
  addressTo: string;
  amount: DecimalString;
  currency: string;
};

/**
 * Bindings: All
 */
export const NOTIFY_ABOUT_SUCCESSFUL_SENDING_ROUTE =
  'NOTIFY_ABOUT_SUCCESSFUL_SENDING_ROUTE';
export type NotifyAboutSuccessfulSendingRoute = {
  kind: typeof NOTIFY_ABOUT_SUCCESSFUL_SENDING_ROUTE;
  params: {error?: string} & SendReceipt;
};

/**
 * Bindings: All
 */
export const PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE =
  'PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE';
export type PromptSourceCryptoToExchangeRoute = {
  kind: typeof PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE;
  params?: {search?: string};
};

/**
 * Bindings: All
 */
export const PROMPT_EXCHANGE_RECEIPT_ROUTE = 'PROMPT_EXCHANGE_RECEIPT_ROUTE';
export type PromptExchangeReceiptRoute = {
  kind: typeof PROMPT_EXCHANGE_RECEIPT_ROUTE;
  params: ExchangeReceipt;
};

export type ExchangeReceipt = {
  walletIdFrom: WalletId;
  walletIdTo?: WalletId;
  currencyTo?: CryptoCurrencyCode;
  value?: DecimalString;
  isTargetValue?: boolean;
};

/**
 * Bindings: All
 */
export const PROMPT_CRYPTO_TO_EXCHANGE_ROUTE =
  'PROMPT_CRYPTO_TO_EXCHANGE_ROUTE';
export type PromptCryptoToExchangeRoute = {
  kind: typeof PROMPT_CRYPTO_TO_EXCHANGE_ROUTE;
  params: {
    isSourceRequested: boolean;
    disabledCrypto?: CryptoCurrencyCode;
    search?: string;
  } & ExchangeReceipt;
};

/**
 * Bindings: All
 */
export const PROMPT_CONFIRMATION_FOR_EXCHANGE_ROUTE =
  'PROMPT_CONFIRMATION_FOR_EXCHANGE_ROUTE';
export type PromptConfirmationForExchangeRoute = {
  kind: typeof PROMPT_CONFIRMATION_FOR_EXCHANGE_ROUTE;
  params: {
    walletIdFrom: WalletId;
    walletIdTo?: WalletId;
    isTargetValueLastChanged: boolean;
    errorCode?:
      | CommonErrorCode.ExchangeRateChanged
      | CommonErrorCode.ExchangeTokenExpired;
    token: string;
    exchangeRate: RateValue<CurrencyCode, CurrencyCode>;
    date: Millisecond;
    fee?: string;
  } & ExchangeSummary;
};

export const PROMPT_OTP_TO_EXCHANGE_ROUTE = 'PROMPT_OTP_TO_EXCHANGE_ROUTE';
export type PromptOtpToExchangeRoute = {
  kind: typeof PROMPT_OTP_TO_EXCHANGE_ROUTE;
  params: {
    walletIdFrom: WalletId;
    walletIdTo?: WalletId;
    isTargetValueLastChanged: boolean;
    token: string;
    exchangeRate: RateValue<CurrencyCode, CurrencyCode>;
    date: Millisecond;
    fee?: string;
  } & ExchangeSummary;
};

/**
 * Bindings: All
 */
export const NOTIFY_ABOUT_SUCCESSFUL_EXCHANGE_ROUTE =
  'NOTIFY_ABOUT_SUCCESSFUL_EXCHANGE_ROUTE';
export type NotifyAboutSuccessfulExchangeRoute = {
  kind: typeof NOTIFY_ABOUT_SUCCESSFUL_EXCHANGE_ROUTE;
  params: ExchangeSummary;
};

export type ExchangeSummary = {
  valueFrom: string;
  valueTo: string;
  currencyFrom: CryptoCurrencyCode;
  currencyTo: CryptoCurrencyCode;
};

/**
 * Bindings: All
 */
export const LIST_HISTORY_ROUTE = 'LIST_HISTORY_ROUTE';
export type ListHistoryRoute = {
  kind: typeof LIST_HISTORY_ROUTE;
  params?: {
    walletId?: WalletId;
  } & BaseFilterParams;
};

export type BaseFilterParams = {
  from?: Millisecond;
  to?: Millisecond;
  transactionFilterKind?: TransactionFilterKind;
};

/**
 * Bindings: All
 */
export const LIST_HISTORY_FILTERS_ROUTE = 'LIST_HISTORY_FILTERS_ROUTE';
export type ListHistoryFiltersRoute = {
  kind: typeof LIST_HISTORY_FILTERS_ROUTE;
  params?: {
    walletId?: WalletId;
  } & BaseFilterParams;
};

/**
 * todo stub
 *
 * Bindings: Small, *Large
 */
export const PROMPT_WALLET_GROUP_TO_FILTER_HISTORY_ROUTE =
  'PROMPT_WALLET_GROUP_TO_FILTER_HISTORY_ROUTE';
export type PromptWalletGroupToFilterHistoryRoute = SimpleRoute<
  typeof PROMPT_WALLET_GROUP_TO_FILTER_HISTORY_ROUTE
>;

/**
 * Bindings: Small, *Large
 */
export const PROMPT_WALLET_TO_FILTER_HISTORY_ROUTE =
  'PROMPT_WALLET_TO_FILTER_HISTORY_ROUTE';
export type PromptWalletToFilterHistoryRoute = {
  kind: typeof PROMPT_WALLET_TO_FILTER_HISTORY_ROUTE;
  params?: {search?: string};
};

/**
 * Bindings: All
 */
export const PROMPT_TRANSACTION_KIND_TO_FILTER_HISTORY_ROUTE =
  'PROMPT_TRANSACTION_KIND_TO_FILTER_HISTORY_ROUTE';
export type PromptTransactionKindToFilterHistoryRoute = SimpleRoute<
  typeof PROMPT_TRANSACTION_KIND_TO_FILTER_HISTORY_ROUTE
>;

/**
 * Bindings: All
 */
export const PROMPT_PERIOD_TO_FILTER_HISTORY_ROUTE =
  'PROMPT_PERIOD_TO_FILTER_HISTORY_ROUTE';
export type PromptPeriodToFilterHistoryRoute = {
  kind: typeof PROMPT_PERIOD_TO_FILTER_HISTORY_ROUTE;
  params?: {
    from?: Millisecond;
    to?: Millisecond;
  };
};

/**
 * Bindings: All
 */
export const PROMPT_DATE_OF_HISTORY_PERIOD_ROUTE =
  'PROMPT_DATE_OF_HISTORY_PERIOD_ROUTE';
export type PromptDateOfHistoryPeriodRoute = {
  kind: typeof PROMPT_DATE_OF_HISTORY_PERIOD_ROUTE;
  params: {
    isSourceRequested: boolean;
    value?: Millisecond;
    to?: Millisecond;
    from?: Millisecond;
  };
};

/**
 * Bindings: All
 */
export const SHOW_TRANSACTION_ROUTE = 'SHOW_TRANSACTION_ROUTE';
export type ShowTransactionRoute = {
  kind: typeof SHOW_TRANSACTION_ROUTE;
  params: {
    id: string;
  };
};
/**
 * Bindings: All
 */
export const SHOW_TRANSACTION_REPORT_ROUTE = 'SHOW_TRANSACTION_REPORT_ROUTE';
export type ShowTransactionReportRoute = {
  kind: typeof SHOW_TRANSACTION_REPORT_ROUTE;
  params: {
    id: string;
  };
};

/**
 * Bindings: All
 */
export const SHOW_QR_CODE_SCANNER_ROUTE = 'SHOW_QR_CODE_SCANNER_ROUTE';
export type ShowQrCodeScannerRoute = SimpleRoute<
  typeof SHOW_QR_CODE_SCANNER_ROUTE
>;

/**
 * Bindings: All
 */
export const LIST_QR_CODE_HISTORY_ROUTE = 'LIST_QR_CODE_HISTORY_ROUTE';
export type ListQrCodeHistoryRoute = {
  kind: typeof LIST_QR_CODE_HISTORY_ROUTE;
  params?: {
    address?: string;
    error?: AddressRedirectError;
  };
};

/**
 * Bindings: All
 */
export const PROMPT_RATE_ROUTE = 'PROMPT_RATE_ROUTE';
export type PromptRateRoute = SimpleRoute<typeof PROMPT_RATE_ROUTE>;

/**
 * Bindings: All
 */
export const PROMPT_ACCOUNT_DELETION_ROUTE = 'PROMPT_ACCOUNT_REMOVAL_ROUTE';
export type PromptAccountDeletionRoute = SimpleRoute<
  typeof PROMPT_ACCOUNT_DELETION_ROUTE
>;

export const PROMPT_LINK_TO_EMAIL_ROUTE = 'PROMPT_LINK_TO_EMAIL_ROUTE';
export type PromptLinkToEmailRoute = SimpleRoute<
  typeof PROMPT_LINK_TO_EMAIL_ROUTE
>;

export const PROMPT_LINK_TO_TELEGRAM_ROUTE = 'PROMPT_LINK_TO_TELEGRAM_ROUTE';
export type PromptLinkToTelegramRoute = SimpleRoute<
  typeof PROMPT_LINK_TO_TELEGRAM_ROUTE
>;

export const PROMPT_OTP_TO_DELETE_ACCOUNT_ROUTE =
  'PROMPT_OTP_TO_DELETE_ACCOUNT_ROUTE';
export type PromptOtpToDeleteAccountRoute = SimpleRoute<
  typeof PROMPT_OTP_TO_DELETE_ACCOUNT_ROUTE
>;

/**
 * Bindings: All
 */
export const PROMPT_FEEDBACK_ROUTE = 'PROMPT_FEEDBACK_ROUTE';
export type PromptFeedbackRoute = {
  kind: typeof PROMPT_FEEDBACK_ROUTE;
  params: {
    rating: StarValue;
  };
};

export type StarValue = 1 | 2 | 3 | 4 | 5;

/**
 * Bindings: All
 */
export const DEBUG_ROUTE = 'DEBUG_ROUTE';
export type DebugRoute = SimpleRoute<typeof DEBUG_ROUTE>;

/**
 * Bindings: All
 */
export const LIST_LOGS_ROUTE = 'LIST_LOGS_ROUTE';
export type ListLogsRoute = SimpleRoute<typeof LIST_LOGS_ROUTE>;

/**
 * Bindings: All
 */
export const LIST_SIGN_OUT_REASON_LOGS_ROUTE =
  'LIST_SIGN_OUT_REASON_LOGS_ROUTE';
export type ListSignOutReasonLogsRoute = SimpleRoute<
  typeof LIST_SIGN_OUT_REASON_LOGS_ROUTE
>;

/**
 * Bindings: All
 */
export const LIST_ENVIRONMENT_ROUTE = 'LIST_ENVIRONMENT_ROUTE';
export type ListEnvironmentsRoute = SimpleRoute<typeof LIST_ENVIRONMENT_ROUTE>;

/**
 * Bindings: All
 */
export const PROMPT_ENVIRONMENT_FORM_ROUTE = 'PROMPT_ENVIRONMENT_FORM_ROUTE';
export type PromptEnvironmentFormRoute = SimpleRoute<
  typeof PROMPT_ENVIRONMENT_FORM_ROUTE
>;

/**
 * Bindings: Small
 *
 */
export const PROMPT_SIGN_OUT_ROUTE = 'PROMPT_SIGN_OUT_ROUTE';
export type PromptSignOutRoute = SimpleRoute<typeof PROMPT_SIGN_OUT_ROUTE>;

export const PROMPT_CLOSE_APP_ROUTE = 'PROMPT_CLOSE_APP_ROUTE';
export type PromptCloseAppRoute = SimpleRoute<typeof PROMPT_CLOSE_APP_ROUTE>;

export const TWO_FA_WELCOME_ROUTE = 'TWO_FA_WELCOME_ROUTE';
export type TwoFaWelcomeRoute = {
  kind: typeof TWO_FA_WELCOME_ROUTE;
  params?: TwoFaParams;
};

export const PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE =
  'PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE';
export type PromptSelectTwoFaProviderRoute = {
  kind: typeof PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE;
  params?: TwoFaParams;
};

export const LIST_WALLETS_FILTER = 'LIST_WALLETS_FILTER';
