import {useStrings, useStyles, useTheme, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps} from '@ncwallet-app/ui';
import {
  Button,
  LG_BREAKPOINT,
  SafeAreaScrollView,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {ArrowLeftWide} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Platform, View} from 'react-native';

import {EXTRA_BOTTOM_OFFSET} from '../constants';
import type {GeneratePayMeLinkBodyProps} from './GeneratePayMeLinkBody';
import GeneratePayMeLinkBody from './GeneratePayMeLinkBody';

export type GeneratePayMeLinkScreen = BaseSafeAreaProps &
  GeneratePayMeLinkBodyProps;

export default observer(function GeneratePayMeLinkScreen(
  props: GeneratePayMeLinkScreen,
) {
  const styles = useStyles(theme => ({
    root: {
      flex: 1,
      ...Platform.select({
        web: {
          overflow: 'visible',
        },
      }),
    },
    container: {
      flexGrow: 1,
      overflow: 'visible',
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          backgroundColor: theme.palette.uiPrimary,
          paddingBottom: 0,
        },
      }),
    },
  }));

  const isLg = useIsDimensions('lg');
  const strings = useStrings();
  const theme = useTheme();

  return (
    <SafeAreaScrollView
      style={styles.root}
      contentContainerStyle={{flexGrow: 1, paddingBottom: 10}}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}>
      <Inner>
        {isLg && (
          <Header>
            <BackButton
              title={strings['generatePayMeLink.screenTitle']}
              onPress={props.onBackPress}
              Icon={ArrowLeftWide}
              iconCustomColor={theme.palette.uiAdditional1}
            />
          </Header>
        )}
        <GeneratePayMeLinkBody {...props} />
      </Inner>
    </SafeAreaScrollView>
  );
});

const Inner = variance(View)(theme => ({
  root: {
    paddingTop: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexGrow: 1,
        borderRadius: 10,
        backgroundColor: theme.palette.background,
        paddingTop: 30,
        paddingHorizontal: 30,
        paddingBottom: 20,
        ...theme.bar(10),
      },
    }),
  },
}));

const Header = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 60,
  },
}));

const BackButton = variance(Button)(() => ({
  root: {
    marginBottom: 20,
    padding: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
  },
}));
