import {useRoot, variance} from '@ncwallet-app/core';
import {useIsDimensions} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

const ExtraDetailsComponent = observer(() => {
  const {localization} = useRoot();
  const isLg = useIsDimensions('lg');

  return (
    <Root isLg={isLg}>
      <Title>{localization.getTranslation('buyCrypto.select')}</Title>
      <Description>
        {localization.getTranslation('buyCrypto.selectDesc')}
      </Description>
    </Root>
  );
});

export default ExtraDetailsComponent;

const Root = variance(View)(theme => ({
  root: {
    padding: 20,
    gap: 5,
    borderTopColor: theme.palette.uiSecondary,
    borderTopWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
  },
  isLg: {
    paddingTop: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 21,
    color: theme.palette.textPrimary,
  },
}));

const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 13,
    lineHeight: 20,
    color: theme.palette.textAdditional2,
  },
}));
