import {
  ADD_IDENTITY_NEW_DOCUMENT_KIND_ROUTE,
  CHECK_BIOMETRICS_PIN_ROUTE,
  CHECK_PIN_ROUTE,
  DEBUG_ROUTE,
  FALL_BACK_ROUTE,
  GENERATE_PAY_ME_LINK_ROUTE,
  IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE,
  LIST_ACTIVE_SESSIONS_ROUTE,
  LIST_ALLOWED_IPS_ROUTE,
  LIST_AUTH_APPS_ROUTE,
  LIST_BLOCKED_IPS_ROUTE,
  LIST_DOCUMENTS_ROUTE,
  LIST_ENVIRONMENT_ROUTE,
  LIST_HISTORY_FILTERS_ROUTE,
  LIST_HISTORY_ROUTE,
  LIST_INPUT_ADDRESSES_ROUTE,
  LIST_LIMITS_ROUTE,
  LIST_LOGS_ROUTE,
  LIST_MENU_CATEGORIES_ROUTE,
  LIST_NOTIFICATION_SETTINGS_ROUTE,
  LIST_NOTIFICATIONS_ROUTE,
  LIST_PERSONAL_DATA_ROUTE,
  LIST_QR_CODE_HISTORY_ROUTE,
  LIST_SECURITY_SETTINGS_ROUTE,
  LIST_SESSION_HISTORY_ROUTE,
  LIST_SIGN_OUT_REASON_LOGS_ROUTE,
  LIST_WALLETS_ROUTE,
  NOTIFY_ABOUT_SUCCESSFUL_EXCHANGE_ROUTE,
  NOTIFY_ABOUT_SUCCESSFUL_PIN_CHANGE,
  NOTIFY_ABOUT_SUCCESSFUL_SENDING_ROUTE,
  PROMPT_ACCOUNT_DELETION_ROUTE,
  PROMPT_ADD_IP_ROUTE,
  PROMPT_ADDRESS_FORMAT_ROUTE,
  PROMPT_AFFILIATE_PROGRAM_ROUTE,
  PROMPT_AMOUNT_TO_SEND_ROUTE,
  PROMPT_BASE_CRYPTO_ROUTE,
  PROMPT_BASE_FIAT_ROUTE,
  PROMPT_BIOMETRIC_SETTINGS_ROUTE,
  PROMPT_BUY_CRYPTO_ROUTE,
  PROMPT_CLOSE_APP_ROUTE,
  PROMPT_COMMENT_TO_SEND_ROUTE,
  PROMPT_COMMISSION_FOR_SENDING_ROUTE,
  PROMPT_CONFIRMATION_FOR_EXCHANGE_ROUTE,
  PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE,
  PROMPT_CRYPTO_TO_EXCHANGE_ROUTE,
  PROMPT_CRYPTO_TO_RECEIVE_ROUTE,
  PROMPT_CRYPTO_TO_SEND_ROUTE,
  PROMPT_DATE_OF_HISTORY_PERIOD_ROUTE,
  PROMPT_DOCUMENT_ROUTE,
  PROMPT_EMAIL_ROUTE,
  PROMPT_ENVIRONMENT_FORM_ROUTE,
  PROMPT_EXCHANGE_RECEIPT_ROUTE,
  PROMPT_FEEDBACK_ROUTE,
  PROMPT_IDENTITY_VERIFICATION_ADDRESS_ROUTE,
  PROMPT_INPUT_NETWORK_ROUTE,
  PROMPT_LANGUAGE_ROUTE,
  PROMPT_LIMIT_REMOVAL_ROUTE,
  PROMPT_LIMIT_UPDATE_ROUTE,
  PROMPT_NAME_AND_BIRTHDATE_ROUTE,
  PROMPT_NEW_LIMIT_ROUTE,
  PROMPT_NEW_PIN_ROUTE,
  PROMPT_NEW_WALLET_ROUTE,
  PROMPT_NICKNAME_ROUTE,
  PROMPT_OTP_TO_ADD_IP_ROUTE,
  PROMPT_OTP_TO_BIOMETRICS_ROUTE,
  PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE,
  PROMPT_OTP_TO_CLEAR_ALLOWED_LIST_ROUTE,
  PROMPT_OTP_TO_DELETE_ACCOUNT_ROUTE,
  PROMPT_OTP_TO_DELETE_IP_ROUTE,
  PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE,
  PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE,
  PROMPT_OTP_TO_EXCHANGE_ROUTE,
  PROMPT_OTP_TO_LIMIT_CREATE_ROUTE,
  PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE,
  PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE,
  PROMPT_OTP_TO_SEND_CRYPTO_ROUTE,
  PROMPT_OTP_TO_UPDATE_IP_ROUTE,
  PROMPT_OUTPUT_ADDRESS_ROUTE,
  PROMPT_OUTPUT_NETWORK_ROUTE,
  PROMPT_PERIOD_TO_FILTER_HISTORY_ROUTE,
  PROMPT_RATE_ROUTE,
  PROMPT_RECEIVE_NETWORK_ROUTE,
  PROMPT_RESIDENTIAL_ADDRESS_ROUTE,
  PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE,
  PROMPT_SESSION_DELETION,
  PROMPT_SIGN_OUT_ROUTE,
  PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE,
  PROMPT_THEME_ROUTE,
  PROMPT_TO_CLEAR_ALLOWED_LIST_ROUTE,
  PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE,
  PROMPT_TO_DELETE_IP_ROUTE,
  PROMPT_TRANSACTION_KIND_TO_FILTER_HISTORY_ROUTE,
  PROMPT_UPDATE_IP_ROUTE,
  PROMPT_USER_ID_ROUTE,
  PROMPT_WALLET_GROUP_TO_FILTER_HISTORY_ROUTE,
  PROMPT_WALLET_TO_FILTER_HISTORY_ROUTE,
  REDIRECT_TO_SEND_ROUTE,
  SHOW_LIMIT_ROUTE,
  SHOW_PROFILE_ROUTE,
  SHOW_QR_CODE_SCANNER_ROUTE,
  SHOW_QR_ROUTE,
  SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE,
  SHOW_TRANSACTION_REPORT_ROUTE,
  SHOW_TRANSACTION_ROUTE,
  SHOW_TWO_FA_GENERATED_SECRET_ROUTE,
  SHOW_TWO_FA_SETTINGS_ROUTE,
  SHOW_WALLET_ROUTE,
  TWO_FA_WELCOME_ROUTE,
} from '../CommonState';

/**
 * fixme shorten
 * @summary paths should never collapse
 */
const routePathMap = {
  [LIST_WALLETS_ROUTE]: '',
  [LIST_MENU_CATEGORIES_ROUTE]: 'menu',
  [PROMPT_THEME_ROUTE]: 'theme',
  [PROMPT_LANGUAGE_ROUTE]: 'lang',
  [LIST_NOTIFICATIONS_ROUTE]: 'log',
  [LIST_NOTIFICATION_SETTINGS_ROUTE]: 'logOpt',
  [SHOW_PROFILE_ROUTE]: 'profile',
  [PROMPT_NICKNAME_ROUTE]: 'nickname',
  [PROMPT_USER_ID_ROUTE]: 'userId',
  [PROMPT_EMAIL_ROUTE]: 'email',
  [LIST_PERSONAL_DATA_ROUTE]: 'personalData',
  [PROMPT_NAME_AND_BIRTHDATE_ROUTE]: 'nameAndBirth',
  [PROMPT_RESIDENTIAL_ADDRESS_ROUTE]: 'residentialAddress',
  [PROMPT_IDENTITY_VERIFICATION_ADDRESS_ROUTE]: 'selectCountry',
  [LIST_DOCUMENTS_ROUTE]: 'docs',
  [PROMPT_DOCUMENT_ROUTE]: 'newDoc',
  [PROMPT_BASE_CRYPTO_ROUTE]: 'baseCrypto',
  [PROMPT_BASE_FIAT_ROUTE]: 'baseFiat',
  [LIST_SECURITY_SETTINGS_ROUTE]: 'security',
  [SHOW_TWO_FA_SETTINGS_ROUTE]: '2fa',
  [SHOW_TWO_FA_GENERATED_SECRET_ROUTE]: '2faSecret',
  [LIST_AUTH_APPS_ROUTE]: 'authApps',
  [TWO_FA_WELCOME_ROUTE]: '2faWelcome',
  [PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE]: '2faselect',
  [PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE]: 'enable2fa',
  [PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE]: 'disable2fa',
  [PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE]: 'edit2faSettings',
  [LIST_ALLOWED_IPS_ROUTE]: 'allowedIps',
  [PROMPT_UPDATE_IP_ROUTE]: 'updateIp',
  [PROMPT_ADD_IP_ROUTE]: 'addIp',
  [PROMPT_AFFILIATE_PROGRAM_ROUTE]: 'affProgram',
  [LIST_BLOCKED_IPS_ROUTE]: 'blockedIps',
  [PROMPT_TO_DELETE_IP_ROUTE]: 'deleteIp',
  [PROMPT_OTP_TO_DELETE_IP_ROUTE]: 'otpToDeleteIp',
  [PROMPT_TO_CLEAR_ALLOWED_LIST_ROUTE]: 'clearAllowedList',
  [PROMPT_OTP_TO_CLEAR_ALLOWED_LIST_ROUTE]: 'otpToClearAllowedList',
  [PROMPT_OTP_TO_ADD_IP_ROUTE]: 'otpToAddIp',
  [PROMPT_OTP_TO_UPDATE_IP_ROUTE]: 'otpToUpdateIp',
  [LIST_SESSION_HISTORY_ROUTE]: 'allSessions',
  [LIST_ACTIVE_SESSIONS_ROUTE]: 'activeSessions',
  [CHECK_PIN_ROUTE]: 'checkPin',
  [CHECK_BIOMETRICS_PIN_ROUTE]: 'checkBiometricsPin',
  [PROMPT_OTP_TO_BIOMETRICS_ROUTE]: 'otpToBiometricsPin',
  [PROMPT_BIOMETRIC_SETTINGS_ROUTE]: 'biometricSettings',
  [PROMPT_NEW_PIN_ROUTE]: 'updatePin',
  [NOTIFY_ABOUT_SUCCESSFUL_PIN_CHANGE]: 'pinChangeSuccess',
  [LIST_LIMITS_ROUTE]: 'limits',
  [PROMPT_NEW_LIMIT_ROUTE]: 'newLimit',
  [PROMPT_OTP_TO_LIMIT_CREATE_ROUTE]: 'otpToNewLimit',
  [PROMPT_LIMIT_UPDATE_ROUTE]: 'updateLimit',
  [PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE]: 'otpToUpdateLimit',
  [SHOW_LIMIT_ROUTE]: 'limit',
  [PROMPT_LIMIT_REMOVAL_ROUTE]: 'removeLimit',
  [PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE]: 'otpToRemoveLimit',
  [PROMPT_NEW_WALLET_ROUTE]: 'newWallet',
  [PROMPT_BUY_CRYPTO_ROUTE]: 'buyCrypto',
  [SHOW_WALLET_ROUTE]: 'wallet',
  [LIST_INPUT_ADDRESSES_ROUTE]: 'inAddresses',
  [PROMPT_INPUT_NETWORK_ROUTE]: 'inNetwork',
  [SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE]: 'receive',
  [PROMPT_CRYPTO_TO_RECEIVE_ROUTE]: 'cryptoToReceive',
  [PROMPT_ADDRESS_FORMAT_ROUTE]: 'addressFormat',
  [PROMPT_RECEIVE_NETWORK_ROUTE]: 'receiveNetwork',
  [REDIRECT_TO_SEND_ROUTE]: 'o',
  [PROMPT_CRYPTO_TO_SEND_ROUTE]: 'cryptoToSend',
  [PROMPT_OUTPUT_ADDRESS_ROUTE]: 'outAddress',
  [PROMPT_OUTPUT_NETWORK_ROUTE]: 'outNetwork',
  [PROMPT_AMOUNT_TO_SEND_ROUTE]: 'amountToSend',
  [SHOW_QR_ROUTE]: 'showQr',
  [GENERATE_PAY_ME_LINK_ROUTE]: 'GeneratePayMeLink',
  [PROMPT_COMMENT_TO_SEND_ROUTE]: 'commentToSend',
  [PROMPT_COMMISSION_FOR_SENDING_ROUTE]: 'commissionForSending',
  [PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE]: 'confirmSending',
  [PROMPT_OTP_TO_SEND_CRYPTO_ROUTE]: 'otpToSend',
  [NOTIFY_ABOUT_SUCCESSFUL_SENDING_ROUTE]: 'sendingSuccess',
  [PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE]: 'sourceCryptoToExchange',
  [PROMPT_EXCHANGE_RECEIPT_ROUTE]: 'exchangeReceipt',
  [PROMPT_CRYPTO_TO_EXCHANGE_ROUTE]: 'cryptoToExchange',
  [PROMPT_CONFIRMATION_FOR_EXCHANGE_ROUTE]: 'confirmExchange',
  [PROMPT_OTP_TO_EXCHANGE_ROUTE]: 'otpToExchange',
  [NOTIFY_ABOUT_SUCCESSFUL_EXCHANGE_ROUTE]: 'exchangeSuccess',
  [LIST_HISTORY_ROUTE]: 'ListHistory',
  [LIST_HISTORY_FILTERS_ROUTE]: 'historyFilters',
  [PROMPT_WALLET_GROUP_TO_FILTER_HISTORY_ROUTE]: 'walletGroupToFilterHistory',
  [PROMPT_WALLET_TO_FILTER_HISTORY_ROUTE]: 'walletToFilterHistory',
  [PROMPT_TRANSACTION_KIND_TO_FILTER_HISTORY_ROUTE]:
    'transactionKindToFilterHistory',
  [PROMPT_PERIOD_TO_FILTER_HISTORY_ROUTE]: 'periodToFilterHistory',
  [PROMPT_DATE_OF_HISTORY_PERIOD_ROUTE]: 'dateOfHistoryPeriod',
  [SHOW_TRANSACTION_ROUTE]: 'transaction',
  [SHOW_TRANSACTION_REPORT_ROUTE]: 'transactionReport',
  [SHOW_QR_CODE_SCANNER_ROUTE]: 'qr',
  [LIST_QR_CODE_HISTORY_ROUTE]: 'qrHistory',
  [PROMPT_RATE_ROUTE]: 'appRate',
  [PROMPT_ACCOUNT_DELETION_ROUTE]: 'accountDeletion',
  [PROMPT_OTP_TO_DELETE_ACCOUNT_ROUTE]: 'otpToDeleteAccount',
  [PROMPT_FEEDBACK_ROUTE]: 'appFeedback',
  [DEBUG_ROUTE]: 'debug',
  [LIST_LOGS_ROUTE]: 'debugLogs',
  [LIST_SIGN_OUT_REASON_LOGS_ROUTE]: 'signOutLogs',
  [LIST_ENVIRONMENT_ROUTE]: 'debugEnvs',
  [PROMPT_ENVIRONMENT_FORM_ROUTE]: 'debugNewEnv',
  [PROMPT_SESSION_DELETION]: 'PromptSessionDeletion',
  [ADD_IDENTITY_NEW_DOCUMENT_KIND_ROUTE]: 'addDocument',
  [IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE]: 'uploadDocument',
  [PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE]: 'removeDocument',
  [PROMPT_SIGN_OUT_ROUTE]: 'signOut',
  [PROMPT_CLOSE_APP_ROUTE]: 'closeApp',
  [FALL_BACK_ROUTE]: '404',
} as const;

export default routePathMap;
