/* eslint-disable @typescript-eslint/unbound-method */
import {useRoot, variance} from '@ncwallet-app/core';
import {ButtonVariant, Input, InputType, InputVariant} from '@ncwallet-app/ui';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import type {PersonalFormStateProps} from '../../../../shared/AccountDataForm/PersonalFormState/usePersonalFormState';
import {
  BIRTHDAY_LENGTH,
  FIRST_NAME_LENGTH,
  LAST_NAME_LENGTH,
  usePersonalFormState,
} from '../../../../shared/AccountDataForm/PersonalFormState/usePersonalFormState';
import {BIRTHDAY_ERROR_TO_LOCALE} from '../../../../shared/AccountDataForm/PersonalFormState/validatePersonalForm';
import {
  BodyTag,
  CardButtonTag,
  HeaderTag,
  HeaderTitleTag,
  InfoTextTag,
  LabelTag,
} from '../useCardStyles';
import BackButton from './BackButton';

export type PersonalFormProps = {
  onBack: () => void;
} & PersonalFormStateProps;

export const getInputVariant = (isValid?: boolean) => {
  return isNil(isValid) || isValid ? InputVariant.Default : InputVariant.Error;
};

export default observer(function PersonalForm(props: PersonalFormProps) {
  const {localization} = useRoot();
  const {formState, handleSubmit} = usePersonalFormState(props);
  const getErrorMessage = () => {
    if (formState.errorMap?.firstName || formState.errorMap?.lastName) {
      return localization.getTranslation(
        'profileSettingsScreen.verificationError',
      );
    }

    if (formState.errorMap?.birthday) {
      return localization.getTranslation(
        BIRTHDAY_ERROR_TO_LOCALE[formState.errorMap.birthday],
      );
    }

    return '';
  };
  return (
    <>
      <HeaderTag>
        <BackButton onPress={props.onBack} />
        <HeaderTitleTag>
          {localization.getTranslation(
            'profileSettingsScreen.verification.personInfo.title',
          )}
        </HeaderTitleTag>
        <StepText>
          {localization.getTranslation(
            'profileSettingsScreen.verificationStep',
          )}{' '}
          1/3
        </StepText>
      </HeaderTag>
      <BodyTag>
        <InfoTextTag>
          {localization.getTranslation(
            'profileSettingsScreen.verificationInfoText',
          )}
        </InfoTextTag>
        <LabelTag>
          {localization.getTranslation(
            'profileSettingsScreen.verificationLabel',
          )}{' '}
          *
        </LabelTag>
        <Input
          variant={getInputVariant(!formState.errorMap?.firstName)}
          placeholder={localization.getTranslation(
            'profileSettingsScreen.verificationPlaceholderFirst',
          )}
          value={formState.firstName}
          onChangeText={formState.setFirstName}
          maxLength={FIRST_NAME_LENGTH}
        />
        <SizedBox />
        <Input
          variant={getInputVariant(!formState.errorMap?.lastName)}
          placeholder={localization.getTranslation(
            'profileSettingsScreen.verificationPlaceholderSecond',
          )}
          value={formState.lastName}
          onChangeText={formState.setLastName}
          maxLength={LAST_NAME_LENGTH}
        />
        <SizedBox />
        <LabelTag>
          {`${localization.getTranslation(
            'profileSettingsScreen.verification.personInfo.birthDateLabel',
          )} (${localization.getTranslation('datePlaceholder')}) *`}
        </LabelTag>
        <Input
          variant={getInputVariant(!formState.errorMap?.birthday)}
          placeholder={localization.getTranslation('datePlaceholder')}
          value={formState.birthday}
          onChangeText={formState.setBirthday}
          type={InputType.Numeric}
          maxLength={BIRTHDAY_LENGTH}
        />
        {(formState.errorMap?.firstName ||
          formState.errorMap?.lastName ||
          formState.errorMap?.birthday) && (
          <ErrorText>{getErrorMessage()}</ErrorText>
        )}

        <CardButtonTag
          title={localization.getTranslation(
            'profileSettingsScreen.nextButton',
          )}
          variant={ButtonVariant.Primary}
          onPress={handleSubmit}
        />
      </BodyTag>
    </>
  );
});

const SizedBox = variance(View)(() => ({
  root: {
    height: 10,
  },
}));

const StepText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textAdditional1,
    fontSize: 14,
    lineHeight: 17,
    marginLeft: 'auto',
  },
  disabled: {
    color: theme.palette.uiMain,
  },
}));

const ErrorText = variance(Text)(theme => ({
  root: {
    marginTop: 10,
    ...theme.fontByWeight('500'),
    fontSize: 13,
    lineHeight: 20,
    color: theme.palette.error,
  },
}));
