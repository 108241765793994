import type {AddressInfo, InOutCurrency} from '@ncwallet-app/core';
import {
  getDefaultInOutCurrency,
  isNetworkChangePossible,
  useRoot,
} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {
  ListInputAddressesRoute,
  PromptAddressFormatRoute,
  PromptInputNetworkRoute,
  RouteParams,
  ShowQrToReceiveCryptoRoute,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {RouteTransition} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransition';
import type {RouteTransitionMap} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransitionMap';
import {getLastCreatedAddressInfo} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {getDefaultAddressParams} from '@ncwallet-app/core/src/NCWalletServer/InOutCurrency';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {autorun, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';

import {useReceiveCryptoAddressesBindingState} from '../../Navigation/HomeStack/ReceiveCryptoAddressesBInding/useReceiveCryptoAddressesBindingState';
import {useNavigationGetIsFocused} from '../../Navigation/hooks';
import {ReceiveCryptoAddressesScreen} from '../../screens/ReceiveCryptoAddressesScreen';

export type ListInputAddressesContainerProps = {
  params: RouteParams<ListInputAddressesRoute>;
  goToChangeNetwork: () => void;
  onBackPress: () => void;
  showQrToReceiveCrypto: RouteTransition<ShowQrToReceiveCryptoRoute>;
  updateHeaderAction?: (availableNetworks: InOutCurrency[] | undefined) => void;
} & RouteTransitionMap<PromptAddressFormatRoute | PromptInputNetworkRoute>;

export default observer(function ListInputAddressesContainer(
  props: ListInputAddressesContainerProps,
) {
  const {
    params,
    showQrToReceiveCrypto,
    promptInputNetwork,
    promptAddressFormat,
    goToChangeNetwork,
    updateHeaderAction,
    onBackPress,
  } = props;
  const getIsReady = useGetIsReadyToMakeRequests();
  const getIsFocused = useNavigationGetIsFocused();
  const [state, refresh] = useReceiveCryptoAddressesBindingState(
    params.walletId,
    params.address,
    params.addressNetwork,
    params.networkFilter,
  );

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          if (state.addresses?.length === 0) {
            runInAction(() => {
              void state.createAddress(
                params.networkFilter || params.addressNetwork,
              );
            });
          }

          updateHeaderAction?.(state.availableNetworks);
        }
      }),
    [
      state,
      updateHeaderAction,
      getIsFocused,
      getIsReady,
      params.addressNetwork,
      params.networkFilter,
    ],
  );

  const {navigationContainer} = useRoot();
  const goToAddress = useCallback(
    (addressInfo: AddressInfo) => {
      showQrToReceiveCrypto({
        address: addressInfo.address,
        addressNetwork: params.networkFilter,
        addressCurrency: params.currencyFilter,
        depositType: params.depositType,
        walletId: params.walletId,
      });
    },
    [
      showQrToReceiveCrypto,
      params.networkFilter,
      params.currencyFilter,
      params.depositType,
      params.walletId,
    ],
  );
  const onNewPress = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const crypto = state.crypto!;
    const defaultCurrencyIn = getDefaultInOutCurrency(crypto, 'in');

    if (isNetworkChangePossible(crypto, 'in')) {
      promptInputNetwork({
        walletId: params.walletId,
        ...getDefaultAddressParams(defaultCurrencyIn),
      });
      return;
    }

    if (defaultCurrencyIn.address_types.length > 1) {
      promptAddressFormat({
        walletId: params.walletId,
        ...getDefaultAddressParams(defaultCurrencyIn),
      });
      return;
    }

    const res = await state.createAddress(
      defaultCurrencyIn.network,
      defaultCurrencyIn.default_type,
    );
    if (res.success) {
      const newAddress = getLastCreatedAddressInfo(
        res.right.addresses,
        defaultCurrencyIn.network,
        defaultCurrencyIn.default_type,
      );
      navigationContainer.ref?.navigate('ShowQrToReceiveCrypto', {
        walletId: params.walletId,
        address: newAddress?.address,
        addressCurrency: crypto.code,
        addressNetwork: newAddress?.network,
        type: newAddress?.type,
      });
    }
  }, [
    navigationContainer.ref,
    params.walletId,
    promptAddressFormat,
    promptInputNetwork,
    state,
  ]);

  return (
    <ReceiveCryptoAddressesScreen
      showAddressNetwork={state.showNetwork}
      defaultNetwork={state.defaultNetwork}
      selectedAddress={state.addressInfo}
      addresses={state.addresses}
      availableNetworks={state.availableNetworks}
      goToChangeNetwork={goToChangeNetwork}
      onAddressSelect={goToAddress}
      onRefresh={refresh}
      getIsLoading={state.getIsLoading}
      insets={SafeAreaInset.BOTTOM}
      onNewPress={onNewPress}
      onBackPress={onBackPress}
    />
  );
});
