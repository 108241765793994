import {turnOut} from '../util';
import {
  ADD_IDENTITY_NEW_DOCUMENT_KIND_ROUTE,
  CHECK_BIOMETRICS_PIN_ROUTE,
  CHECK_PIN_ROUTE,
  DEBUG_ROUTE,
  FALL_BACK_ROUTE,
  GENERATE_PAY_ME_LINK_ROUTE,
  IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE,
  LIST_ACTIVE_SESSIONS_ROUTE,
  LIST_ALLOWED_IPS_ROUTE,
  LIST_BLOCKED_IPS_ROUTE,
  LIST_DOCUMENTS_ROUTE,
  LIST_ENVIRONMENT_ROUTE,
  LIST_HISTORY_FILTERS_ROUTE,
  LIST_HISTORY_ROUTE,
  LIST_INPUT_ADDRESSES_ROUTE,
  LIST_LIMITS_ROUTE,
  LIST_LOGS_ROUTE,
  LIST_MENU_CATEGORIES_ROUTE,
  LIST_NOTIFICATION_SETTINGS_ROUTE,
  LIST_NOTIFICATIONS_ROUTE,
  LIST_PERSONAL_DATA_ROUTE,
  LIST_QR_CODE_HISTORY_ROUTE,
  LIST_SECURITY_SETTINGS_ROUTE,
  LIST_SESSION_HISTORY_ROUTE,
  LIST_SIGN_OUT_REASON_LOGS_ROUTE,
  LIST_WALLETS_FILTER,
  LIST_WALLETS_ROUTE,
  NOTIFY_ABOUT_SUCCESSFUL_EXCHANGE_ROUTE,
  NOTIFY_ABOUT_SUCCESSFUL_SENDING_ROUTE,
  PROMPT_ACCOUNT_DELETION_ROUTE,
  PROMPT_ADD_IP_ROUTE,
  PROMPT_ADDRESS_FORMAT_ROUTE,
  PROMPT_AFFILIATE_PROGRAM_ROUTE,
  PROMPT_AMOUNT_TO_SEND_ROUTE,
  PROMPT_BASE_CRYPTO_ROUTE,
  PROMPT_BASE_FIAT_ROUTE,
  PROMPT_BIOMETRIC_SETTINGS_ROUTE,
  PROMPT_BUY_CRYPTO_ROUTE,
  PROMPT_CLOSE_APP_ROUTE,
  PROMPT_COMMENT_TO_SEND_ROUTE,
  PROMPT_COMMISSION_FOR_SENDING_ROUTE,
  PROMPT_CONFIRMATION_FOR_EXCHANGE_ROUTE,
  PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE,
  PROMPT_CRYPTO_TO_EXCHANGE_ROUTE,
  PROMPT_CRYPTO_TO_RECEIVE_ROUTE,
  PROMPT_CRYPTO_TO_SEND_ROUTE,
  PROMPT_DATE_OF_HISTORY_PERIOD_ROUTE,
  PROMPT_DOCUMENT_PERMISSION_ROUTE,
  PROMPT_EMAIL_ROUTE,
  PROMPT_ENVIRONMENT_FORM_ROUTE,
  PROMPT_EXCHANGE_RECEIPT_ROUTE,
  PROMPT_FEEDBACK_ROUTE,
  PROMPT_IDENTITY_VERIFICATION_ADDRESS_ROUTE,
  PROMPT_INPUT_NETWORK_ROUTE,
  PROMPT_LANGUAGE_ROUTE,
  PROMPT_LIMIT_REMOVAL_ROUTE,
  PROMPT_LIMIT_UPDATE_ROUTE,
  PROMPT_LINK_TO_EMAIL_ROUTE,
  PROMPT_LINK_TO_TELEGRAM_ROUTE,
  PROMPT_NAME_AND_BIRTHDATE_ROUTE,
  PROMPT_NEW_LIMIT_ROUTE,
  PROMPT_NEW_PIN_ROUTE,
  PROMPT_NEW_WALLET_ROUTE,
  PROMPT_NICKNAME_ROUTE,
  PROMPT_OTP_TO_ADD_IP_ROUTE,
  PROMPT_OTP_TO_BIOMETRICS_ROUTE,
  PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE,
  PROMPT_OTP_TO_CLEAR_ALLOWED_LIST_ROUTE,
  PROMPT_OTP_TO_DELETE_ACCOUNT_ROUTE,
  PROMPT_OTP_TO_DELETE_IP_ROUTE,
  PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE,
  PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE,
  PROMPT_OTP_TO_EXCHANGE_ROUTE,
  PROMPT_OTP_TO_LIMIT_CREATE_ROUTE,
  PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE,
  PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE,
  PROMPT_OTP_TO_SEND_CRYPTO_ROUTE,
  PROMPT_OTP_TO_UPDATE_IP_ROUTE,
  PROMPT_OUTPUT_ADDRESS_ROUTE,
  PROMPT_OUTPUT_NETWORK_ROUTE,
  PROMPT_PERIOD_TO_FILTER_HISTORY_ROUTE,
  PROMPT_RATE_ROUTE,
  PROMPT_RECEIVE_NETWORK_ROUTE,
  PROMPT_RESIDENTIAL_ADDRESS_ROUTE,
  PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE,
  PROMPT_SESSION_DELETION,
  PROMPT_SIGN_OUT_ROUTE,
  PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE,
  PROMPT_THEME_ROUTE,
  PROMPT_TO_CHOOSE_IDENTITY_FILE_ROUTE,
  PROMPT_TO_CLEAR_ALLOWED_LIST_ROUTE,
  PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE,
  PROMPT_TO_DELETE_IP_ROUTE,
  PROMPT_TRANSACTION_KIND_TO_FILTER_HISTORY_ROUTE,
  PROMPT_UPDATE_IP_ROUTE,
  PROMPT_USER_ID_ROUTE,
  PROMPT_WALLET_GROUP_TO_FILTER_HISTORY_ROUTE,
  PROMPT_WALLET_TO_FILTER_HISTORY_ROUTE,
  REDIRECT_TO_SEND_ROUTE,
  SHOW_DOCUMENT_CAMERA_ROUTE,
  SHOW_LIMIT_ROUTE,
  SHOW_PROFILE_ROUTE,
  SHOW_QR_CODE_SCANNER_ROUTE,
  SHOW_QR_ROUTE,
  SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE,
  SHOW_TRANSACTION_REPORT_ROUTE,
  SHOW_TRANSACTION_ROUTE,
  SHOW_TWO_FA_GENERATED_SECRET_ROUTE,
  SHOW_TWO_FA_SETTINGS_ROUTE,
  SHOW_WALLET_ROUTE,
  TWO_FA_WELCOME_ROUTE,
} from './CommonState';

export const nameToKindMap = {
  FallBack: FALL_BACK_ROUTE,
  ListWallets: LIST_WALLETS_ROUTE,
  ListMenuCategories: LIST_MENU_CATEGORIES_ROUTE,
  PromptThemeSelect: PROMPT_THEME_ROUTE,
  PromptLanguage: PROMPT_LANGUAGE_ROUTE,
  ListNotifications: LIST_NOTIFICATIONS_ROUTE,
  ListNotificationSettings: LIST_NOTIFICATION_SETTINGS_ROUTE,
  ShowProfile: SHOW_PROFILE_ROUTE,
  PromptNickname: PROMPT_NICKNAME_ROUTE,
  PromptUserId: PROMPT_USER_ID_ROUTE,
  PromptEmail: PROMPT_EMAIL_ROUTE,
  ListPersonalData: LIST_PERSONAL_DATA_ROUTE,
  PromptNameAndBirthdate: PROMPT_NAME_AND_BIRTHDATE_ROUTE,
  PromptResidentialAddress: PROMPT_RESIDENTIAL_ADDRESS_ROUTE,
  PromptIdentityVerificationAddress: PROMPT_IDENTITY_VERIFICATION_ADDRESS_ROUTE,
  ListDocuments: LIST_DOCUMENTS_ROUTE,
  PromptToChooseIdentityFile: PROMPT_TO_CHOOSE_IDENTITY_FILE_ROUTE,
  AddIdentityNewDocumentKind: ADD_IDENTITY_NEW_DOCUMENT_KIND_ROUTE,
  IdentityVerificationUploadDocument:
    IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE,
  PromptToDeleteDocumentFile: PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE,
  PromptBaseCryptoSetting: PROMPT_BASE_CRYPTO_ROUTE,
  PromptBaseFiatSetting: PROMPT_BASE_FIAT_ROUTE,
  ListSecuritySettings: LIST_SECURITY_SETTINGS_ROUTE,
  ShowTwoFaSettings: SHOW_TWO_FA_SETTINGS_ROUTE,
  ShowTwoFaGenerateSecret: SHOW_TWO_FA_GENERATED_SECRET_ROUTE,
  PromptOtpToEnableTwoFa: PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE,
  PromptOtpToDisableTwoFa: PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE,
  PromptOtpToChangeTwoFaSettings: PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE,
  ListAllowedIp: LIST_ALLOWED_IPS_ROUTE,
  PromptUpdateIp: PROMPT_UPDATE_IP_ROUTE,
  PromptAddIp: PROMPT_ADD_IP_ROUTE,
  PromptAffiliateProgram: PROMPT_AFFILIATE_PROGRAM_ROUTE,
  ListBlockedIp: LIST_BLOCKED_IPS_ROUTE,
  PromptToDeleteIp: PROMPT_TO_DELETE_IP_ROUTE,
  PromptOtpToDeleteIp: PROMPT_OTP_TO_DELETE_IP_ROUTE,
  PromptOtpToAddIp: PROMPT_OTP_TO_ADD_IP_ROUTE,
  PromptOtpToUpdateIp: PROMPT_OTP_TO_UPDATE_IP_ROUTE,
  PromptToClearAllowedList: PROMPT_TO_CLEAR_ALLOWED_LIST_ROUTE,
  PromptOtpToClearAllowedList: PROMPT_OTP_TO_CLEAR_ALLOWED_LIST_ROUTE,
  ListSessionHistory: LIST_SESSION_HISTORY_ROUTE,
  ListActiveSessions: LIST_ACTIVE_SESSIONS_ROUTE,
  CheckPin: CHECK_PIN_ROUTE,
  CheckBiometricsPin: CHECK_BIOMETRICS_PIN_ROUTE,
  PromptOtpToBiometrics: PROMPT_OTP_TO_BIOMETRICS_ROUTE,
  PromptBiometricSettings: PROMPT_BIOMETRIC_SETTINGS_ROUTE,
  PromptNewPin: PROMPT_NEW_PIN_ROUTE,
  ListLimits: LIST_LIMITS_ROUTE,
  PromptNewLimit: PROMPT_NEW_LIMIT_ROUTE,
  PromptLimitUpdate: PROMPT_LIMIT_UPDATE_ROUTE,
  PromptLimitRemoval: PROMPT_LIMIT_REMOVAL_ROUTE,
  PromptOtpToLimitCreate: PROMPT_OTP_TO_LIMIT_CREATE_ROUTE,
  PromptOtpToLimitUpdate: PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE,
  PromptOtpToLimitRemoval: PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE,
  ShowLimit: SHOW_LIMIT_ROUTE,
  PromptNewWallet: PROMPT_NEW_WALLET_ROUTE,
  PromptBuyCrypto: PROMPT_BUY_CRYPTO_ROUTE,
  ShowWallet: SHOW_WALLET_ROUTE,
  PromptReceiveNetwork: PROMPT_RECEIVE_NETWORK_ROUTE,
  ListInputAddresses: LIST_INPUT_ADDRESSES_ROUTE,
  PromptInputNetwork: PROMPT_INPUT_NETWORK_ROUTE,
  ShowQrToReceiveCrypto: SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE,
  PromptSessionDeletion: PROMPT_SESSION_DELETION,
  PromptCryptoToReceive: PROMPT_CRYPTO_TO_RECEIVE_ROUTE,
  PromptAddressFormat: PROMPT_ADDRESS_FORMAT_ROUTE,
  PromptCryptoToSend: PROMPT_CRYPTO_TO_SEND_ROUTE,
  PromptOutputAddress: PROMPT_OUTPUT_ADDRESS_ROUTE,
  PromptOutputNetwork: PROMPT_OUTPUT_NETWORK_ROUTE,
  PromptAmountToSend: PROMPT_AMOUNT_TO_SEND_ROUTE,
  PromptCommentToSend: PROMPT_COMMENT_TO_SEND_ROUTE,
  PromptCommissionForSending: PROMPT_COMMISSION_FOR_SENDING_ROUTE,
  PromptConfirmationToSendCrypto: PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE,
  PromptOtpToSendCrypto: PROMPT_OTP_TO_SEND_CRYPTO_ROUTE,
  NotifyAboutSuccessfulSending: NOTIFY_ABOUT_SUCCESSFUL_SENDING_ROUTE,
  PromptSourceCryptoToExchange: PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE,
  PromptExchangeReceipt: PROMPT_EXCHANGE_RECEIPT_ROUTE,
  PromptCryptoToExchange: PROMPT_CRYPTO_TO_EXCHANGE_ROUTE,
  PromptConfirmationForExchange: PROMPT_CONFIRMATION_FOR_EXCHANGE_ROUTE,
  PromptOtpToExchange: PROMPT_OTP_TO_EXCHANGE_ROUTE,
  NotifyAboutSuccessfulExchange: NOTIFY_ABOUT_SUCCESSFUL_EXCHANGE_ROUTE,
  ListHistory: LIST_HISTORY_ROUTE,
  ListHistoryFilters: LIST_HISTORY_FILTERS_ROUTE,
  PromptWalletGroupToFilterHistory: PROMPT_WALLET_GROUP_TO_FILTER_HISTORY_ROUTE,
  PromptWalletToFilterHistory: PROMPT_WALLET_TO_FILTER_HISTORY_ROUTE,
  PromptTransactionKindToFilterHistory:
    PROMPT_TRANSACTION_KIND_TO_FILTER_HISTORY_ROUTE,
  PromptPeriodToFilterHistory: PROMPT_PERIOD_TO_FILTER_HISTORY_ROUTE,
  PromptDateOfHistoryPeriod: PROMPT_DATE_OF_HISTORY_PERIOD_ROUTE,
  ShowTransaction: SHOW_TRANSACTION_ROUTE,
  ShowTransactionReport: SHOW_TRANSACTION_REPORT_ROUTE,
  ScanQrCodeScanner: SHOW_QR_CODE_SCANNER_ROUTE,
  ListQrCodeHistory: LIST_QR_CODE_HISTORY_ROUTE,
  PromptRate: PROMPT_RATE_ROUTE,
  PromptFeedback: PROMPT_FEEDBACK_ROUTE,
  PromptAccountDeletion: PROMPT_ACCOUNT_DELETION_ROUTE,
  PromptLinkToEmail: PROMPT_LINK_TO_EMAIL_ROUTE,
  PromptLinkToTelegram: PROMPT_LINK_TO_TELEGRAM_ROUTE,
  PromptOtpToDeleteAccount: PROMPT_OTP_TO_DELETE_ACCOUNT_ROUTE,
  Debug: DEBUG_ROUTE,
  ListLogs: LIST_LOGS_ROUTE,
  ListSignOutReasonLogs: LIST_SIGN_OUT_REASON_LOGS_ROUTE,
  ListEnvironment: LIST_ENVIRONMENT_ROUTE,
  PromptEnvironmentForm: PROMPT_ENVIRONMENT_FORM_ROUTE,
  ShowQr: SHOW_QR_ROUTE,
  ShowDocumentCamera: SHOW_DOCUMENT_CAMERA_ROUTE,
  PromptDocumentPermission: PROMPT_DOCUMENT_PERMISSION_ROUTE,
  GeneratePayMeLink: GENERATE_PAY_ME_LINK_ROUTE,
  RedirectToSend: REDIRECT_TO_SEND_ROUTE,
  PromptSignOut: PROMPT_SIGN_OUT_ROUTE,
  PromptCloseApp: PROMPT_CLOSE_APP_ROUTE,
  TwoFaWelcome: TWO_FA_WELCOME_ROUTE,
  PromptSelectTwoFaProvider: PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE,
  ListWalletOptions: LIST_WALLETS_FILTER,
} as const;
export type NameToKindMap = typeof nameToKindMap;

export const kindToNameMap = turnOut(nameToKindMap);
export type KindToNameMap = typeof kindToNameMap;
