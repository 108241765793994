import {FULFILLED, useRoot, useStrings} from '@ncwallet-app/core';
import {useAccountState} from '@ncwallet-app/core/src/AccountStore';
import {
  IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE,
  LIST_DOCUMENTS_ROUTE,
  LIST_PERSONAL_DATA_ROUTE,
  PROMPT_BASE_CRYPTO_ROUTE,
  PROMPT_BASE_FIAT_ROUTE,
  PROMPT_IDENTITY_VERIFICATION_ADDRESS_ROUTE,
  PROMPT_NAME_AND_BIRTHDATE_ROUTE,
  PROMPT_RESIDENTIAL_ADDRESS_ROUTE,
  PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {ProfileCard} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import type {CountryNameData} from '@ncwallet-app/core/src/Localization/constant';
import type {
  AccountDocumentId,
  AccountDocumentType,
} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments';
import type {AccountDocumentFileInfo} from '@ncwallet-app/core/src/NCWalletServer/AccountsDocuments/AccountDocumentFileInfo';
import type {FiatCurrency} from '@ncwallet-app/core/src/NCWalletServer/FiatCurrency';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import useAccountVerificationBindingState from '../../../../../CommonNavigationContainers/hooks/AccountVerification/useAccountVerificationBindingState';
import {usePromptToSelectVerificationCountryBindingState} from '../../../../../CommonNavigationContainers/hooks/usePromptToSelectVerificationCountryBindingState';
import {useBaseCryptoSettingsBindingState} from '../../../../../Navigation/HomeStack/BaseCryptoSettingsBinding/useBaseCryptoSettingsBindingState';
import {useBaseFiatSettingsBindingState} from '../../../../../Navigation/HomeStack/BaseFiatSettingsBinding/useBaseFiatSettingsBindingState';
import {LgProfileSettings} from '../../../../../screens/ProfileSettingsScreen';
import {CurrencyTab} from '../../../../../screens/ProfileSettingsScreen/LgProfileCards/BaseCard';
import {VerificationSteps} from '../../../../../screens/ProfileSettingsScreen/LgProfileCards/Verification/VerificationStep';
import type {CryptoSectionItemData} from '../../../../../screens/UserSettingsScreens/BaseCryptoSettingScreen/CryptoSectionItem';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type ShowProfileBindingProps = LargeSwitchBindingProps<'ShowProfile'>;

export default observer(function ShowProfileBinding(
  props: ShowProfileBindingProps,
) {
  const {navigation, route} = props;
  const {accountStore} = useRoot();
  const verificationState = useAccountVerificationBindingState();
  const countrySelectState = usePromptToSelectVerificationCountryBindingState();
  const onDetailsInteraction = useCallback(() => {
    navigation.setParams({focusedCard: ProfileCard.Details});
  }, [navigation]);

  const verificationKind =
    route.params?.focusedVerification?.kind ?? LIST_PERSONAL_DATA_ROUTE;
  const verificationStep = stepByRoute[verificationKind];
  const goToStart = useCallback(() => {
    navigation.setParams({
      focusedCard: ProfileCard.Verification,
      focusedVerification: {kind: LIST_PERSONAL_DATA_ROUTE},
    });
  }, [navigation]);
  const goToPersonalInfoNameStep = useCallback(() => {
    navigation.setParams({
      focusedCard: ProfileCard.Verification,
      focusedVerification: {kind: PROMPT_NAME_AND_BIRTHDATE_ROUTE},
    });
  }, [navigation]);
  const goToAddress = useCallback(
    (country?: string) => {
      navigation.setParams({
        focusedCard: ProfileCard.Verification,
        focusedVerification: {
          kind: PROMPT_RESIDENTIAL_ADDRESS_ROUTE,
          params: {country},
        },
      });
    },
    [navigation],
  );
  const goToCountrySelect = useCallback(() => {
    navigation.setParams({
      focusedCard: ProfileCard.Verification,
      focusedVerification: {kind: PROMPT_IDENTITY_VERIFICATION_ADDRESS_ROUTE},
    });
  }, [navigation]);
  const goToDocumentsStep = useCallback(() => {
    navigation.setParams({
      focusedCard: ProfileCard.Verification,
      focusedVerification: {kind: LIST_DOCUMENTS_ROUTE},
    });
  }, [navigation]);

  const goToUploadDocument = useCallback(
    (documentType: AccountDocumentType) => {
      navigation.setParams({
        focusedCard: ProfileCard.Verification,
        focusedVerification: {
          kind: IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE,
          params: {type: documentType},
        },
      });
    },
    [navigation],
  );

  const promptToDeleteDocumentFile = useCallback(
    (documentId: AccountDocumentId, file: AccountDocumentFileInfo) => {
      navigation.setParams({
        focusedCard: ProfileCard.Verification,
        focusedVerification: {
          kind: PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE,
          params: {
            documentId,
            file: file.name,
          },
        },
      });
    },
    [navigation],
  );

  const currencyTab =
    route.params?.focusedBaseCurrency?.kind === PROMPT_BASE_FIAT_ROUTE
      ? CurrencyTab.Fiat
      : CurrencyTab.Crypto;
  const focusCryptoTab = useCallback(() => {
    navigation.setParams({
      focusedCard: ProfileCard.BaseCurrency,
      focusedBaseCurrency: {kind: PROMPT_BASE_CRYPTO_ROUTE},
    });
  }, [navigation]);
  const focusFiatTab = useCallback(() => {
    navigation.setParams({
      focusedCard: ProfileCard.BaseCurrency,
      focusedBaseCurrency: {kind: PROMPT_BASE_FIAT_ROUTE},
    });
  }, [navigation]);

  const baseCryptoState = useBaseCryptoSettingsBindingState();

  const {account, update} = useAccountState();
  const {flashMessage} = useRoot();
  const strings = useStrings();

  const baseFiatSettingsState = useBaseFiatSettingsBindingState();

  const showDoneMessage = useCallback(() => {
    flashMessage.showMessage({
      title: strings['profileSettingsScreen.updateBtn'],
      variant: 'success',
    });
  }, [strings, flashMessage]);

  const handleFiatSelect = useCallback(
    async (fiat: FiatCurrency) => {
      void baseFiatSettingsState.update(fiat);
    },
    [baseFiatSettingsState],
  );

  const handleUserSelect = useCallback(
    async (name: string | undefined, id: string | undefined) => {
      void update({name: name, user_id: id});
      showDoneMessage();
    },
    [showDoneMessage, update],
  );

  const handleCryptoSelect = useCallback(
    async (crypto: CryptoSectionItemData) => {
      void baseCryptoState.update(crypto);
    },
    [baseCryptoState],
  );

  const onPromptAccountDeletion = useCallback(() => {
    navigation.navigate('PromptAccountDeletion');
  }, [navigation]);

  const handleCountrySelect = useCallback(
    (country: CountryNameData) => {
      navigation.setParams({});
      countrySelectState.resetSearch();
      goToAddress(country.name);
    },
    [navigation, countrySelectState, goToAddress],
  );

  const handleDeleteFile = useCallback(async () => {
    if (
      props.route.params?.focusedVerification?.kind !==
      PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE
    ) {
      return;
    }

    const {documentId, file} = props.route.params.focusedVerification.params;

    await verificationState.deleteFile(documentId, file);
    goToDocumentsStep();
  }, [goToDocumentsStep, verificationState, props.route]);

  const isVerified =
    accountStore.state?.status === FULFILLED &&
    accountStore.state.result.settings.is_verified;

  const isVerificationRequired =
    accountStore.state?.status === FULFILLED &&
    accountStore.state.result.settings.verification_required;
  const editedLocallyCountry =
    props.route.params?.focusedVerification?.kind ===
    PROMPT_RESIDENTIAL_ADDRESS_ROUTE
      ? props.route.params.focusedVerification.params?.country
      : undefined;
  const onLinkToTelegramPress = useCallback(() => {
    navigation.navigate('PromptLinkToTelegram');
  }, [navigation]);

  const onLinkToEmailPress = useCallback(() => {
    navigation.navigate('PromptLinkToEmail');
  }, [navigation]);

  return (
    <LgProfileSettings
      onDeleteAccountPress={onPromptAccountDeletion}
      currencyTab={currencyTab}
      focusCryptoTab={focusCryptoTab}
      focusFiatTab={focusFiatTab}
      baseCryptoState={baseCryptoState}
      baseCurrency={baseCryptoState.getBaseCrypto()}
      othersCurrency={baseCryptoState.getOtherCryptos()}
      baseFiatState={baseFiatSettingsState}
      baseFiat={baseFiatSettingsState.getBaseFiat()}
      othersFiat={baseFiatSettingsState.getOtherFiats()}
      onCryptoSelect={handleCryptoSelect}
      onFiatSelect={handleFiatSelect}
      onInteraction={onDetailsInteraction}
      onUserSelect={handleUserSelect}
      showDoneMessage={showDoneMessage}
      userInfo={account}
      verificationStep={verificationStep}
      verificationState={verificationState}
      countrySelectState={countrySelectState}
      goToStart={goToStart}
      goToPersonalInfoNameStep={goToPersonalInfoNameStep}
      goToAddress={goToAddress}
      goToCountrySelect={goToCountrySelect}
      handleCountrySelect={handleCountrySelect}
      goToDocumentsStep={goToDocumentsStep}
      goToUploadDocument={goToUploadDocument}
      promptToDeleteDocumentFile={promptToDeleteDocumentFile}
      onDeleteFile={handleDeleteFile}
      isVerified={isVerified}
      isVerificationRequired={isVerificationRequired}
      editedLocallyCountry={editedLocallyCountry}
      onLinkToTelegramPress={onLinkToTelegramPress}
      onLinkToEmailPress={onLinkToEmailPress}
    />
  );
});

const stepByRoute = {
  [LIST_PERSONAL_DATA_ROUTE]: VerificationSteps.Start,
  [PROMPT_NAME_AND_BIRTHDATE_ROUTE]: VerificationSteps.PersonalInfoNameStep,
  [PROMPT_RESIDENTIAL_ADDRESS_ROUTE]: VerificationSteps.Address,
  [LIST_DOCUMENTS_ROUTE]: VerificationSteps.DocumentsStep,
  [IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE]:
    VerificationSteps.UploadDocumentStep,
  [PROMPT_IDENTITY_VERIFICATION_ADDRESS_ROUTE]: VerificationSteps.SelectCountry,
  [PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE]:
    VerificationSteps.RemoveUploadedDocumentStep,
} as const;
