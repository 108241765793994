/* eslint-disable import-x/no-deprecated,@typescript-eslint/no-deprecated */
import {BigNumber} from 'bignumber.js';
import dayjs from 'dayjs';
import {isNil, reduce} from 'lodash';
import {computed, makeObservable, observable, runInAction} from 'mobx';

import type {AccountStore} from '../AccountStore';
import {getAccountFromState} from '../AccountStore';
import type {BaseAsyncOptions} from '../Async';
import type {
  CurrenciesRateHistoryStore,
  CurrenciesRateStore,
  CurrencyStore,
  WalletsBalanceHistoryStore,
  WalletStore,
} from '../dataStores';
import {RateHistoryPeriod} from '../dataStores';
import {getHistoryFromResponse} from '../dataStores/CurrenciesRateHistoryStore/AcceptedRateHistoryResponse';
import type {LastTransactionStore} from '../dataStores/LastTransactionStore';
import {
  WALLET_BALANCE_UPDATE,
  WALLET_CREATE,
  WALLET_VISIBILITY_CHANGE,
} from '../dataStores/WalletStore/WalletStoreEvents';
import {unwrap} from '../EitherAdapter';
import type {CancellationError} from '../Error';
import {CANCELLATION_ERROR, TIMEOUT_ERROR} from '../Error';
import type {ErrorRepository} from '../ErrorRepository';
import type {FlashMessage} from '../FlashMessage';
import {error} from '../fp';
import type {JsonKeyValueMap, JsonKeyValueStore} from '../JsonKeyValueStore';
import type {JsonRpcClient, JsonRpcServer} from '../JsonRpc';
import type {LegacyLocalization} from '../Localization';
import {
  toCurrencyDescriptionFromCrypto,
  toCurrencyDescriptionFromFiat,
} from '../models';
import type {
  CryptoCurrencyCode,
  CurrencyValue,
  FiatCurrencyCode,
} from '../Money';
import {MoneyStatic} from '../Money';
import type {NavigationContainer} from '../NavigationContainer';
import type {
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  NCWalletReverseCallScheme,
  NCWalletReverseNotificationScheme,
  Wallet,
  WalletId,
} from '../NCWalletServer';
import {
  patchRateHistoryWithLatestRate,
  walletsByCurrencyPopularitySorter,
} from '../NCWalletServer';
import type {Disposer} from '../structure';
import {batchDisposers} from '../structure';
import type {ISODateString} from '../Time';
import type {BaseTransactionOptions} from '../util';
import {executeWithPostpone} from '../util';
import type {WalletTransactions} from '../WalletTransactionsRequestHelper';
import type {
  FilterOption,
  WalletListItemData,
  WalletSlideData,
  WalletsState,
} from './WalletsState';

export default class WalletsStateImpl implements WalletsState {
  @observable private _walletsFilterOption: FilterOption = 'unset';
  @observable.ref private _isLoading: boolean = false;
  @observable.ref private _isManualRefresh = false;
  @observable private _isTimeoutError = false;
  private _backgroundRefreshDisposer: Disposer | undefined;

  @computed get isTimeoutError() {
    return this._isTimeoutError;
  }

  @computed
  get walletsFilterOption() {
    return this._walletsFilterOption;
  }

  set walletsFilterOption(value: FilterOption) {
    this._walletsFilterOption = value;
    void this._root.jsonKeyValueStore.set('filterOption', value);
  }

  @computed
  get isWalletsFilterAvailable() {
    return !!this._walletItems?.some(it => it.cryptoValue !== '0');
  }

  @computed
  private get _wallets() {
    const wallets = this._root.walletStore.getWallets();
    const currencies = this._root.currencyStore.getCryptoCurrencies();
    if (!wallets || !currencies) {
      return undefined;
    }

    const currenciesOrder = new Map(currencies.map((c, i) => [c.code, i]));
    return wallets
      .slice()
      .filter(w => w.is_visible)
      .sort((w1, w2) =>
        walletsByCurrencyPopularitySorter(w1, w2, currenciesOrder),
      );
  }

  @computed
  private get _walletItems() {
    return this._wallets?.map(this.walletToCurrencyListItemData);
  }

  @computed
  private get _lastTransaction() {
    return this._root.lastTransactionStore.getLastTransaction();
  }

  @computed
  private get _walletSlideData(): WalletSlideData | undefined {
    if (!this._isAllLoaded) {
      return undefined;
    }
    return {
      fiatValue: this._getTotalInBaseFiatValue() ?? '0',
      cryptoValue: this._getTotalInBaseCryptoValue() ?? '0',
      getCryptoCurrency: this._cryptoDescriptionGetter(this._baseCrypto),
      getFiatCurrency: this._fiatDescriptionGetter(this._baseFiat),
      getBalanceHistory: () => this._balanceHistory,
      getLastTransaction: () => this._lastTransaction,
    };
  }

  @computed
  private get _balanceHistory() {
    const items = this._root.walletsBalanceHistoryStore.getHistory()?.items;
    const total = this._getTotalInBaseFiatValue();
    if (!items || isNil(total)) {
      return undefined;
    }

    return [
      ...items,
      {
        amount: parseFloat(total),
        timestamp: dayjs().toISOString() as ISODateString,
      },
    ];
  }

  @computed
  private get _isAllLoaded() {
    const wallets = this._root.walletStore.getWallets();
    const currencies = this._root.currencyStore.getCryptoCurrencies();
    const baseCrypto = this._baseCrypto;
    const baseFiat = this._baseFiat;
    if (!wallets || !currencies || !baseCrypto || !baseFiat) {
      return false;
    }

    const codes = [...new Set(wallets.map(_ => _.currency))];

    return [...codes].every(c => {
      const cryptoRate = this._root.currenciesRateStore.getRate(c, baseCrypto);
      const fiatRate = this._root.currenciesRateStore.getRate(c, baseFiat);
      return !isNil(cryptoRate) && !isNil(fiatRate);
    });
  }

  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly ncWalletJsonRpcServer: JsonRpcServer<
        NCWalletReverseCallScheme,
        NCWalletReverseNotificationScheme
      >;
      readonly accountStore: AccountStore;
      readonly currencyStore: CurrencyStore;
      readonly walletStore: WalletStore;
      readonly currenciesRateStore: CurrenciesRateStore;
      readonly currenciesRateHistoryStore: CurrenciesRateHistoryStore;
      readonly walletsBalanceHistoryStore: WalletsBalanceHistoryStore;
      readonly navigationContainer: NavigationContainer;
      readonly walletTransactionsRequestHelper: WalletTransactions.RequestHelper;
      readonly lastTransactionStore: LastTransactionStore;
      readonly flashMessage: FlashMessage;
      readonly translation: LegacyLocalization;
      readonly jsonKeyValueStore: JsonKeyValueStore<JsonKeyValueMap>;
    },
  ) {
    makeObservable(this);
  }

  @computed
  private get _baseCrypto(): CryptoCurrencyCode | undefined {
    const account = getAccountFromState(this._root.accountStore.state);
    return account && account.base_crypto;
  }

  @computed
  private get _baseFiat(): FiatCurrencyCode | undefined {
    const account = getAccountFromState(this._root.accountStore.state);
    return account && account.base_fiat;
  }

  getTotal = () => this._walletSlideData;
  getWallets = () => {
    return this._walletsFilterOption === 'unset' ||
      this._walletsFilterOption === 'all'
      ? this._walletItems
      : this._walletItems?.filter(it => it.cryptoValue !== '0');
  };
  getLastTransaction = () => this._lastTransaction;

  getIsLoading = () => this._isLoading;

  private _setLoading(loading: boolean) {
    runInAction(() => (this._isLoading = loading));
  }

  getIsManualRefresh = () => this._isManualRefresh;

  async manualRefresh(options?: BaseAsyncOptions) {
    if (!this._baseCrypto || !this._baseFiat) {
      return;
    }

    runInAction(() => {
      this._isManualRefresh = true;
    });

    await this.refresh(options);

    runInAction(() => {
      this._isManualRefresh = false;
    });
  }

  refresh = async (options?: BaseAsyncOptions) => {
    const baseCrypto = this._baseCrypto;
    const baseFiat = this._baseFiat;
    if (!baseCrypto || !baseFiat) {
      return;
    }
    this._setLoading(true);
    try {
      const persistedFilterOption =
        await this._root.jsonKeyValueStore.get('filterOption');
      const persistedFilterOptionUnwrapped = unwrap(persistedFilterOption);
      this.walletsFilterOption = persistedFilterOptionUnwrapped
        ? persistedFilterOptionUnwrapped
        : 'all';
      await executeWithPostpone(
        postpone =>
          this._refreshAll(baseCrypto, baseFiat, {
            ...options,
            postpone,
          }),
        options,
      );
    } finally {
      this._setLoading(false);
    }
  };

  hideWallet = async (id: WalletId) => {
    const wallet = this._root.walletStore.getWalletById(id);
    if (!wallet) {
      return;
    }

    await this._root.ncWalletJsonRpcClient.call('wallets.update', {
      wallet_id: id,
      is_visible: false,
    });

    const currency = this._root.currencyStore.getCryptoCurrency(
      wallet.currency,
    );
    this._root.flashMessage.showMessage({
      variant: 'success',
      title: this._root.translation.templates['Wallet.hidden.text']({
        currency: currency?.name,
      }),
    });

    await this.refresh();
  };

  activateBackgroundRefresh() {
    this._root.lastTransactionStore.activateTransactionsUpdate();
    this._backgroundRefreshDisposer = batchDisposers(
      this._root.walletStore.events.listen(WALLET_CREATE, () => this.refresh()),
      this._root.walletStore.events.listen(WALLET_BALANCE_UPDATE, () =>
        this.refresh(),
      ),
      this._root.walletStore.events.listen(WALLET_VISIBILITY_CHANGE, w => {
        if (w.is_visible) {
          return this.refresh();
        }
      }),
    );
  }

  deactivateBackgroundRefresh() {
    this._root.lastTransactionStore.deactivateTransactionsUpdate();
    if (this._backgroundRefreshDisposer) {
      this._backgroundRefreshDisposer();
    }
  }

  private _fiatDescriptionGetter =
    (code: FiatCurrencyCode | undefined) => () => {
      const currency = code && this._root.currencyStore.getFiatCurrency(code);
      return currency && toCurrencyDescriptionFromFiat(currency);
    };

  private _cryptoDescriptionGetter =
    (code: CryptoCurrencyCode | undefined) => () => {
      const currency = code && this._root.currencyStore.getCryptoCurrency(code);
      return currency && toCurrencyDescriptionFromCrypto(currency);
    };

  private _toFiatRateGetter =
    (crypto: CryptoCurrencyCode | undefined) => () => {
      const toFiat =
        crypto &&
        this._baseFiat &&
        this._root.currenciesRateStore.getRate(crypto, this._baseFiat);
      return toFiat?.rate;
    };

  private _fiatValueGetter =
    <T extends CryptoCurrencyCode>(
      value: CurrencyValue<T>,
      crypto: T | undefined,
    ) =>
    () => {
      const rate = this._toFiatRateGetter(crypto)();
      return rate && MoneyStatic.convert(value, rate);
    };

  private _rateHistoryGetter =
    (crypto: CryptoCurrencyCode | undefined) => () => {
      if (!crypto || !this._baseFiat) {
        return;
      }
      const rateHistoryRes =
        this._root.currenciesRateHistoryStore.getLatestHistoryRes(
          crypto,
          this._baseFiat,
          RateHistoryPeriod.Month,
        );

      const rateHistory =
        rateHistoryRes && getHistoryFromResponse(rateHistoryRes);

      const lastRate = this._root.currenciesRateStore.getRate(
        crypto,
        this._baseFiat,
      );

      if (!rateHistory || !lastRate) {
        return undefined;
      }

      return patchRateHistoryWithLatestRate(
        rateHistory,
        lastRate,
        RateHistoryPeriod.Month,
      );
    };

  private _getTotalInBaseCryptoValue = () => {
    const baseCrypto = this._baseCrypto;
    if (!this._isAllLoaded || !baseCrypto) {
      return undefined;
    }

    return reduce(
      this._wallets,
      (acc, w) => {
        const cryptoRate = this._root.currenciesRateStore.getRate(
          w.currency,
          baseCrypto,
        );
        if (!cryptoRate) {
          return acc;
        }
        return acc.plus(MoneyStatic.convert(w.total, cryptoRate.rate));
      },
      BigNumber(0),
    ).toString();
  };

  private _getTotalInBaseFiatValue = () => {
    if (!this._isAllLoaded) {
      return undefined;
    }

    return reduce(
      this._walletItems,
      (acc, _) => acc.plus(_.getFiatValue() ?? '0'),
      BigNumber(0),
    ).toString();
  };

  private walletToCurrencyListItemData = (w: Wallet): WalletListItemData => ({
    id: w.id,
    cryptoValue: w.total,
    cryptoCurrencyCode: w.currency,
    is_visible: w.is_visible,
    getCryptoRate: this._toFiatRateGetter(w.currency),
    getFiatValue: this._fiatValueGetter(w.total, w.currency),
    getCryptoCurrency: this._cryptoDescriptionGetter(w.currency),
    getFiatCurrency: this._fiatDescriptionGetter(this._baseFiat),
    getRateHistory: this._rateHistoryGetter(w.currency),
  });

  private async _refreshAll(
    baseCryptoCode: CryptoCurrencyCode,
    baseFiatCode: FiatCurrencyCode,
    options?: BaseAsyncOptions & BaseTransactionOptions,
  ) {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }
    const responses = await Promise.all([
      this._root.currencyStore.refreshCryptoCurrencies(options),
      this._root.currencyStore.refreshFiatCurrencies(options),
      this._root.walletsBalanceHistoryStore.refreshHistory(options),
    ]);

    this._isTimeoutError = responses.some(
      it => !it.success && it.left.kind === TIMEOUT_ERROR,
    );

    const wallets_ = await this._root.walletStore.refreshWallets(options);
    this._isTimeoutError =
      !wallets_.success && wallets_.left.kind === TIMEOUT_ERROR;

    if (!wallets_.success) {
      return;
    }

    const wallets = wallets_.right;
    const walletIds = new Set(wallets.map(_ => _.id));
    const codes = new Set(wallets.map(_ => _.currency));
    const codesArray = [...codes];

    await Promise.all([
      this._root.lastTransactionStore.refreshLastTransaction(
        walletIds,
        options,
      ),
      this._root.currenciesRateStore.batchRefreshRates(
        [
          ...codesArray.map(c => ({from: c, to: baseCryptoCode})),
          ...codesArray.map(c => ({from: c, to: baseFiatCode})),
        ],
        options,
      ),
      this._root.currenciesRateHistoryStore.refreshLatestHistoryBatched(
        codes,
        baseFiatCode,
        RateHistoryPeriod.Month,
        options,
      ),
    ]);
  }

  private _createCancellationError(cause?: unknown) {
    return this._root.errorRepository.create<CancellationError>({
      kind: CANCELLATION_ERROR,
      raw: cause,
    });
  }
}
