import {action, makeObservable, observable, runInAction} from 'mobx';

import type {BaseAsyncOptions} from '../../Async';
import type {AccountIdStore} from '../../Auth';
import type {
  CancellationError,
  GeneralJsonRpcError,
  GlobalError,
} from '../../Error';
import {CANCELLATION_ERROR, TIMEOUT_ERROR} from '../../Error';
import type {ErrorRepository} from '../../ErrorRepository';
import type {Either} from '../../fp';
import {error} from '../../fp';
import type {JsonRpcClient} from '../../JsonRpc';
import type {
  CommonError,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
} from '../../NCWalletServer';
import type {WalletsBalanceHistoryResult} from '../../NCWalletServer/WalletsBalanceHistory';
import type {SentryLog} from '../../SentryLog';
import {createJsonErrorMessage} from '../../SentryLog';
import type {BaseTransactionOptions} from '../../util';
import type {WalletsBalanceHistoryStore} from './WalletsBalanceHistoryStore';

export default class WalletsBalanceHistoryStoreImpl
  implements WalletsBalanceHistoryStore
{
  @observable.ref private _history: WalletsBalanceHistoryResult | undefined;

  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly accountIdStore: AccountIdStore;
      readonly sentryLog: SentryLog;
    },
  ) {
    makeObservable(this);
  }

  getHistory(): WalletsBalanceHistoryResult | undefined {
    return this._history;
  }

  async refreshHistory(
    options?: BaseAsyncOptions & BaseTransactionOptions,
  ): Promise<
    Either<
      WalletsBalanceHistoryResult,
      GlobalError | GeneralJsonRpcError<CommonError>
    >
  > {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }

    const res = await this._root.ncWalletJsonRpcClient.call(
      'wallets.balance.history',
      {},
      options,
    );

    if (!res.success) {
      if (res.left.kind === TIMEOUT_ERROR) {
        this._root.sentryLog.write(
          createJsonErrorMessage('[wallets.balance.history]', options),
        );
      }
    }

    if (res.success) {
      const _runInAction = options?.postpone ?? runInAction;
      _runInAction(() => {
        this._history = res.right;
      });
    }

    return res;
  }

  private _createCancellationError(cause?: unknown) {
    return this._root.errorRepository.create<CancellationError>({
      kind: CANCELLATION_ERROR,
      raw: cause,
    });
  }

  @action.bound
  reset() {
    this._history = undefined;
  }
}
