import {type FilterOption, useStrings} from '@ncwallet-app/core';
import {BottomSheet} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import ListWalletOptions from './ListWalletOptions';

export type ListWalletOptionsScreenProps = {
  selectedFilterOption: FilterOption;
  onFilterPress: (filterOption: FilterOption) => void;
  onClose: () => void;
};

export default observer(function ListWalletOptionsScreen(
  props: ListWalletOptionsScreenProps,
) {
  const {selectedFilterOption, onFilterPress, onClose} = props;
  const {bottom} = useSafeAreaInsets();
  const strings = useStrings();

  const handleChange = useCallback(
    (idx: number) => {
      if (idx < 0) {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <BottomSheet
      index={0}
      enableAutoHeight
      enableDynamicSizing
      title={strings['coinFilter.Title']}
      animateOnMount
      enablePanDownToClose
      onChange={handleChange}
      onClose={onClose}>
      <View style={{paddingBottom: bottom}}>
        <ListWalletOptions
          onFilterOptionPress={onFilterPress}
          selectedFilterOption={selectedFilterOption}
        />
      </View>
    </BottomSheet>
  );
});
