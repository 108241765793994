import type {InOutCurrency} from '@ncwallet-app/core';
import {useBoolean, useStrings, variance} from '@ncwallet-app/core';
import type {AddressType} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {Button, ButtonVariant, LG_BREAKPOINT} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

import type {BuyLinkBindingState} from '../../Navigation/HomeStack/BuyLinkBinding/BuyLinkBindingState';
import {ErrorTitle} from '../SendScreen/SharedComponents';
import BuyAmountInput from './BuyAmountInput';
import FinalBuyMessage from './FinalBuyMessage/FinalBuyMessage';
import ReceiveAddressType from './ReceiveAddressType';
import {useReceiveScreenActions} from './useReceiveScreenActions';
import {WalletInfo} from './WalletInfo';

export type BuyTabViewProps = {
  walletHeader: React.ReactNode;
  amount: BuyLinkBindingState['amount'];
  currencyCode: BuyLinkBindingState['currencyCode'];
  setAmount: BuyLinkBindingState['setAmount'];
  onSubmit: BuyLinkBindingState['onSubmit'];
  errorText?: BuyLinkBindingState['errorText'];
  disabled?: BuyLinkBindingState['disabled'];
  addressName?: string;
  addressNameShown: boolean;
  networks?: InOutCurrency[] | undefined;
  contractType: string | null | undefined;
  shareShown: boolean;
  addressType?: AddressType;
  walletAddress: string;
  onShare: (data: string) => void;
  onAddresses: () => void;
  onAddressTypePress: () => void;
  goToRoot: () => void;
};

export default observer(function BuyTabView(props: BuyTabViewProps) {
  const {
    walletHeader,
    amount,
    addressName,
    networks,
    addressNameShown,
    contractType,
    shareShown,
    addressType,
    walletAddress,
    onShare,
    onAddresses,
    onAddressTypePress,
    currencyCode,
    errorText,
    setAmount,
    onSubmit,
    disabled,
    goToRoot,
  } = props;
  const strings = useStrings();

  const {copyAddressThrottled, shareThrottled} = useReceiveScreenActions(
    onShare,
    walletAddress,
  );

  const [isFinalModalShown, showFinalModalShown, hideFinalModalShown] =
    useBoolean(false);

  const handleBuySubmit = useCallback(async () => {
    const isSubmitSuccessful = await onSubmit();
    if (isSubmitSuccessful) {
      showFinalModalShown();
    }
  }, [onSubmit, showFinalModalShown]);

  const handleConfirmModal = () => {
    hideFinalModalShown();
    goToRoot();
  };

  return (
    <Root contentContainerStyle={styles.container}>
      <BuyTabHeader>
        {walletHeader}
        <ReceiveAddressType
          addressName={addressName}
          onAddressTypePress={onAddressTypePress}
          addressNameShown={addressNameShown}
          networks={networks}
          contractType={contractType}
        />
      </BuyTabHeader>
      <BuyTabContent>
        <WalletInfo
          goToAddresses={onAddresses}
          shareShown={shareShown}
          addressType={addressType}
          label={strings['receiveCryptoScreen.walletAddressLabel']}
          info={walletAddress}
          onCopy={copyAddressThrottled}
          onShare={shareThrottled}
        />
        <BuyAmountInput
          wideStyle
          amount={amount}
          onChangeAmount={setAmount}
          currency={currencyCode}
        />
      </BuyTabContent>

      <BottomContainer>
        <ErrorText withoutOffset>{errorText ?? ''}</ErrorText>
        <SubmitButton
          title={strings['receiveCryptoScreen.fiat.button']}
          variant={ButtonVariant.Primary}
          onPress={handleBuySubmit}
          disabled={disabled}
        />
      </BottomContainer>
      {isFinalModalShown && (
        <FinalBuyMessage
          onConfirm={handleConfirmModal}
          onCancel={hideFinalModalShown}
        />
      )}
    </Root>
  );
});

const styles = StyleSheet.create({
  container: {
    gap: 20,
  },
});

const Root = variance(ScrollView)(() => ({
  root: {
    flex: 1,
    gap: 20,
  },
}));

const BuyTabHeader = variance(View)(theme => ({
  root: {
    gap: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
      },
    }),
  },
}));

const BuyTabContent = variance(View)(theme => ({
  root: {
    flex: 1,
    gap: 20,
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
      },
    }),
  },
}));

const BottomContainer = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
  },
}));

const ErrorText = variance(ErrorTitle)(() => ({
  root: {
    marginBottom: 40,
  },
}));

const SubmitButton = variance(Button)(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 345,
      },
    }),
  },
}));
