import type {WalletSlideData} from '@ncwallet-app/core';
import {useStrings, variance} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import type {ReactElement} from 'react';
import React from 'react';

import {useIsDimensions} from '../../../util';
import {SelectionCryptoTitle, Separator} from '../../atoms';
import type {BaseSafeAreaProps} from '../SafeAreaLayout';
import type {CurrencySelectionListItemData} from './CurrencySelectionListItemData';
import MdCurrencySelectionList from './MdCurrencySelectionList';
import {SmCurrencySelectionList} from './SmCurrencySelectionList';

export type SelectionForExchangeBodyProps = BaseSafeAreaProps & {
  showValueAsRate?: boolean;
  onCurrencyPress: (currency: CurrencySelectionListItemData) => void;
  onAllPress?: () => void;
  totalShownCurrencies?: WalletSlideData | undefined;
  currencies: CurrencySelectionListItemData[] | undefined;
  refreshControl: ReactElement;
  ListFooter?: React.ReactElement;
  title?: string;
};

export default observer(function CurrencySelectionList(
  props: SelectionForExchangeBodyProps,
) {
  const isLg = useIsDimensions('lg');
  const strings = useStrings();

  return (
    <>
      {isLg ? (
        <>
          <MdCurrencySelectionList
            data={props.currencies}
            onAllPress={props.onAllPress}
            totalShownCurrencies={props.totalShownCurrencies}
            onCurrencyPress={props.onCurrencyPress}
            showValueAsRate={props.showValueAsRate}
          />
          {props.ListFooter}
        </>
      ) : (
        <>
          {props.title !== '' && (
            <SelectionCryptoTitle
              title={props.title || strings['currencySelection.useCoins']}
            />
          )}
          <SmCurrencySelectionList
            data={props.currencies}
            CustomSeparator={CustomSeparator}
            showValueAsRate={props.showValueAsRate}
            onAllPress={props.onAllPress}
            totalShownCurrencies={props.totalShownCurrencies}
            ListFooterComponent={props.ListFooter}
            {...props}
          />
        </>
      )}
    </>
  );
});

const CustomSeparator = variance(Separator)(() => ({
  root: {
    marginRight: 0,
    marginLeft: 0,
  },
}));
