import {sized, useBoolean, useStyles, useTheme} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import type {ForwardedRef} from 'react';
import React, {useCallback, useMemo} from 'react';
import type {
  NativeSyntheticEvent,
  StyleProp,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  ViewStyle,
} from 'react-native';
import {Platform, TextInput as RNTextInput, Text, View} from 'react-native';
import type {SvgProps} from 'react-native-svg';

import {PhoneSvg} from '../../assets/svg/colorless';

export enum InputType {
  Default,
  Phone,
  Email,
  Password,
  Numeric,
}

export enum InputVariant {
  Default,
  Error,
  Info,
  Success,
}

export enum IconPosition {
  Left,
  Right,
}

export type InputProps = TextInputProps & {
  variant?: InputVariant;
  type?: InputType;
  iconPosition?: IconPosition;
  iconBackgroundShown?: boolean;
  Icon?: React.ComponentType<SvgProps>;
  label?: string | React.ReactElement<View>;
  LabelComponent?: () => React.ReactElement;
  message?: string;
  containerStyle?: StyleProp<ViewStyle>;
  innerContainerStyle?: StyleProp<ViewStyle>;
  Slot?: React.ComponentType;
  disabled?: boolean;
};

export const Input = observer(
  React.forwardRef((props: InputProps, ref: ForwardedRef<TextInput>) => {
    const {
      variant = InputVariant.Default,
      iconPosition = IconPosition.Left,
      onFocus,
      onBlur,
      containerStyle,
      style,
      Slot,
      disabled,
      ...rest
    } = props;
    const [focused, focus, blur] = useBoolean(false);
    const handleFocus = useCallback(
      (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        focus();
        onFocus?.(e);
      },
      [focus, onFocus],
    );
    const handleBlur = useCallback(
      (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        blur();
        onBlur?.(e);
      },
      [blur, onBlur],
    );
    const styles = useStyles(theme => ({
      root: {
        borderWidth: 1,
        borderColor: theme.palette.uiSecondary,
        borderRadius: 6,
        backgroundColor: theme.palette.background,
        flexDirection: 'row',
        overflow: 'hidden',
        height: 50,
        ...Platform.select({
          web: {
            outline: 'none',
          },
          default: {},
        }),
      },
      input: {
        width: '100%',
        ...theme.fontByWeight('400'),
        flex: 1,
        paddingHorizontal: 10,
        paddingVertical: 10,
        fontSize: 15,
        lineHeight: 17,
        color: theme.palette.textMain,
        ...Platform.select({
          web: {
            outline: 'none',
          },
          default: {},
        }),
      },
      focused: {
        borderColor: theme.palette.info,
      },
      disabled: {
        opacity: 0.5,
      },
      [InputVariant.Default]: {},
      [InputVariant.Error]: {
        borderColor: theme.palette.error,
      },
      [InputVariant.Info]: {
        borderColor: theme.palette.info,
      },
      [InputVariant.Success]: {
        borderColor: theme.palette.success,
      },
      message: {
        color: theme.palette.textMain,
      },
    }));
    const keyboardProps = useMemo(() => getKeyboardProps(props), [props]);
    const theme = useTheme();

    const _renderTextInput = useCallback(
      () => (
        <RNTextInput
          ref={ref}
          placeholderTextColor={theme.palette.textAdditional3}
          style={[styles.input, style]}
          caretHidden={false} // Need for Readmi devices
          {...keyboardProps}
          editable={!disabled}
          {...rest}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      ),
      [
        ref,
        handleBlur,
        handleFocus,
        keyboardProps,
        rest,
        disabled,
        style,
        styles.input,
        theme.palette.textAdditional3,
      ],
    );

    const _renderLabel = useCallback(() => {
      return <Label {...props} />;
    }, [props]);

    const _renderInput = useCallback(() => {
      return (
        <View
          style={[
            styles.root,
            !disabled && focused && styles.focused,
            disabled && styles.disabled,
            styles[variant],
            props.innerContainerStyle,
          ]}>
          {iconPosition === IconPosition.Left && (
            <InputIcon {...props} focused={focused} />
          )}
          {_renderTextInput()}
          {Slot && <Slot />}
        </View>
      );
    }, [
      _renderTextInput,
      focused,
      disabled,
      props,
      styles,
      variant,
      iconPosition,
      Slot,
    ]);

    const _renderMessage = useCallback(() => {
      return <Message {...props} />;
    }, [props]);

    return (
      <View style={containerStyle}>
        {_renderLabel()}
        {_renderInput()}
        {_renderMessage()}
      </View>
    );
  }),
);

export type LabelProps = InputProps;

const Label = observer(({label, LabelComponent}: LabelProps) => {
  const styles = useStyles(theme => ({
    root: {
      minHeight: 16,
      ...theme.fontByWeight('700'),
      color: theme.palette.textMain,
      fontSize: 14,
      lineHeight: 16,
      marginBottom: 10,
    },
    [InputVariant.Default]: {},
  }));
  if (LabelComponent) {
    return <LabelComponent />;
  }
  if (label === undefined) {
    return null;
  }
  return <Text style={styles.root}>{label}</Text>;
});

export type MessageProps = InputProps;

const Message = observer(
  ({variant = InputVariant.Default, message}: MessageProps) => {
    const styles = useStyles(theme => ({
      root: {
        ...theme.fontByWeight('500'),
        color: theme.palette.textSecondary,
        fontSize: 12,
        lineHeight: 18,
        marginTop: 12,
      },
      [InputVariant.Default]: {},
      [InputVariant.Error]: {
        color: theme.palette.error,
      },
      [InputVariant.Info]: {
        color: theme.palette.info,
      },
      [InputVariant.Success]: {
        color: theme.palette.success,
      },
    }));
    if (!message) {
      return null;
    }
    return <Text style={[styles.root, styles[variant]]}>{message}</Text>;
  },
);

export type InputIconProps = InputProps & {focused: boolean};

const InputIcon = observer(
  ({
    type = InputType.Default,
    Icon,
    iconPosition = IconPosition.Left,
    iconBackgroundShown = true,
    focused,
  }: InputIconProps) => {
    const theme = useTheme();
    const styles = useStyles(() => ({
      root: {
        backgroundColor: iconBackgroundShown
          ? theme.palette.uiPrimary
          : 'transparent',
        maxWidth: 50,
        padding: 15,
        alignItems: 'center',
        justifyContent: 'center',
      },
      focused: {
        borderRightColor: theme.palette.info,
      },
      [IconPosition.Left]: {
        borderRightWidth: iconBackgroundShown ? 1 : 0,
        borderRightColor: theme.palette.uiSecondary,
        paddingRight: iconBackgroundShown ? 15 : 0,
      },
      [IconPosition.Right]: {
        borderLeftWidth: iconBackgroundShown ? 1 : 0,
        borderLeftColor: theme.palette.uiSecondary,
        paddingLeft: iconBackgroundShown ? 15 : 0,
      },
    }));
    const style = [
      styles.root,
      styles[iconPosition],
      focused && styles.focused,
    ];
    if (Icon) {
      return (
        <View style={style}>
          <Icon />
        </View>
      );
    }
    if (type === InputType.Phone) {
      return (
        <View style={style}>
          <PhoneIcon color={theme.palette.foreground} />
        </View>
      );
    }
    return null;
  },
);

const PhoneIcon = sized(PhoneSvg, 16);

const getKeyboardProps = (props: InputProps): TextInputProps => {
  const {type} = props;
  switch (type) {
    case InputType.Phone:
      return {
        keyboardType: 'phone-pad',
        textContentType: 'telephoneNumber',
      };
    case InputType.Password:
      return {
        textContentType: 'password',
        autoComplete: 'password',
        secureTextEntry: true,
      };
    case InputType.Email:
      return {
        autoCapitalize: 'none',
        keyboardType: 'email-address',
        autoComplete: 'email',
        textContentType: 'emailAddress',
      };
    case InputType.Numeric:
      return {
        inputMode: 'decimal',
        keyboardType: 'numeric',
      };
  }
  return {};
};
