import {useRoot} from '@ncwallet-app/core';
import type {
  PromptFeedbackRoute,
  RouteTransition,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {
  APP_STORE_LINK,
  GOOGLE_PLAY_LINK,
  openLink,
  Target,
} from '@ncwallet-app/core/src/Links';
import {computed} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo, useState} from 'react';

import {RateModalScreen} from '../../../screens/RateModalScreen';
import type {RateModalScreenProps} from '../../../screens/RateModalScreen/RateModalScreen';
import getStoresToDisplay from './getStoresToDisplay';
import RateModalBindingStateImpl from './RateModalBindingStateImpl';
import usePromptFeedback from './usePromptFeedback';
import useSendRatingEvent from './useSendRatingEvent';
import useStoreRedirection from './useStoreRedirection';

export type PromptRateContainerProps = {
  goBack: () => void;
  promptFeedback: RouteTransition<PromptFeedbackRoute>;
  getIsFocused: () => boolean;
};

export default observer(function PromptRateContainer(
  props: PromptRateContainerProps,
) {
  const {promptFeedback, goBack, getIsFocused} = props;
  const {location} = useRoot();

  const [state] = useState(() => new RateModalBindingStateImpl());

  const modalProps = useMemo<Partial<RateModalScreenProps>>(() => {
    const storesToDisplay = computed(() => getStoresToDisplay(state));
    return {
      getIsAppStoreButtonDisplayed: () =>
        computed(() => storesToDisplay.get()?.has('AppStore')).get(),
      getIsGooglePlayButtonDisplayed: () =>
        computed(() => storesToDisplay.get()?.has('GooglePlay')).get(),
    };
  }, [state]);

  const [getDeadline, cancelTimeout] = useStoreRedirection(
    getIsFocused,
    goBack,
    state,
    location,
  );

  const onGoToAppStore = useCallback(async () => {
    await openLink(APP_STORE_LINK, Target.Blank);
    goBack();
    cancelTimeout();
  }, [cancelTimeout, goBack]);

  const onGoToGooglePlay = useCallback(async () => {
    await openLink(GOOGLE_PLAY_LINK, Target.Blank);
    goBack();
    cancelTimeout();
  }, [cancelTimeout, goBack]);

  usePromptFeedback(getIsFocused, promptFeedback, state);

  useSendRatingEvent(state);

  return (
    <RateModalScreen
      getRating={state.getRating}
      onChange={state.changeRating}
      onClose={goBack}
      getDeadline={getDeadline}
      onGoToAppStore={onGoToAppStore}
      onGoToGooglePlay={onGoToGooglePlay}
      {...modalProps}
    />
  );
});
