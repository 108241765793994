import {
  isEnoughMoneyForSendOrExchange,
  RateHistoryPeriod,
  useRoot,
  useStrings,
} from '@ncwallet-app/core';
import type {ExchangeReceipt} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {DepositType} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {getDefaultAddressParams} from '@ncwallet-app/core/src/NCWalletServer/InOutCurrency';
import type {SmallBottomTabParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallBottomTab/SmallBottomTabParamList';
import type {SmallHomeStackParamList} from '@ncwallet-app/core/src/SmallNavigationScheme/SmallHomeStack/SmallHomeStackParamList';
import {CommonActions} from '@react-navigation/native';
import type {
  NavigationState,
  PartialState,
} from '@react-navigation/routers/src/types';
import {setStringAsync} from 'expo-clipboard';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useRedirectToNotFoundIfNoWalletWithId} from '../../../CommonNavigationContainers/hooks/redirectToNotFoundOnWrongDataHooks';
import {useNavigatePurgingDuplicates} from '../../../CommonNavigationScheme';
import {useCurrencyBindingState} from '../../../Navigation/HomeStack/CurrencyBinding/useCurrencyBindingState';
import {CurrencyScreen} from '../../../screens/CurrencyScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ShowWalletBindingProps = SmallHomeStackBindingProps<'ShowWallet'>;

export default observer(function ShowWalletBinding(
  props: ShowWalletBindingProps,
) {
  const {navigation} = props;
  const {walletId, period = RateHistoryPeriod.Month} = props.route.params;
  const state = useCurrencyBindingState(walletId, period);
  const {flashMessage, walletStore} = useRoot();
  const strings = useStrings();
  const navigatePurgingDuplicates = useNavigatePurgingDuplicates(navigation);
  const addressInfo = state.addressInfo;
  useRedirectToNotFoundIfNoWalletWithId(walletId);

  const navigateToReceive = useCallback(async () => {
    if (!addressInfo) {
      return null;
    }
    navigation.navigate('ShowQrToReceiveCrypto', {
      walletId,
      address: addressInfo.address,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...getDefaultAddressParams(state.getDefaultInCurrency()!),
    });
  }, [state, addressInfo, navigation, walletId]);

  const navigateToBuy = useCallback(async () => {
    if (!addressInfo) {
      return null;
    }
    const defaultCurrency = state.getDefaultBuyCurrency();
    const defaultAddressInfo = state.getDefaultBuyAddressInfo();
    if (defaultCurrency && defaultAddressInfo) {
      navigation.navigate('ShowQrToReceiveCrypto', {
        walletId,
        depositType: DepositType.Buy,
        address: defaultAddressInfo.address,
        ...getDefaultAddressParams(defaultCurrency),
      });
    }
  }, [addressInfo, state, navigation, walletId]);

  const handleCopy = useCallback(() => {
    void setStringAsync(addressInfo?.address || '');
    flashMessage.showMessage({
      title: strings.copied,
      variant: 'success',
    });
  }, [addressInfo?.address, flashMessage, strings]);

  const navigateToSend = useCallback(() => {
    navigatePurgingDuplicates('PromptOutputAddress', {
      walletId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...getDefaultAddressParams(state.getDefaultOutCurrency()!),
    });
  }, [navigatePurgingDuplicates, state, walletId]);

  const navigateToHistory = useCallback(() => {
    const wallet = state.wallet;
    if (!wallet) {
      return;
    }
    const bottomTabState: PartialState<
      NavigationState<SmallBottomTabParamList>
    > = {
      index: 0,
      routes: [{name: 'ListHistory', params: {walletId: wallet.id}}],
    };
    const rootState: PartialState<NavigationState<SmallHomeStackParamList>> = {
      index: 0,
      routes: [{name: 'Root', state: bottomTabState}],
    };
    navigation.dispatch(CommonActions.reset(rootState));
  }, [state, navigation]);

  const navigateToExchange = useCallback(() => {
    const params: ExchangeReceipt = {
      walletIdFrom: walletId,
    };
    if (state.wallet && !isEnoughMoneyForSendOrExchange(state.wallet)) {
      const nextWallet = walletStore
        .getWallets()
        ?.find(it => it.id !== walletId);
      params.walletIdTo = nextWallet?.id;
    }
    navigatePurgingDuplicates('PromptExchangeReceipt', params);
  }, [navigatePurgingDuplicates, state.wallet, walletId, walletStore]);

  const handleGraphPeriodChange = useCallback(
    (p: RateHistoryPeriod) => {
      navigation.setParams({period: p});
    },
    [navigation],
  );

  return (
    <CurrencyScreen
      address={addressInfo?.address}
      onCopy={handleCopy}
      walletName={strings['currencyScreen.walletTitle']}
      cryptoCurrency={state.cryptoCurrency}
      cryptoValue={state.cryptoValue}
      fiatRate={state.baseFiatRate}
      fiatCurrency={state.baseFiatCurrency}
      fiatValue={state.baseFiatValue}
      receiveEnabled={state.receiveEnabled()}
      exchangeEnabled={state.exchangeEnabled()}
      sendEnabled={state.sendEnabled()}
      buyEnabled={state.buyEnabled()}
      rateHistory={state.rateHistory}
      isGraphLoading={state.isGraphLoading}
      onBuyPress={navigateToBuy}
      activePeriod={state.period ?? RateHistoryPeriod.Month}
      onReceivePress={navigateToReceive}
      onSendPress={navigateToSend}
      onExchangePress={navigateToExchange}
      onHistoryPress={navigateToHistory}
      onGraphPeriodChange={handleGraphPeriodChange}
    />
  );
});
