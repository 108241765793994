import {type Url, useRoot, useStyles} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
// eslint-disable-next-line import-x/no-extraneous-dependencies
import type {
  OverriddenProps,
  PartialStyleSheetRecord,
} from 'react-native-awesome-markdown';
// eslint-disable-next-line import-x/no-extraneous-dependencies
import MarkdownComponent, {
  mergeNamedStyles,
} from 'react-native-awesome-markdown';

export type MarkdownProps = {
  children: string;
  styles?: PartialStyleSheetRecord;
  rules?: OverriddenProps['rules'];
};

export default observer(function Markdown({
  children,
  styles,
  rules,
}: MarkdownProps) {
  const {location} = useRoot();

  const openLink = useCallback(
    (url: string) => location.open(url as Url),
    [location],
  );

  const themedStyles = useStyles(theme => ({
    link: {
      ...theme.fontByWeight('700'),
      color: theme.palette.info,
      fontStyle: 'normal',
    },
    paragraph: {
      ...theme.fontByWeight('400'),
    },
  }));

  const _styles = useMemo(
    () => mergeNamedStyles<PartialStyleSheetRecord>(themedStyles, styles),
    [styles, themedStyles],
  );

  return (
    <MarkdownComponent
      text={children}
      onLinkPress={openLink}
      overridden={{
        globalFontSize: 14,
        styles: _styles,
        rules,
      }}
    />
  );
});
