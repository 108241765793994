import type {
  BlockedIpsListRoute,
  CheckBiometricsPinRoute,
  CheckPinRoute,
  DebugRoute,
  FallBackRoute,
  GeneratePayMeLinkRoute,
  IdentityVerificationUploadDocumentRoute,
  ListActiveSessionsRoute,
  ListAllowedIpsRoute,
  ListAuthAppsRoute,
  ListDocumentsRoute,
  ListEnvironmentsRoute,
  ListHistoryFiltersRoute,
  ListHistoryRoute,
  ListInputAddressesRoute,
  ListLimitsRoute,
  ListLogsRoute,
  ListMenuCategoriesRoute,
  ListNotificationSettingsRoute,
  ListNotificationsRoute,
  ListPersonalDataRoute,
  ListQrCodeHistoryRoute,
  ListSecuritySettingsRoute,
  ListSessionHistoryRoute,
  ListSignOutReasonLogsRoute,
  ListWalletsRoute,
  MergeShallowObjects as MSO,
  NotifyAboutSuccessfulExchangeRoute,
  NotifyAboutSuccessfulPinChangeRoute,
  NotifyAboutSuccessfulSendingRoute,
  PromptAddIpRoute,
  PromptAddressFormatRoute,
  PromptAffiliateProgramRoute,
  PromptBaseCryptoRoute,
  PromptBaseFiatRoute,
  PromptBiometricSettingsRoute,
  PromptBuyCryptoRoute,
  PromptCommissionForSendingRoute,
  PromptConfirmationForExchangeRoute,
  PromptConfirmationToSendCryptoRoute,
  PromptCryptoToExchangeRoute,
  PromptCryptoToReceiveRoute,
  PromptCryptoToSendRoute,
  PromptEmailRoute,
  PromptEnvironmentFormRoute,
  PromptExchangeReceiptRoute,
  PromptIdentityVerificationAddressRoute,
  PromptInputNetworkRoute,
  PromptLanguageRoute,
  PromptLimitRemovalRoute,
  PromptLimitUpdateRoute,
  PromptNameAndBirthdateRoute,
  PromptNewLimitRoute,
  PromptNewPinRoute,
  PromptNewWalletRoute,
  PromptNicknameRoute,
  PromptOtpToAddIpRoute,
  PromptOtpToBiometricsRoute,
  PromptOtpToChangeTwoFaSettingsRoute,
  PromptOtpToClearAllowedListRoute,
  PromptOtpToDeleteIpRoute,
  PromptOtpToDisableTwoFaRoute,
  PromptOtpToEnableTwoFaRoute,
  PromptOtpToExchangeRoute,
  PromptOtpToLimitCreateRoute,
  PromptOtpToLimitRemovalRoute,
  PromptOtpToLimitUpdateRoute,
  PromptOtpToSendCryptoRoute,
  PromptOtpToUpdateIpRoute,
  PromptOutputAddressRoute,
  PromptOutputNetworkRoute,
  PromptPeriodToFilterHistoryRoute,
  PromptReceiveNetworkRoute,
  PromptResidentialAddressRoute,
  PromptSelectTwoFaProviderRoute,
  PromptSessionDeletionRoute,
  PromptSourceCryptoToExchangeRoute,
  PromptThemeRoute,
  PromptToClearAllowedListRoute,
  PromptToDeleteDocumentFileRoute,
  PromptToDeleteIpRoute,
  PromptTransactionKindToFilterHistoryRoute,
  PromptUpdateIpRoute,
  PromptUserIdRoute,
  PromptWalletGroupToFilterHistoryRoute,
  PromptWalletToFilterHistoryRoute,
  RedirectToSendRoute,
  RouteBase,
  RouteParams,
  ShowLimitRoute,
  ShowProfileRoute,
  ShowQrToReceiveCryptoRoute,
  ShowTransactionRoute,
  ShowTwoFaGeneratedSecretRoute,
  ShowTwoFASettingsRoute,
  ShowWalletRoute,
  TwoFaWelcomeRoute,
} from '../../../CommonNavigationScheme';

export type LargeSwitchParamList = {
  ListWallets: MH<ListWalletsRoute>;
  ListNotificationSettings: SH<ListNotificationSettingsRoute>;
  ShowProfile: MSO<ShowProfileRouteParams | undefined, SH<ShowProfileRoute>>;
  ListSecuritySettings: MSO<
    ListSecuritySettingsRouteParams | undefined,
    SH<ListSecuritySettingsRoute>
  >;
  FallBack: MH<FallBackRoute>;
  ListLimits: MSO<ListLimitsRouteParams | undefined, SH<ListLimitsRoute>>;
  PromptNewWallet: MH<PromptNewWalletRoute>;
  PromptBuyCrypto: MH<PromptBuyCryptoRoute>;
  ShowWallet: MH<ShowWalletRoute>;
  ListInputAddresses: MH<ListInputAddressesRoute>;
  ShowQrToReceiveCrypto: MH<ShowQrToReceiveCryptoRoute>;
  PromptCryptoToReceive: MH<PromptCryptoToReceiveRoute>;
  PromptAddressFormat: MH<PromptAddressFormatRoute>;
  PromptReceiveNetwork: MH<PromptReceiveNetworkRoute>;
  PromptInputNetwork: MH<PromptInputNetworkRoute>;
  PromptCryptoToSend: MH<PromptCryptoToSendRoute>;
  PromptOutputAddress: MH<PromptOutputAddressRoute>;
  PromptOutputNetwork: MH<PromptOutputNetworkRoute>;
  PromptCommissionForSending: MH<PromptCommissionForSendingRoute>;
  PromptConfirmationToSendCrypto: MH<PromptConfirmationToSendCryptoRoute>;
  PromptOtpToSendCrypto: MH<PromptOtpToSendCryptoRoute>;
  NotifyAboutSuccessfulSending: MH<NotifyAboutSuccessfulSendingRoute>;
  PromptSourceCryptoToExchange: MH<PromptSourceCryptoToExchangeRoute>;
  PromptExchangeReceipt: MH<PromptExchangeReceiptRoute>;
  PromptCryptoToExchange: MH<PromptCryptoToExchangeRoute>;
  PromptConfirmationForExchange: MH<PromptConfirmationForExchangeRoute>;
  PromptOtpToExchange: MH<PromptOtpToExchangeRoute>;
  NotifyAboutSuccessfulExchange: MH<NotifyAboutSuccessfulExchangeRoute>;
  ListHistory: MH<ListHistoryRoute>;
  ListHistoryFilters: MH<ListHistoryFiltersRoute>;
  PromptWalletGroupToFilterHistory: MH<PromptWalletGroupToFilterHistoryRoute>;
  PromptWalletToFilterHistory: MH<PromptWalletToFilterHistoryRoute>;
  PromptTransactionKindToFilterHistory: MH<PromptTransactionKindToFilterHistoryRoute>;
  PromptPeriodToFilterHistory: MH<PromptPeriodToFilterHistoryRoute>;
  ShowTransaction: MH<ShowTransactionRoute>;
  ListQrCodeHistory: MH<ListQrCodeHistoryRoute>;
  Debug: MH<DebugRoute>;
  ListLogs: MH<ListLogsRoute>;
  ListSignOutReasonLogs: MH<ListSignOutReasonLogsRoute>;
  ListEnvironment: MH<ListEnvironmentsRoute>;
  PromptEnvironmentForm: MH<PromptEnvironmentFormRoute>;
  RedirectToSend: MH<RedirectToSendRoute>;
  PromptAffiliateProgram: MH<PromptAffiliateProgramRoute>;
  TwoFaWelcome: MH<TwoFaWelcomeRoute>;
  PromptSelectTwoFaProvider: MH<PromptSelectTwoFaProviderRoute>;
  GeneratePayMeLink: MH<GeneratePayMeLinkRoute>;
};

export type SettingsRouteName =
  | 'ListNotificationSettings'
  | 'ShowProfile'
  | 'PromptAffiliateProgram'
  | 'ListSecuritySettings'
  | 'TwoFaWelcome'
  | 'ListLimits';

export type MainRouteName = Exclude<
  keyof LargeSwitchParamList,
  SettingsRouteName
>;

export type RouteWithMainHeaderParams<T extends RouteBase> = MSO<
  RouteParams<T>,
  MainHeaderParams | undefined
>;

type MH<T extends RouteBase> = RouteWithMainHeaderParams<T>;

export type MainHeaderParams = {
  focusedHeader?: MainHeaderRoute;
};

export type MainHeaderRoute =
  | ListMenuCategoriesRoute
  | PromptThemeRoute
  | PromptLanguageRoute
  | ListNotificationsRoute;

export type RouteWithSettingsHeaderParams<T extends RouteBase> = MSO<
  RouteParams<T>,
  SettingsHeaderParams | undefined
>;

type SH<T extends RouteBase> = RouteWithSettingsHeaderParams<T>;

export type SettingsHeaderParams = {
  focusedHeader?: SettingsHeaderRoute;
};

export type SettingsHeaderRoute =
  | PromptThemeRoute
  | PromptLanguageRoute
  | ListNotificationsRoute;

export type ShowProfileRouteParams = {
  focusedCard?: ProfileCard;
  focusedDetails?: ProfileDetailsRoute;
  focusedVerification?: ProfileVerificationRoute;
  focusedBaseCurrency?: ProfileBaseCurrencyRoute;
};

export enum ProfileCard {
  Details,
  Verification,
  BaseCurrency,
}

export type ProfileDetailsRoute =
  | PromptNicknameRoute
  | PromptUserIdRoute
  | PromptEmailRoute;

export type ProfileVerificationRoute =
  | ListPersonalDataRoute
  | PromptNameAndBirthdateRoute
  | PromptResidentialAddressRoute
  | ListDocumentsRoute
  | IdentityVerificationUploadDocumentRoute
  | PromptIdentityVerificationAddressRoute
  | PromptToDeleteDocumentFileRoute;

export type ProfileBaseCurrencyRoute =
  | PromptBaseCryptoRoute
  | PromptBaseFiatRoute;

export type ListSecuritySettingsRouteParams = {
  focusedCard?: SecuritySettingsCard;
  focusedTwoFa?: ListSecuritySettingsTwoFaRoute;
  focusedPin?: ListSecuritySettingsPinRoute;
  focusedSessions?: ListSecuritySettingsSessionsRoute;
};

export enum SecuritySettingsCard {
  TwoFa,
  Pin,
  Sessions,
}

export type ListSecuritySettingsTwoFaRoute =
  | TwoFaWelcomeRoute
  | ShowTwoFASettingsRoute
  | ShowTwoFaGeneratedSecretRoute
  | ListAuthAppsRoute
  | PromptSelectTwoFaProviderRoute
  | PromptOtpToEnableTwoFaRoute
  | PromptOtpToDisableTwoFaRoute
  | PromptOtpToChangeTwoFaSettingsRoute;

export type ListSecuritySettingsPinRoute =
  | PromptBiometricSettingsRoute
  | CheckPinRoute
  | CheckBiometricsPinRoute
  | PromptNewPinRoute
  | PromptOtpToBiometricsRoute
  | NotifyAboutSuccessfulPinChangeRoute;

export type ListSecuritySettingsSessionsRoute =
  | ListAllowedIpsRoute
  | PromptUpdateIpRoute
  | BlockedIpsListRoute
  | ListSessionHistoryRoute
  | ListActiveSessionsRoute
  | PromptAddIpRoute
  | PromptSessionDeletionRoute
  | PromptToDeleteIpRoute
  | PromptOtpToAddIpRoute
  | PromptOtpToUpdateIpRoute
  | PromptOtpToDeleteIpRoute
  | PromptToClearAllowedListRoute
  | PromptOtpToClearAllowedListRoute;

export type ListLimitsRouteParams = {
  focusedCard?: LimitsCard;
  focusedSelectedLimit?: SelectedLimitRoute;
};

export enum LimitsCard {
  ActiveLimits,
  UpdateLimit,
  RemoveLimit,
  NewLimit,
}

export type SelectedLimitRoute =
  | PromptLimitUpdateRoute
  | ShowLimitRoute
  | PromptLimitRemovalRoute
  | PromptOtpToLimitCreateRoute
  | PromptOtpToLimitUpdateRoute
  | PromptOtpToLimitRemovalRoute
  | PromptNewLimitRoute;
