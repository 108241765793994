import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import {variance} from '@ncwallet-app/core';
import {expr} from '@ncwallet-app/core/src/mobx-toolbox';
import {useIsExchangeAllowed} from '@ncwallet-app/navigation/src/Navigation/hooks/useIsExchangeAllowed';
import type {
  BaseSafeAreaProps,
  CurrencySelectionListItemData,
} from '@ncwallet-app/ui';
import {
  CommonRefreshControl,
  CurrenciesListState,
  CurrencySelectionHeader,
  CurrencySelectionList,
  CurrencySelectionViewContainer,
  CurrencySkeletonList,
  FilteredToEmptyCurrencySelectionList,
  getCurrenciesListState,
  LG_BREAKPOINT,
  SelectionCryptoTopMessage,
  WalletActionKind,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {isTablet} from 'react-native-device-info';

import {useIsSuspended} from '../../Navigation/hooks/useIsSuspended';
import CurrencyListContainer from '../CurrencyScreen/CurrencyListContainer';
import CurrencyListItem from '../CurrencyScreen/CurrencyListItem';
import type {ScreenType} from '../SelectionForExchangeScreen/ScreenType';

export type SelectionForAddScreenProps = BaseSafeAreaProps & {
  screenType: ScreenType.Modal;
  getIsLoading: () => boolean;
  currencyListTitle?: string;
  onRefresh: () => void;
  getCurrencies: () => CurrencySelectionListItemData[] | undefined;
  getShownCurrencies: () => CurrencySelectionListItemData[] | undefined;
  ExtraDetailsComponent?: React.ReactNode;
  onCurrencyPress: (item: CurrencySelectionListItemData) => void;
  onBack: () => void;
  getSearch: () => string | undefined;
  setSearch: (next: string) => void;
  goToReceive: () => void;
  goToExchange: () => void;
  goToSend: () => void;
};

export default observer(function SelectionForAddScreen({
  ExtraDetailsComponent,
  goToReceive,
  goToExchange,
  goToSend,
  onRefresh,
  getIsLoading,
  currencyListTitle,
  ...props
}: SelectionForAddScreenProps) {
  const getIsRefreshing = useCallback(
    () => expr(() => getIsLoading()),
    [getIsLoading],
  );
  const refreshControl = useMemo(
    () => (
      <CommonRefreshControl
        getIsRefreshing={getIsRefreshing}
        onRefresh={onRefresh}
      />
    ),
    [getIsRefreshing, onRefresh],
  );

  const allCurrencies = props.getCurrencies();
  const shownCurrencies = props.getShownCurrencies();
  const listState = getCurrenciesListState(allCurrencies, shownCurrencies);
  return (
    <CurrencySelectionViewContainer>
      <CurrencySelectionHeader
        search={props.getSearch()}
        onSearchChange={props.setSearch}
        onBackPress={props.onBack}
        hideSearch={isTablet() || listState === CurrenciesListState.Empty}
        withSecondaryBackground
        title={currencyListTitle}
      />

      {ExtraDetailsComponent && ExtraDetailsComponent}

      {listState === CurrenciesListState.Loading && (
        <CurrencySkeletonList itemCount={4} />
      )}

      {listState === CurrenciesListState.Empty && (
        <NoCurrencies
          goToReceive={goToReceive}
          goToExchange={goToExchange}
          goToSend={goToSend}
        />
      )}

      {listState === CurrenciesListState.Normal && (
        <CurrencySelectionList
          currencies={shownCurrencies}
          refreshControl={refreshControl}
          showValueAsRate={false}
          title={currencyListTitle}
          {...props}
        />
      )}

      {listState === CurrenciesListState.FilteredToEmpty && (
        <FilteredToEmptyCurrencySelectionList
          refreshControl={refreshControl}
          allCurrencies={allCurrencies}
          {...props}
        />
      )}
    </CurrencySelectionViewContainer>
  );
});

type NoCurrenciesProps = {
  goToReceive: () => void;
  goToExchange: () => void;
  goToSend: () => void;
};

type Message = {
  title: LocaleKeys;
  message: LocaleKeys;
};

const NoCurrencies = (props: NoCurrenciesProps) => {
  const {isExchangeAllowed} = useIsExchangeAllowed();
  const getTopMessage: Message = useMemo(() => {
    return {
      title: 'currencySelection.coinsFull.title',
      message: 'currencySelection.coinsFull.message',
    };
  }, []);
  const isSuspended = useIsSuspended();
  return (
    <>
      <SelectionCryptoTopMessage texts={getTopMessage} />
      <CurrencyListContainer>
        <SelectionForAddListItem
          dataSet={{app: 'currency-item'}}
          disabled={isSuspended}
          onPress={props.goToReceive}
          type={WalletActionKind.Receive}
        />
        <SelectionForAddListItem
          dataSet={{app: 'currency-item'}}
          disabled={isSuspended}
          onPress={props.goToSend}
          type={WalletActionKind.Send}
        />
        {isExchangeAllowed && (
          <SelectionForAddListItem
            dataSet={{app: 'currency-item'}}
            disabled={isSuspended}
            onPress={props.goToExchange}
            type={WalletActionKind.Exchange}
          />
        )}
      </CurrencyListContainer>
    </>
  );
};

const SelectionForAddListItem = variance(CurrencyListItem)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        minHeight: 140,
      },
    }),
  },
}));
