import Hammer from '@egjs/hammerjs';
import type {Disposer} from '@ncwallet-app/core';
import {batchDisposers} from '@ncwallet-app/core';
import useGetElement from '@ncwallet-app/core/src/hooks/useGetElement';
import useObservableRef from '@ncwallet-app/core/src/mobx-react-toolbox/useObservableRef';
import {BigNumber} from 'bignumber.js';
import {clamp} from 'lodash';
import {autorun, observable} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useLayoutEffect, useRef, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import type {LayoutChangeEvent} from 'react-native/Libraries/Types/CoreEventTypes';

import type {CurrencyListItemSwipeContainerProps} from './WalletListItemSwipeContainerProps';

export default observer(function CurrencyListItemSwipeContainer(
  props: CurrencyListItemSwipeContainerProps,
) {
  const {value, renderRight, children, canChange} = props;
  const enabled = BigNumber(value).isEqualTo(0) && canChange;

  return (
    <Skateboard enabled={enabled} renderRight={renderRight}>
      {children}
    </Skateboard>
  );
});

type SkateboardProps = {
  enabled?: boolean;
  renderRight?: () => React.ReactNode;
  children?: React.ReactNode;
};

const Skateboard = observer(function Skateboard(props: SkateboardProps) {
  const {children, enabled, renderRight} = props;

  const enabledRef = useRef(enabled);
  enabledRef.current = enabled;

  const frontRef = useObservableRef<View>(null);
  const getFront = useGetElement(frontRef);

  const rightUnderlayRef = useObservableRef<View>(null);
  const getRightUnderlay = useGetElement(rightUnderlayRef);

  const [rightUnderlayWidth] = useState(() => observable.box<number>());
  const onRightUnderlayLayout = useCallback(
    (_: LayoutChangeEvent) => {
      rightUnderlayWidth.set(_.nativeEvent.layout.width);
    },
    [rightUnderlayWidth],
  );
  const getRightUnderlayWidth = useCallback(
    () => rightUnderlayWidth.get(),
    [rightUnderlayWidth],
  );

  const offsetRef = useRef(0);
  const disposerRef = useRef<() => void>();
  useLayoutEffect(
    () =>
      batchDisposers(
        (() => disposerRef.current?.()) as Disposer,
        autorun(() => {
          disposerRef.current?.();

          const _front = getFront();
          const _rightUnderlay = getRightUnderlay();
          if (!_front || !_rightUnderlay) {
            return;
          }
          const front = _front;
          const rightUnderlay = _rightUnderlay;

          const mc = new Hammer.Manager(front, {
            recognizers: [],
            enable: () => enabledRef.current ?? true,
          });

          const pan = new Hammer.Pan({
            direction: Hammer.DIRECTION_HORIZONTAL,
            threshold: 5,
          });

          mc.add(pan);

          mc.on('panstart', onPanStart);
          mc.on('panmove', onPanMove);
          mc.on('panend', onPanEnd);
          mc.on('pancancel', onPanCancel);

          disposerRef.current = () => {
            mc.off('pancancel', onPanCancel);
            mc.off('panend', onPanEnd);
            mc.off('panmove', onPanMove);
            mc.off('panstart', onPanStart);
            mc.remove(pan);
            mc.destroy();
            disposerRef.current = undefined;
          };

          function onPanStart() {
            front.style.transition = 'all 0s ease';
            rightUnderlay.style.transition = 'all 0s ease';
          }

          function onPanMove(_: HammerInput) {
            const pos = offsetRef.current + _.deltaX;
            const width = getRightUnderlayWidth() ?? 0;
            const clamped = clamp(pos, -width, 0);
            front.style.translate = `${clamped}px`;
            rightUnderlay.style.translate = `${clamped + width}px`;
          }

          function onPanEnd(_: HammerInput) {
            const width = getRightUnderlayWidth() ?? 0;
            if (_.velocityX < -0.3) {
              setFinal(-width, width);
            } else if (_.velocityX > 0.3) {
              setFinal(0, width);
            } else {
              const pos = offsetRef.current + _.deltaX;
              const final = pos < -width / 2 ? -width : 0;
              setFinal(final, width);
            }
          }

          function onPanCancel() {
            const width = getRightUnderlayWidth() ?? 0;
            setFinal(offsetRef.current, width);
          }

          function setFinal(final: number, width: number) {
            front.style.transition = 'translate 400ms ease-out';
            front.style.translate = `${final}px`;
            rightUnderlay.style.transition = 'translate 400ms ease-out';
            rightUnderlay.style.translate = `${final + width}px`;
            offsetRef.current = final;
          }
        }),
      ),
    [getFront, getRightUnderlay, getRightUnderlayWidth],
  );

  useLayoutEffect(
    () =>
      autorun(reaction => {
        const width = getRightUnderlayWidth();
        const rightUnderlay = getRightUnderlay();
        if (width === undefined || !rightUnderlay) {
          return;
        }
        rightUnderlay.style.translate = `${width}px`;
        reaction.dispose();
      }),
    [getRightUnderlay, getRightUnderlayWidth],
  );

  return (
    <View>
      <View style={styles._right}>
        <View
          ref={rightUnderlayRef}
          style={styles.right}
          onLayout={onRightUnderlayLayout}>
          {renderRight?.()}
        </View>
      </View>
      <View ref={frontRef} style={styles.front}>
        {children}
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  front: {
    position: 'relative',
    overflow: 'hidden',
  },
  _right: {
    ...StyleSheet.absoluteFillObject,
    flexDirection: 'row-reverse',
    alignItems: 'stretch',
  },
  right: {
    flexDirection: 'row-reverse',
  },
});
