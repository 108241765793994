import type {AnimatedBottomTabBarProps} from '@ncwallet-app/navigation/src/components';
import {AnimatedBottomTabBar} from '@ncwallet-app/navigation/src/components';
import type {DebugScreenProps} from '@ncwallet-app/navigation/src/screens/DebugScreen';
import {DebugScreen} from '@ncwallet-app/navigation/src/screens/DebugScreen';
import type {DetailsTelegramButtonProps} from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/LgProfileCards/DetailsTelegramButton';
import DetailsTelegramButton from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/LgProfileCards/DetailsTelegramButton';
import type {ProfileTelegramButtonProps} from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/ProfileTelegramButton';
import {ProfileTelegramButton} from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/ProfileTelegramButton';
import type {QrCodeScreenProps} from '@ncwallet-app/navigation/src/screens/QrCodeScreen';
import {QrCodeScreen} from '@ncwallet-app/navigation/src/screens/QrCodeScreen';
import React from 'react';

import type {AuthScreenFooterProps} from '../AuthScreenFooter';
import {AuthScreenFooter} from '../AuthScreenFooter';
import type {LgProfileEmailButtonProps} from '../LgProfileEmailButton';
import {LgProfileEmailButton} from '../LgProfileEmailButton';
import type {LogButtonProps} from '../LogButton';
import {LogButton} from '../LogButton';
import type {ProfileEmailButtonProps} from '../ProfileEmailButton';
import {ProfileEmailButton} from '../ProfileEmailButton';
import type {PlatformSpecific} from './PlatformSpecific';

export default class CommonPlatformSpecificComponents
  implements PlatformSpecific
{
  AuthScreenFooter = (props: AuthScreenFooterProps) => {
    return <AuthScreenFooter {...props} />;
  };

  ProfileTelegramButton = (props: ProfileTelegramButtonProps) => {
    return <ProfileTelegramButton {...props} />;
  };

  ProfileEmailButton = (props: ProfileEmailButtonProps) => {
    return <ProfileEmailButton {...props} />;
  };

  LgProfileEmailButton = (props: LgProfileEmailButtonProps) => {
    return <LgProfileEmailButton {...props} />;
  };

  DebugScreen = (props: DebugScreenProps) => {
    return <DebugScreen {...props} />;
  };

  LogButton = (props: LogButtonProps) => {
    return <LogButton {...props} />;
  };

  AnimatedBottomTabBar = (props: AnimatedBottomTabBarProps) => {
    return <AnimatedBottomTabBar {...props} />;
  };

  QrCodeScreen = (props: QrCodeScreenProps) => {
    return <QrCodeScreen {...props} />;
  };

  DetailsTelegramButton = (props: DetailsTelegramButtonProps) => {
    return <DetailsTelegramButton {...props} />;
  };
}
