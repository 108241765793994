import type {
  CryptoAddress,
  CryptoCurrencyCode,
  DecimalString,
} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {useEffect, useState} from 'react';

import {BuyLinkBindingState} from './BuyLinkBindingState';

interface BuyLinkBindingStateProps {
  currency?: CryptoCurrencyCode;
  amount?: DecimalString;
  address: CryptoAddress;
  network: AddressNetwork;
}

// eslint-disable-next-line import-x/prefer-default-export
export const useBuyLinkBindingState = (props: BuyLinkBindingStateProps) => {
  const {amount, currency, address, network} = props;
  const root = useRoot();
  const [state] = useState(new BuyLinkBindingState(root));

  useEffect(() => {
    if (currency) {
      state.setCurrency(currency);
    }
  }, [amount, currency, state]);

  useEffect(() => {
    if (address) {
      state.setAddress(address);
    }
  }, [amount, address, state]);

  useEffect(() => {
    if (network) {
      state.setNetwork(network);
    }
  }, [amount, network, state]);

  return state;
};
