import {useRoot, variance} from '@ncwallet-app/core';
import {useGetIsReadyToShowUserInterface} from '@ncwallet-app/core/src/AppStateHelper';
import {PREFIXES} from '@ncwallet-app/core/src/LinkingOptionsProvider/constant';
import type {LinkingOptions, ParamListBase} from '@react-navigation/native';
import {NavigationContainer} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';

import {TabBarProvider} from '../components';
import LargeNavigationRoot from '../LargeNavigationRoot/LargeNavigationRoot';
import SmallNavigationRoot from '../SmallNavigationRoot/SmallNavigationRoot';
import {AuthStack} from './AuthStack';
import BlockchainNetworksModalStack from './BlockchainNetworksModalStack/BlockchainNetworksModalStack';
import ModalStack from './ModalStack/ModalStack';
import {PushAdvertSplashContainer} from './PushAdvertSplash';
import RPCTimeoutErrorModalStack from './RPCTimeoutErrorModalStack/RPCTimeoutErrorModalStack';

export default observer(function NavigationRoot() {
  const {navigationContainerTheme, layoutHelperState} = useRoot();
  const getIsReady = useGetIsReadyToShowUserInterface();
  const isReady = getIsReady();
  const isLarge = layoutHelperState.isLarge;
  return (
    <TabBarProvider>
      <Root>
        {isLarge ? <LargeNavigationRoot /> : <SmallNavigationRoot />}
        <View style={styles.layer} pointerEvents="box-none">
          <ModalStack />
          <PushAdvertSplashContainer />
        </View>
        <View style={styles.layer} pointerEvents="box-none">
          <BlockchainNetworksModalStack />
        </View>
        <View style={styles.layer} pointerEvents="box-none">
          <RPCTimeoutErrorModalStack />
        </View>
        <View style={styles.layer} pointerEvents="box-none">
          <NavigationContainer
            documentTitle={{enabled: !isReady}}
            theme={navigationContainerTheme.theme}
            linking={authLinkingOptions}
            independent>
            <AuthStack />
          </NavigationContainer>
        </View>
      </Root>
    </TabBarProvider>
  );
});

const styles = StyleSheet.create({
  layer: {
    ...StyleSheet.absoluteFillObject,
  },
});

const Root = variance(View)(theme => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background,
  },
}));

const authLinkingOptions: LinkingOptions<ParamListBase> = {
  enabled: false,
  prefixes: PREFIXES.slice(),
};
