import type {FilterOption} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core/';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {ListWalletOptionsScreen} from '../../../../screens/ListWalletOptionsScreen';
import type {SmallHomeStackBindingProps} from '../../SmallHomeStackBindingProps';

export type ListWalletFilterOptionsBindingProps =
  SmallHomeStackBindingProps<'ListWalletOptions'>;

export default observer(function ListWalletFilterOptionsBinding(
  props: ListWalletFilterOptionsBindingProps,
) {
  const {walletsState} = useRoot();
  const {navigation} = props;

  const handleClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const handleFilterItemPress = useCallback(
    (filterOption: FilterOption) => {
      walletsState.walletsFilterOption = filterOption;
      handleClose();
    },
    [handleClose, walletsState],
  );

  return (
    <ListWalletOptionsScreen
      selectedFilterOption={walletsState.walletsFilterOption}
      onClose={handleClose}
      onFilterPress={handleFilterItemPress}
    />
  );
});
