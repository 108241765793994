import {
  type CurrencyCode,
  type DecimalString,
  useStrings,
  useStyles,
  variance,
} from '@ncwallet-app/core';
import {getOSKind} from '@ncwallet-app/core/src/util/getOSKind';
import {OSKind} from '@ncwallet-app/core/src/util/OSKind';
import {
  ButtonColor,
  ButtonIconPosition,
  ButtonVariant,
  LG_BREAKPOINT,
  SafeAreaScrollView,
  WalletHeader,
} from '@ncwallet-app/ui/src';
import {ArrowRightBigSVG} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {Platform, ScrollView} from 'react-native';

import {useIsSuspended} from '../../../Navigation/hooks/useIsSuspended';
import SendAmountInput from '../../SendAmountInputScreen/SendAmountInput';
import LgSendCommentInput from '../LgSendCommentInput';
import LgSendHeader from '../LgSendHeader';
import type {SendScreenProps} from '../SendScreen';
import {SendTabKey} from '../SendTabKey';
import {BottomContainer, ErrorTitle} from '../SharedComponents';
import LgAddressTabView from './LgAddressTabView';
import LgUserTabView from './LgUserTabView';
import {InputsRow, LgSubmitButton} from './Shared';

export type LgSendScreen = SendScreenProps & {
  amount: DecimalString | undefined;
  max: DecimalString | undefined;
  maxRestrictedByWalletLimit: boolean;
  amountError?: string;
  maxError?: string;
  onAmountChange: (amount: DecimalString | undefined) => void;
  onAddressToChange: (addressTo: string) => void;
  comment?: string;
  onCommentChange: (comment: string) => void;
  onBlurAddressInput: () => Promise<void>;
  contractType: string | null | undefined;
  onCurrencyPress?: () => void;
};

export default observer((props: LgSendScreen) => {
  const [activeTab] = useState<SendTabKey>(SendTabKey.Address);
  const strings = useStrings();
  const isSuspended = useIsSuspended();
  const error =
    isSuspended || props.addressToError || props.amountError || props.maxError;

  const styles = useSendStyles();
  return (
    <SafeAreaScrollView
      style={styles.root}
      contentContainerStyle={styles.container}>
      <Inner>
        <LgSendHeader tabs={null} />
        <WalletHeader
          forceExpand={false}
          onCurrencyPress={props.onCurrencyPress}
          cryptoValue={props.walletTotal}
          cryptoCurrency={props.cryptoCurrency}
          fiatCurrency={props.fiatCurrency}
          fiatValue={props.fiatValue}
          walletName={props.walletName}
        />
        {activeTab === SendTabKey.Address && <LgAddressTabView {...props} />}
        {activeTab === SendTabKey.Username && <LgUserTabView {...props} />}
        <InputsRow>
          <SendAmountInput
            wideStyle
            error={!!props.amountError}
            value={props.amount}
            cryptoCurrency={
              props.cryptoCurrency &&
              (props.cryptoCurrency.code as CurrencyCode)
            }
            onInputChange={props.onAmountChange}
            max={props.max}
            maxRestrictedByWalletLimit={props.maxRestrictedByWalletLimit}
            maxValueAvailable={!props.maxError}
            testID="amount-input"
          />
          <LgSendCommentInput
            value={props.comment}
            onInputChange={props.onCommentChange}
            goToFAQ={props.goToFAQ}
            isBlockchainComment={props.isBlockchainComment}
            wideStyle
          />
        </InputsRow>
        <BottomContainer>
          <ErrorTitle testID="amount-error">{error}</ErrorTitle>
          <LgSubmitButton
            title={strings['SendCrypto.Button.Continue']}
            disabled={!!error}
            variant={ButtonVariant.Highlighted}
            color={ButtonColor.Secondary}
            onPress={props.onSubmit}
            iconPosition={ButtonIconPosition.Right}
            Icon={ArrowRightBigSVG}
            testID="send-next-btn"
          />
        </BottomContainer>
      </Inner>
    </SafeAreaScrollView>
  );
});

const useSendStyles = () => {
  const OS = getOSKind();
  return useStyles(theme => ({
    root: {
      flex: 1,
      ...Platform.select({
        web: {
          overflow: 'visible',
        },
      }),
    },
    container: {
      overflow: 'visible',
      ...Platform.select({
        web: {
          paddingBottom:
            OS === OSKind.IOs ? 100 : OS === OSKind.Android ? 50 : 0,
        },
      }),

      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          backgroundColor: theme.palette.uiPrimary,
          paddingBottom: 0,
        },
      }),
    },
    rowTab: {
      marginLeft: 0,
      marginBottom: 0,
      marginRight: 0,
    },
    offsetRight: {
      marginRight: 7,
    },
  }));
};

const Inner = variance(ScrollView)(
  theme => ({
    root: {
      flex: 1,
      ...Platform.select({
        web: {
          paddingBottom: 30,
        },
      }),
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          flex: 1,
          borderRadius: 10,
          backgroundColor: theme.palette.background,
          paddingTop: 30,
          paddingHorizontal: 30,
          paddingBottom: 20,
          ...theme.bar(10),
        },
      }),
    },
  }),
  () => ({
    contentContainerStyle: {flex: 1},
  }),
);
