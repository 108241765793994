import type {BottomSheetMethods} from '@gorhom/bottom-sheet/lib/typescript/types';
import type {AuthVariant} from '@ncwallet-app/navigation/src/screens/AuthScreen';
import {ChooseLoginOptionVariantList} from '@ncwallet-app/navigation/src/screens/AuthScreen/ChooseLoginOptionVariantList';
import {CreateWalletVariantList} from '@ncwallet-app/navigation/src/screens/AuthScreen/CreateWalletVariantList';
import {
  BottomSheet,
  Button,
  ButtonColor,
  ButtonVariant,
  getPlatformSnapPoints,
  LG_BREAKPOINT,
  SafeAreaInset,
  SafeAreaLayout,
  useIsDimensions,
} from '@ncwallet-app/ui/src';
import {PlusSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';

import {useStrings} from '../../Root';
import {variance} from '../../styling';
import {sized} from '../../Svg';

export type AuthScreenFooterProps = {
  nextEnvironmentButtonShown: boolean;
  onNextEnvironmentPress: () => void;
  logInRef: React.RefObject<BottomSheetMethods>;
  signUpRef: React.RefObject<BottomSheetMethods>;
  openLogInVariant: () => void;
  openSignUpVariant: () => void;
  onLogInPress: (variant: AuthVariant) => void;
  onSignUpPress: (variant: AuthVariant) => void;
  onCloseBottomSheet: () => void;
  onMdLoginPress: () => void;
  onMdCreateWalletPress: () => void;
};

const AuthScreenFooter = observer(
  ({
    nextEnvironmentButtonShown,
    onNextEnvironmentPress,
    logInRef,
    signUpRef,
    openLogInVariant,
    openSignUpVariant,
    onLogInPress,
    onSignUpPress,
    onCloseBottomSheet,
    onMdLoginPress,
    onMdCreateWalletPress,
  }: AuthScreenFooterProps) => {
    const isLgScreen = useIsDimensions('lg');
    const strings = useStrings();
    const onClose = () => {
      onCloseBottomSheet();
    };

    return (
      <>
        <SafeAreaLayout insets={SafeAreaInset.BOTTOM}>
          <Root>
            <Button
              onPress={() => {
                if (isLgScreen) {
                  onMdLoginPress();
                } else {
                  openLogInVariant();
                }
              }}
              variant={ButtonVariant.PrimaryLight}
              color={ButtonColor.Secondary}
              title={strings['welcomeScreen.login']}
              style={styles.button}
              testID="login-btn"
            />

            <Button
              onPress={() => {
                if (isLgScreen) {
                  onMdCreateWalletPress();
                } else {
                  openSignUpVariant();
                }
              }}
              variant={ButtonVariant.Highlighted}
              color={ButtonColor.Secondary}
              Icon={PlusIcon}
              title={strings['welcome.createWalletButton']}
              style={styles.button}
              testID="create-wallet-btn"
            />

            {nextEnvironmentButtonShown && (
              <Button
                onPress={onNextEnvironmentPress}
                variant={ButtonVariant.PrimaryLight}
                color={ButtonColor.Secondary}
                title="Next Environment"
                style={styles.button}
              />
            )}
          </Root>
        </SafeAreaLayout>
        {!isLgScreen && (
          <>
            <BottomSheet
              ref={logInRef}
              index={-1}
              onClose={onClose}
              enablePanDownToClose
              backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
              snapPoints={[getPlatformSnapPoints(210)]}
              title={strings['welcomeScreen.loginOptions']}
              children={
                <ChooseLoginOptionVariantList onItemPress={onLogInPress} />
              }
            />
            <BottomSheet
              ref={signUpRef}
              index={-1}
              onClose={onClose}
              enablePanDownToClose
              backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
              snapPoints={[getPlatformSnapPoints(210)]}
              title={strings['welcomeScreen.createWalletOptions']}
              children={<CreateWalletVariantList onItemPress={onSignUpPress} />}
            />
          </>
        )}
      </>
    );
  },
);

export default AuthScreenFooter;

const styles = StyleSheet.create({
  button: {
    marginBottom: 15,
  },
});
const PlusIcon = sized(PlusSvg, 18);
const Root = variance(View)(theme => ({
  root: {
    paddingHorizontal: 15,
    width: '100%',

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 345,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
    }),
  },
}));
