/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/unbound-method */
import type {CryptoAddress} from '@ncwallet-app/core';
import {urlLinks, useRoot, useStrings} from '@ncwallet-app/core';
import useSendAddressErrorTexts from '@ncwallet-app/core/src/AddressUriHelper/useSendAddressErrorTexts';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {BigNumber} from 'bignumber.js';
import {noop} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

import {usePromptOutputAddressBindingState} from '../../../CommonNavigationContainers/hooks/PromptOutputAddressBindingState/usePromptOutputAddressBindingState';
import {useSendAmountErrorTexts} from '../../../CommonNavigationContainers/hooks/PromptOutputAddressBindingState/useSendAmountErrorTexts';
import {useRedirectToNotFoundIfNoWalletWithId} from '../../../CommonNavigationContainers/hooks/redirectToNotFoundOnWrongDataHooks';
import {SendCommissionBindingStateStatus} from '../../../Navigation/HomeStack/SendCommision/SendCommissionBindingState';
import {isChromeExtension} from '../../../Navigation/hooks/isChromeExtension';
import {useGoToCurrencyScreenIfNotMoney} from '../../../Navigation/hooks/useGoToCurrencyScreenIfNotMoney';
import {SendScreen} from '../../../screens/SendScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';
import {useNavigateToTab} from './PromptNewWalletBinding';

export type PromptOutputAddressBindingProps =
  SmallHomeStackBindingProps<'PromptOutputAddress'>;

export default observer(function PromptOutputAddressBinding(
  props: PromptOutputAddressBindingProps,
) {
  const {route, navigation} = props;
  const {
    fee,
    isEditable,
    walletId,
    addressTo,
    amount,
    comment,
    feeChangeDisabled,
    addressNetwork,
    addressCurrency,
    minFreeWithdrawAmount,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } = route.params ?? {};
  const root = useRoot();

  const strings = useStrings();

  useRedirectToNotFoundIfNoWalletWithId(walletId);

  const [state] = usePromptOutputAddressBindingState(
    walletId,
    addressNetwork,
    addressCurrency,
    addressTo as CryptoAddress,
    amount,
    comment,
    minFreeWithdrawAmount,
    fee,
  );

  const addressErrorTexts = useSendAddressErrorTexts();
  const amountErrorTexts = useSendAmountErrorTexts(state.min, state.minFee);

  const showAddressAction = !isChromeExtension() || root.appViewport.isPopup;
  const goToQrScreen = useCallback(async () => {
    await root.qrCodeScanner.open();
  }, [root]);
  const goToFAQ = useCallback(() => {
    void root.location.open(urlLinks.internalComment);
  }, [root.location]);
  useGoToCurrencyScreenIfNotMoney(state.wallet);

  const navigateToSend = useNavigateToTab('PromptCryptoToSend');

  const navigateToConfirm = useCallback(
    (fee_: string | undefined, feeChangeDisabled_: boolean | undefined) => {
      navigation.navigate('PromptConfirmationToSendCrypto', {
        addressNetwork: state.addressNetwork || addressNetwork,
        addressCurrency: addressCurrency,
        feeChangeDisabled: feeChangeDisabled_,
        fee: fee_,
        walletId,
        addressTo: state.addressTo,
        amount: state.amount!,
        comment: state.comment,
        currency: state.wallet!.currency,
        minFreeWithdrawAmount: route.params.minFreeWithdrawAmount,
      });
    },
    [
      navigation,
      state.addressNetwork,
      state.addressTo,
      state.amount,
      state.comment,
      state.wallet,
      addressNetwork,
      addressCurrency,
      walletId,
      route.params.minFreeWithdrawAmount,
    ],
  );

  const handleSmSubmit = useCallback(async () => {
    if (state.isValidatingAddressTo || !state.wallet || !state.fees) {
      return;
    }
    state.validateAmount();
    await state.validateAddressTo();
    const error =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      amountErrorTexts[state.amountError!] ??
      addressErrorTexts[state.addressToError!];

    if (error) {
      return;
    }

    navigation.setParams({
      addressNetwork: state.addressNetwork,
      amount: state.amount,
      addressTo: state.addressTo,
      comment: state.comment,
      fee: state.fee,
      minFreeWithdrawAmount: route.params.minFreeWithdrawAmount,
    });

    const hasFee = fee && BigNumber(fee).isGreaterThan(0);
    const _isFreeWithdrawalUnavailable =
      state.fees.length === 1 && state.isFreeWithdrawalUnavailable;
    const _fee = _isFreeWithdrawalUnavailable ? state.fees[0] : state.fee;

    if (isEditable && (hasFee || state.isEnoughAmountForFreeWithdrawal)) {
      navigateToConfirm(fee, feeChangeDisabled);
    } else {
      navigateToConfirm(_fee, true);
    }
  }, [
    state,
    addressErrorTexts,
    amountErrorTexts,
    navigation,
    fee,
    isEditable,
    navigateToConfirm,
    feeChangeDisabled,
    route.params.minFreeWithdrawAmount,
  ]);

  const navigateToPromptNetwork = useCallback(() => {
    navigation.navigate('PromptOutputNetwork', {
      addressNetwork: state.addressNetwork || addressNetwork,
      addressCurrency: addressCurrency,
      walletId,
      fee,
      addressTo: state.addressTo,
      amount: state.amount!,
      currency: state.wallet!.currency,
      minFreeWithdrawAmount: route.params.minFreeWithdrawAmount,
    });
  }, [
    navigation,
    state.addressNetwork,
    state.addressTo,
    state.amount,
    state.wallet,
    addressNetwork,
    addressCurrency,
    walletId,
    fee,
    route.params.minFreeWithdrawAmount,
  ]);

  const max = state.getMax(walletId);
  const isAmountWithFeeMoreThatMax = useMemo(() => {
    if (!Number(state.fee) || !amount || !max) {
      return false;
    }

    const _fee = BigNumber(state.fee!);
    const _amount = BigNumber(amount);
    const _max = BigNumber(max);

    return (
      _fee.isFinite() &&
      _amount.isFinite() &&
      _max.isFinite() &&
      _fee.plus(_amount).isGreaterThanOrEqualTo(_max)
    );
  }, [state.fee, amount, max]);

  return (
    <SendScreen
      onCurrencyPress={navigateToSend}
      addressNameShown={state.networkShown}
      onAddressNamePress={navigateToPromptNetwork}
      networkChangeEnabled={state.networkChangeEnabled}
      addressName={state.addressName}
      cryptoCurrency={state.cryptoCurrency}
      walletTotal={state.wallet?.total}
      networkCurrency={state.networkCurrency}
      fiatCurrency={state.fiatCurrency}
      fiatValue={state.fiatTotal}
      onUserToChange={noop}
      walletName={strings['sendCrypto.walletName']}
      stepTotal={2}
      stepCurrent={1}
      userTo={''}
      addressTo={state.addressTo}
      onAddressToChange={state.setAddressTo}
      addressToError={addressErrorTexts[state.addressToError!]}
      onUserActionPress={noop}
      onBlurAddressInput={state.onBlurAddressInput}
      onAddressActionPress={goToQrScreen}
      showAddressAction={showAddressAction}
      isEditable={isEditable}
      onSubmit={handleSmSubmit}
      contractType={state.contractType}
      maxAmount={state.max}
      goToFAQ={goToFAQ}
      maxAmountRestrictedByWalletLimit={state.maxRestrictedByWalletLimit}
      amountError={amountErrorTexts[state.amountError!]}
      maxError={
        state.isMinFeeMoreThanAvailableMax
          ? strings['sendCrypto.validation.networkFeeExceedsMax']
          : undefined
      }
      amount={state.amount}
      onAmountChange={state.setAmount}
      comment={state.comment}
      onCommentChange={state.setComment}
      getHasNoCommission={state.getHasNoCommission}
      setHasNoCommission={state.setHasNoCommission}
      minFreeAmount={state.minFreeAmount}
      commissionSwitcherHidden={!state.freeSendAvailableForCrypto}
      isEnoughAmountForFreeSend={state.isEnoughAmountForFreeSend}
      recommendedFee={state.recommendedFee}
      fees={state.fees}
      fee={state.fee}
      feeError={
        isAmountWithFeeMoreThatMax
          ? strings['sendCrypto.validation.amountAndFeeMoreThanTotal']
          : undefined
      }
      setFee={state.setFee}
      disabled={state.status === SendCommissionBindingStateStatus.Load}
      isBlockchainComment={state.isBlockchainComment}
      insets={SafeAreaInset.BOTTOM}
    />
  );
});
