import type {Theme} from '@ncwallet-app/core';
import {useStyles, useTheme} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import type {StyleProp, TextStyle, ViewStyle} from 'react-native';
import {Text as RNText, StyleSheet} from 'react-native';
import type {SvgProps} from 'react-native-svg';

import {CheckSvg} from '../../assets/svg/colorless';
import type {PressableProps} from './Pressable';
import {PressableNativeFeedback} from './PressableNativeFeedback';

export enum ButtonColor {
  Default,
  Primary,
  Secondary,
  Error,
  Success,
  White,
}

// Because web interactions(hover, active and so on) are declared in globalWebStyles
// new interactions for new ButtonVariant should be declared as well
export enum ButtonVariant {
  Default,
  ThemeButton,
  Text,
  Highlighted,
  Primary,
  PrimaryLight,
  SecondaryLight,
  Danger,
  TabletDefault,
  Orange,
}

export enum ButtonIconPosition {
  Left,
  Right,
}

export type ButtonProps = Omit<PressableProps, 'children'> & {
  title?: string;
  Icon?: React.ComponentType<SvgProps>;
  iconPosition?: ButtonIconPosition;
  variant?: ButtonVariant;
  color?: ButtonColor;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  iconCustomColor?: string;
  checked?: boolean;
};

export const Button = observer(function Button(props: ButtonProps) {
  const {
    title,
    variant = ButtonVariant.Default,
    color = ButtonColor.Default,
    disabled = false,
    Icon,
    iconPosition = ButtonIconPosition.Left,
    style,
    iconCustomColor,
    checked = false,
    ...rest
  } = props;
  const theme = useTheme();
  const rootStyles = getRootStyles(color, variant, disabled, theme); //[, style];
  const textStyles = getTextStyles(color, variant, theme);
  const iconColor = getTextColor(color, variant, theme);
  const borderStyles = getBorderStyle(variant, theme);
  const iconStyles = useStyles(() => ({
    iconLeft: {
      marginRight: variant === ButtonVariant.ThemeButton ? -10 : 10,
    },
    iconRight: {
      marginLeft: 10,
    },
    iconTablet: {
      marginRight: 10,
    },
    checkMarker: {
      position: 'absolute',
      right: 15,
      top: 15,
    },
    noTitle: {
      marginLeft: 0,
      marginRight: 0,
    },
  }));

  return (
    <PressableNativeFeedback
      containerStyle={[rootStyles, style]}
      disabled={disabled}
      borderStyle={borderStyles}
      underlayColor="transparent"
      {...rest}
      dataSet={{
        ['app-button']: `button-${ButtonVariant[variant]}-color-${ButtonColor[color]}`,
      }}>
      {Icon && iconPosition === ButtonIconPosition.Left && (
        <Icon
          style={[
            variant !== ButtonVariant.TabletDefault
              ? iconStyles.iconLeft
              : iconStyles.iconTablet,
            title ? undefined : iconStyles.noTitle,
          ]}
          color={iconCustomColor || iconColor}
        />
      )}

      {title && <RNText style={[textStyles, props.textStyle]}>{title}</RNText>}

      {checked && (
        <CheckSvg
          style={iconStyles.checkMarker}
          color={theme.palette.uiAdditional1}
        />
      )}

      {Icon && iconPosition === ButtonIconPosition.Right && (
        <Icon
          style={[iconStyles.iconRight, title ? undefined : iconStyles.noTitle]}
          color={iconColor}
        />
      )}
    </PressableNativeFeedback>
  );
});

const getRootStyles = (
  color: ButtonColor,
  variant: ButtonVariant,
  disabled: boolean,
  theme: Theme,
): StyleProp<ViewStyle> => {
  const backlightColor = translateMainColor(color, theme);
  const backgroundColor = backlightColor ?? theme.palette.background;
  const borderColor =
    variant === ButtonVariant.Default
      ? (backlightColor ?? theme.palette.textMain)
      : backlightColor;
  return [
    styles.root,
    disabled && styles.rootDisabled,
    variant === ButtonVariant.Highlighted && {
      backgroundColor,
    },
    variant === ButtonVariant.Default && [
      {borderColor},
      styles.rootDefaultVariant,
    ],

    variant === ButtonVariant.PrimaryLight && {
      backgroundColor: theme.palette.additional3,
    },

    variant === ButtonVariant.Danger && {
      backgroundColor: theme.palette.additional5,
    },

    variant === ButtonVariant.Primary && {
      backgroundColor: theme.palette.info,
    },

    variant === ButtonVariant.ThemeButton && {
      justifyContent: 'center',
    },

    variant === ButtonVariant.Orange && {
      backgroundColor: theme.palette.primary,
    },

    variant === ButtonVariant.TabletDefault && {
      borderWidth: 0,
      backgroundColor: theme.palette.whitey,
      alignItems: 'center',
      paddingVertical: 0,
      paddingHorizontal: 10,
    },

    variant === ButtonVariant.SecondaryLight && {
      backgroundColor: theme.palette.uiPrimary,
      borderColor: 'transparent',
    },
  ];
};

const getTextStyles = (
  color: ButtonColor,
  variant: ButtonVariant,
  theme: Theme,
): StyleProp<TextStyle> => {
  return [
    styles.text,
    [theme.fontByWeight('700')],

    variant === ButtonVariant.ThemeButton && {
      ...theme.fontByWeight('400'),
      alignSelf: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    variant === ButtonVariant.PrimaryLight && {
      ...theme.fontByWeight('700'),
    },

    variant === ButtonVariant.TabletDefault && {
      lineHeight: 40,
    },

    variant === ButtonVariant.SecondaryLight && {
      color: theme.palette.textMain,
    },

    {
      color: getTextColor(color, variant, theme),
    },
  ];
};

const getBorderStyle = (
  variant: ButtonVariant,
  theme: Theme,
): StyleProp<ViewStyle> => {
  return [
    variant === ButtonVariant.ThemeButton && {
      borderColor: theme.palette.uiSecondary,
      borderWidth: 1,
    },
    {borderRadius: 6},
  ];
};

const getTextColor = (
  color: ButtonColor,
  variant: ButtonVariant,
  theme: Theme,
): string => {
  if (variant === ButtonVariant.Text && color === ButtonColor.Default) {
    return theme.palette.textMain;
  }
  if (variant === ButtonVariant.Highlighted) {
    if (color === ButtonColor.Default) {
      return theme.palette.textMain;
    }
    return theme.select(
      theme.contrast(theme.palette.textMain),
      theme.palette.textMain,
    );
  }

  if (variant === ButtonVariant.Primary) {
    return theme.palette.whitey;
  }

  if (variant === ButtonVariant.PrimaryLight) {
    return theme.palette.info;
  }

  if (variant === ButtonVariant.Danger) {
    return theme.palette.error;
  }

  if (variant === ButtonVariant.ThemeButton) {
    return theme.palette.textPrimary;
  }

  return translateMainColor(color, theme) ?? theme.palette.textMain;
};

const translateMainColor = (color: ButtonColor, theme: Theme) => {
  switch (color) {
    case ButtonColor.Success:
      return theme.palette.success;
    case ButtonColor.Error:
      return theme.palette.error;
    case ButtonColor.Primary:
      return theme.palette.primary;
    case ButtonColor.Secondary:
      return theme.palette.info;
    case ButtonColor.White:
      return theme.palette.whitey;
    default:
      return undefined;
  }
};

const styles = StyleSheet.create({
  root: {
    borderRadius: 6,
    paddingVertical: 15,
    paddingHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rootDisabled: {
    opacity: 0.5,
  },
  rootDefaultVariant: {
    borderWidth: 1,
  },
  text: {
    fontSize: 16,
    lineHeight: 20,
  },
});
