import type {
  FilterOption,
  WalletId,
  WalletListItemData,
  WalletSlideData,
} from '@ncwallet-app/core';
import {useTheme, variance} from '@ncwallet-app/core';
import type {BaseSafeAreaProps, InsetsProps} from '@ncwallet-app/ui';
import {CommonRefreshControl, OrangeShapeBackground} from '@ncwallet-app/ui';
import {LinearGradient} from 'expo-linear-gradient';
import {isEmpty} from 'lodash';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import React, {useCallback, useMemo} from 'react';
import {Platform, View} from 'react-native';

import {WalletList} from './WalletList/WalletList';
import {WalletListHeader} from './WalletListHeader';
import WalletsSlider from './WalletsSlider';

export enum WalletListFilter {
  All,
  WithFunds,
}

export type SmallWalletsScreenProps = BaseSafeAreaProps & {
  getTotal: () => WalletSlideData | undefined;
  getWallets: () => WalletListItemData[] | undefined;
  onWalletSelect: (d: WalletListItemData) => void;
  onCurrencyAdd: () => void;
  onBuyCrypto: () => void;
  insets?: InsetsProps;
  getIsLoading: () => boolean;
  getIsManualRefresh: () => boolean;
  onRefresh: () => void;
  hideWallet: (id: WalletId) => void;
  onLastTransactionPress: () => void;
  isWalletFilterAvailable: boolean;
  selectedWalletListFilter: WalletListFilter;
  selectedFilterOption: FilterOption;
  onFilterPress: () => void;
};

export default observer(function SmallWalletsScreen(
  props: SmallWalletsScreenProps,
) {
  const {
    getTotal,
    getWallets,
    onWalletSelect,
    onCurrencyAdd,
    getIsLoading,
    getIsManualRefresh,
    onRefresh,
    onLastTransactionPress,
    isWalletFilterAvailable,
    selectedWalletListFilter,
    selectedFilterOption,
    onFilterPress,
    onBuyCrypto,
    ...rest
  } = props;
  const theme = useTheme();

  const getFilteredWallets = useCallback(
    () =>
      expr(() => {
        return selectedWalletListFilter === WalletListFilter.All
          ? getWallets()
          : getWallets()?.filter(_ => _.cryptoValue !== '0');
      }),
    [getWallets, selectedWalletListFilter],
  );

  const getWalletCount = useCallback(
    () => expr(() => getFilteredWallets()?.filter(_ => _.is_visible).length),
    [getFilteredWallets],
  );

  const getIsRefreshing = useCallback(
    () => expr(() => getIsLoading() && getIsManualRefresh()),
    [getIsLoading, getIsManualRefresh],
  );

  const refreshControl = useMemo(
    () => (
      <WalletsRefreshControl
        getIsRefreshing={getIsRefreshing}
        onRefresh={onRefresh}
      />
    ),
    [getIsRefreshing, onRefresh],
  );

  const wallets = getWallets();

  const ListHeader = useCallback(
    () => (
      <>
        <Container
          colors={[
            theme.palette.mainCardBackgroundStart,
            theme.palette.mainCardBackgroundEnd,
          ]}>
          <OrangeShapeBackground />
          <WalletsSlider
            getWallet={getTotal}
            onLastTransactionPress={onLastTransactionPress}
          />
        </Container>
        {!isEmpty(wallets) && (
          <WalletListHeader
            getWalletCount={getWalletCount}
            onAddCurrencyPress={onCurrencyAdd}
            selectedFilterOption={selectedFilterOption}
            onFilterPress={isWalletFilterAvailable ? onFilterPress : null}
            onBuyCrypto={onBuyCrypto}
          />
        )}
      </>
    ),
    [
      theme.palette.mainCardBackgroundStart,
      theme.palette.mainCardBackgroundEnd,
      getTotal,
      onLastTransactionPress,
      wallets,
      getWalletCount,
      onCurrencyAdd,
      selectedFilterOption,
      isWalletFilterAvailable,
      onFilterPress,
      onBuyCrypto,
    ],
  );

  return (
    <>
      <Root>
        <WalletList
          ListHeaderComponent={ListHeader}
          getWallets={getFilteredWallets}
          onWalletPress={onWalletSelect}
          refreshControl={refreshControl}
          onCurrencyAddPress={onCurrencyAdd}
          onBuyCryptoPress={onBuyCrypto}
          {...rest}
        />
      </Root>
    </>
  );
});

const Root = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const Container = variance(LinearGradient)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    overflow: 'hidden',
  },
}));

const WalletsRefreshControl = variance(CommonRefreshControl)(
  () => ({root: {}}),
  theme => ({
    ...Platform.select({
      ios: {
        tintColor: theme.palette.textMain,
        titleColor: theme.palette.textMain,
      },
    }),
  }),
);
