import type {FilterOption, WalletId} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {useResponsiveContainerWidth} from '@ncwallet-app/ui/src/components/grid/Container';
import {isEmpty} from 'lodash';
import {reaction} from 'mobx';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import type {PropsWithChildren} from 'react';
import React, {useCallback, useEffect, useState} from 'react';
import type {ScrollViewProps} from 'react-native';
import {StyleSheet, View} from 'react-native';

import {CurrencyCard} from '../../../../../../components/CurrencyCard';
import {WalletList} from '../../../../../../screens/SmallListWalletsScreen/WalletList/WalletList';
import {WalletListHeader} from '../../../../../../screens/SmallListWalletsScreen/WalletListHeader';
import WalletsSlider from '../../../../../../screens/SmallListWalletsScreen/WalletsSlider';
import Banner from './Banner';
import {AsideColumn, MainColumn} from './Grid';
import ContentFooter from './MainFooter';
import {useRequestWalletLayoutDataOnLg} from './useRequestWalletLayoutDataOnLg';
import WebContainer from './WebContainer';
import type {WideWalletLayoutProps} from './WideWalletLayoutProps';

export default observer(function LargeLayout(
  props: PropsWithChildren<WideWalletLayoutProps>,
) {
  const {
    children,
    onWalletSelect,
    onCurrencyAdd,
    onBuyCrypto,
    withAside = true,
    visibleLgComponents,
    onLastTransactionPress,
  } = props;
  const {walletsState, navigationContainer, rpcTimeoutErrorVisibility} =
    useRoot();
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  useRequestWalletLayoutDataOnLg();

  const getWalletCount = useCallback(
    () =>
      expr(() => walletsState.getWallets()?.filter(_ => _.is_visible).length),
    [walletsState],
  );

  const onWalletHideHandler = useCallback(
    (id: WalletId) => {
      walletsState.hideWallet(id);
      navigationContainer.ref?.resetRoot({
        index: 0,
        routes: [{name: 'Root'}],
      });
    },
    [walletsState, navigationContainer],
  );

  useEffect(
    () =>
      reaction(
        () => walletsState.isTimeoutError,
        shouldFire => {
          if (shouldFire) {
            rpcTimeoutErrorVisibility.registerAction(walletsState.refresh);
          }
        },
      ),
    [walletsState, rpcTimeoutErrorVisibility],
  );

  const handleFilterPress = useCallback(() => {
    setIsFilterOpened(prev => !prev);
  }, []);

  const handleFilterOptionPress = useCallback(
    (filter: FilterOption) => {
      walletsState.walletsFilterOption = filter;
      setIsFilterOpened(false);
    },
    [walletsState],
  );

  const wallets = walletsState.getWallets();
  const isEmptyWallets = isEmpty(wallets);

  return (
    <RootContainer visibleLgComponents={visibleLgComponents}>
      {withAside && visibleLgComponents && (
        <AsideColumn>
          <View>
            <View style={styles.card}>
              <WalletsSlider
                getWallet={walletsState.getTotal}
                onLastTransactionPress={onLastTransactionPress}
              />
            </View>
            <CurrencyCard>
              {!isEmptyWallets && (
                <WalletListHeader
                  isFilterOpened={isFilterOpened}
                  getWalletCount={getWalletCount}
                  onAddCurrencyPress={onCurrencyAdd}
                  selectedFilterOption={walletsState.walletsFilterOption}
                  handleFilterOptionPress={handleFilterOptionPress}
                  onFilterPress={
                    walletsState.isWalletsFilterAvailable
                      ? handleFilterPress
                      : null
                  }
                  onBuyCrypto={onBuyCrypto}
                />
              )}
              <View style={styles.listView}>
                <WalletList
                  getWallets={walletsState.getWallets}
                  onWalletPress={onWalletSelect}
                  onCurrencyAddPress={onCurrencyAdd}
                  hideWallet={onWalletHideHandler}
                  onBuyCryptoPress={onBuyCrypto}
                />
              </View>
            </CurrencyCard>
            <View style={styles.banner}>
              <Banner />
            </View>
          </View>
        </AsideColumn>
      )}
      <MainColumn>
        <View
          style={[
            styles.mainContainer,
            visibleLgComponents && styles.mainContainerLg,
          ]}>
          <View
            style={[styles.content, visibleLgComponents && styles.contentLg]}>
            {children}
          </View>
          {visibleLgComponents && (
            <ContentFooter news={props.news} tips={props.tips} />
          )}
        </View>
      </MainColumn>
    </RootContainer>
  );
});

export type RootContainerProps = ScrollViewProps & {
  visibleLgComponents: boolean;
};

const RootContainer = observer(
  (props: PropsWithChildren<RootContainerProps>) => {
    const {children, visibleLgComponents, ...rest} = props;
    const containerWidth = useResponsiveContainerWidth();
    return (
      <WebContainer
        {...rest}
        styles={[
          styles.root,
          visibleLgComponents && styles.rootLg,
          containerWidth ? {width: containerWidth} : undefined,
        ]}>
        {children}
      </WebContainer>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    paddingTop: 30,
  },
  rootLg: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    marginHorizontal: 'auto',
  },
  listView: {
    flex: 1,
    maxHeight: 350,
  },
  listContainerNoHeader: {
    paddingTop: 20,
    maxHeight: 380,
  },
  listContainer: {
    maxHeight: 380,
  },
  mainContainer: {
    flexGrow: 1,
  },
  mainContainerLg: {
    paddingBottom: 50,
  },
  content: {
    flex: 1,
  },
  contentLg: {
    maxHeight: 530,
    minHeight: 530,
    marginBottom: 20,
    borderRadius: 10,
  },
  banner: {
    marginTop: 20,
  },
  card: {
    marginBottom: 20,
  },
});
