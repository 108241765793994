import {isEqual} from 'lodash';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import type {AccountStore} from '../../AccountStore';
import type {BaseAsyncOptions} from '../../Async';
import type {AccountIdStore} from '../../Auth';
import type {
  CancellationError,
  GeneralJsonRpcError,
  GlobalError,
} from '../../Error';
import {CANCELLATION_ERROR, TIMEOUT_ERROR, UNKNOWN_ERROR} from '../../Error';
import type {ErrorRepository} from '../../ErrorRepository';
import type {FlashMessage} from '../../FlashMessage';
import type {Either} from '../../fp';
import {error, failure} from '../../fp';
import type {JsonRpcClient, JsonRpcServer} from '../../JsonRpc';
import type {Localization} from '../../Localization';
import type {CryptoCurrencyCode, CurrencyCode} from '../../Money';
import type {
  AddressInfo,
  CommonError,
  CryptoAddress,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  NCWalletReverseCallScheme,
  NCWalletReverseNotificationScheme,
  Wallet,
  WalletId,
} from '../../NCWalletServer';
import type {
  AddressNetwork,
  AddressType,
} from '../../NCWalletServer/AddressInfo';
import type {WalletsAddressesErrorData} from '../../NCWalletServer/WalletsAddressesCreate';
import {ADDRESS_CREATION_NOT_ALLOWED} from '../../NCWalletServer/WalletsAddressesCreate';
import type {SentryLog} from '../../SentryLog';
import createJsonRpcTimeoutErrorMessage from '../../SentryLog/createJsonRpcTimeoutErrorMessage';
import type {RouterSource, Service} from '../../structure';
import {batchDisposers, RouterImpl} from '../../structure';
import type {BaseTransactionOptions} from '../../util';
import type {WalletStore} from './WalletStore';
import type {WalletStoreEventMap} from './WalletStoreEvents';
import {
  WALLET_BALANCE_UPDATE,
  WALLET_CREATE,
  WALLET_VISIBILITY_CHANGE,
} from './WalletStoreEvents';

const ADDRESS_NOT_ALLOWED_NOTIFICATION_TIMEOUT = 10000;
const ADDRESS_NOT_ALLOWED_NOTIFICATION_ID =
  'ADDRESS_NOT_ALLOWED_NOTIFICATION_ID';

export default class WalletStoreService implements WalletStore, Service {
  private _events = new RouterImpl<WalletStoreEventMap>();

  @observable.ref private _wallets: Wallet[] | undefined;
  @observable.ref private _isLoading = false;
  @observable.ref private _isLoaded = false;

  @computed
  private get _codeToWalletsMap(): Map<CurrencyCode, Wallet[]> | undefined {
    if (!this._wallets) {
      return;
    }

    return this._wallets.reduce((acc, wallet) => {
      const wallets = acc.get(wallet.currency);
      if (wallets === undefined || wallets.length === 0) {
        acc.set(wallet.currency, [wallet]);
      } else {
        wallets.push(wallet);
      }
      return acc;
    }, new Map<CurrencyCode, Wallet[]>());
  }

  get events(): RouterSource<WalletStoreEventMap> {
    return this._events;
  }

  @computed({keepAlive: true})
  private get _idToWalletMap() {
    return this._wallets && new Map(this._wallets.map(w => [w.id, w]));
  }

  constructor(
    private readonly _root: {
      readonly accountStore: AccountStore;
      readonly errorRepository: ErrorRepository;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly ncWalletJsonRpcServer: JsonRpcServer<
        NCWalletReverseCallScheme,
        NCWalletReverseNotificationScheme
      >;
      readonly accountIdStore: AccountIdStore;
      readonly sentryLog: SentryLog;
      readonly flashMessage: FlashMessage;
      readonly localization: Localization;
    },
  ) {
    makeObservable(this);
  }

  isLoading(): boolean {
    return this._isLoading;
  }

  isLoaded(): boolean {
    return this._isLoaded;
  }

  getWallets(): Wallet[] | undefined {
    return this._wallets;
  }

  getWalletsByCode(code: CryptoCurrencyCode): Wallet[] {
    return this._codeToWalletsMap?.get(code) || [];
  }

  getWalletById(id: WalletId): Wallet | undefined {
    return this._idToWalletMap?.get(id);
  }

  async refreshWallets(
    options?: BaseAsyncOptions & BaseTransactionOptions,
  ): Promise<Either<Wallet[], GlobalError | GeneralJsonRpcError<CommonError>>> {
    if (options?.signal?.aborted) {
      return error(this._createCancellationError(options.signal.reason));
    }

    const _runInAction = options?.postpone ?? runInAction;
    _runInAction(() => {
      this._isLoading = true;
    });

    const res = await this._root.ncWalletJsonRpcClient.call(
      'wallets',
      undefined,
      options,
    );

    if (!res.success) {
      if (res.left.kind === TIMEOUT_ERROR) {
        this._root.sentryLog.write(
          createJsonRpcTimeoutErrorMessage('[wallets]'),
        );
      }
    }

    _runInAction(() => {
      if (res.success && !isEqual(res.right, this._wallets)) {
        this._wallets = res.right.slice();
      }

      this._isLoading = false;
      this._isLoaded = true;
    });

    return res;
  }

  async createWallet(
    currency: CryptoCurrencyCode,
    description = '',
  ): Promise<Either<Wallet, GlobalError | GeneralJsonRpcError<CommonError>>> {
    const res = await this._root.ncWalletJsonRpcClient.call('wallets.create', {
      currency,
      description,
    });

    const wallets = this._wallets;
    if (res.success && wallets && !wallets.some(_ => _.id === res.right.id)) {
      runInAction(() => {
        this._events.send(WALLET_CREATE, res.right);
        this._wallets = [...wallets, res.right];
      });
    }

    return res;
  }

  async updateWalletVisibility(
    walletId: WalletId,
    isVisible: boolean,
  ): Promise<Either<Wallet, GlobalError | GeneralJsonRpcError<CommonError>>> {
    const res = await this._root.ncWalletJsonRpcClient.call('wallets.update', {
      wallet_id: walletId,
      is_visible: isVisible,
    });

    const wallets = this._wallets;
    if (
      res.success &&
      wallets &&
      !wallets.some(
        _ => _.id === res.right.id && _.is_visible === res.right.is_visible,
      )
    ) {
      runInAction(() => {
        this._events.send(WALLET_VISIBILITY_CHANGE, res.right);
        this._wallets = [...wallets, res.right];
      });
    }

    return res;
  }

  async createAddress(
    walletId: WalletId,
    network: AddressNetwork,
    type?: AddressType,
  ): Promise<Either<Wallet, GlobalError | GeneralJsonRpcError<CommonError>>> {
    const res = await this._root.ncWalletJsonRpcClient.call(
      'wallets.addresses.create',
      {wallet_id: walletId, address_type: type, network},
    );

    if (res.success) {
      this.updateSavedWallet(res.right);
    } else {
      const body = (Reflect.get(res.left, 'body') ?? {}) as object;
      const code = Reflect.get(body, 'code') as number | undefined;
      const data = Reflect.get(body, 'data') as
        | WalletsAddressesErrorData
        | undefined;

      if (code === ADDRESS_CREATION_NOT_ALLOWED && data) {
        const errorKey = this._root.localization.getPluralKey({
          count: data.mintrans,
          translationKeys: {
            one: 'error.addressNotAllowed_one',
            few: 'error.addressNotAllowed_few',
            many: 'error.addressNotAllowed_many',
            other: 'error.addressNotAllowed_other',
          },
        });

        const isMessagesShown = this._root.flashMessage
          .getMessages()
          .some(({id}) => {
            return id === ADDRESS_NOT_ALLOWED_NOTIFICATION_ID;
          });

        if (!isMessagesShown) {
          this._root.flashMessage.showMessage({
            id: ADDRESS_NOT_ALLOWED_NOTIFICATION_ID,
            variant: 'danger',
            title: this._root.localization.executeTemplate(errorKey, {
              x: `${data.mintrans}`,
            }),
            timeout: ADDRESS_NOT_ALLOWED_NOTIFICATION_TIMEOUT,
          });
        }
      }

      return failure(
        this._root.errorRepository.create({
          kind: UNKNOWN_ERROR,
          raw: res.left.description,
        }),
      );
    }

    return res;
  }

  async updateAddress(
    walledId: WalletId,
    address: CryptoAddress,
    description?: string,
  ) {
    const res = await this._root.ncWalletJsonRpcClient.call(
      'wallets.addresses.update',
      {description, address},
    );
    if (res.success) {
      this.updateSavedAddressDescription(walledId, res.right);
    }

    return res;
  }

  @action.bound
  private updateSavedWallet(wallet: Wallet) {
    this._wallets = this._wallets?.map(w => (w.id === wallet.id ? wallet : w));
  }

  private updateSavedAddressDescription(
    walletId: WalletId,
    address: AddressInfo,
  ) {
    const wallet = this.getWalletById(walletId);

    if (!wallet) {
      return;
    }

    const updated: Wallet = {
      ...wallet,
      addresses: wallet.addresses.map(a =>
        a.address === address.address
          ? {...a, description: address.description}
          : a,
      ),
    };

    this.updateSavedWallet(updated);
  }

  private _updateBalanceOnNotification() {
    return this._root.ncWalletJsonRpcServer.notification(
      'event',
      async (params, response, next) => {
        if (params.type === 'wallet_balance_update') {
          const wallet = this.getWalletById(params.data.wallet_id);
          if (wallet) {
            this.updateSavedWallet({
              ...wallet,
              total: params.data.total,
            });
          } else {
            await this.refreshWallets();
          }
          this._events.send(WALLET_BALANCE_UPDATE);
          return;
        }
        next();
      },
    );
  }

  @action.bound
  reset() {
    this._wallets = undefined;
    this._isLoading = false;
    this._isLoaded = false;
  }

  private _createCancellationError(cause?: unknown) {
    return this._root.errorRepository.create<CancellationError>({
      kind: CANCELLATION_ERROR,
      raw: cause,
    });
  }

  subscribe() {
    return batchDisposers(this._updateBalanceOnNotification());
  }
}
