import {Portal} from '@gorhom/portal';
import {useRoot} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React from 'react';

import LargeFinalBuyMessage from './LargeFinalBuyMessage';
import SmallFinalBuyMessage from './SmallFinalBuyMessage';

export type FinalBuyMessageProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

export default observer(function FinalBuyMessage({
  onConfirm,
  onCancel,
}: FinalBuyMessageProps) {
  const {layoutHelperState} = useRoot();

  const isLarge = layoutHelperState.isLarge;
  return (
    <Portal>
      {isLarge ? (
        <LargeFinalBuyMessage onConfirm={onConfirm} onCancel={onCancel} />
      ) : (
        <SmallFinalBuyMessage onConfirm={onConfirm} onCancel={onCancel} />
      )}
    </Portal>
  );
});
