import {last} from '../../util';
import type {
  BaseFilterParams,
  BaseSendParams,
  ExchangeReceipt,
  ExchangeSummary,
  FeeParams,
  ShallowCommonState,
} from '../CommonState';
import {
  GENERATE_PAY_ME_LINK_ROUTE,
  IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE,
  LIST_HISTORY_FILTERS_ROUTE,
  LIST_HISTORY_ROUTE,
  LIST_INPUT_ADDRESSES_ROUTE,
  LIST_MENU_CATEGORIES_ROUTE,
  LIST_NOTIFICATIONS_ROUTE,
  LIST_QR_CODE_HISTORY_ROUTE,
  LIST_SECURITY_SETTINGS_ROUTE,
  NOTIFY_ABOUT_SUCCESSFUL_EXCHANGE_ROUTE,
  NOTIFY_ABOUT_SUCCESSFUL_SENDING_ROUTE,
  PROMPT_ACCOUNT_DELETION_ROUTE,
  PROMPT_ADD_IP_ROUTE,
  PROMPT_ADDRESS_FORMAT_ROUTE,
  PROMPT_AFFILIATE_PROGRAM_ROUTE,
  PROMPT_AMOUNT_TO_SEND_ROUTE,
  PROMPT_BUY_CRYPTO_ROUTE,
  PROMPT_COMMENT_TO_SEND_ROUTE,
  PROMPT_COMMISSION_FOR_SENDING_ROUTE,
  PROMPT_CONFIRMATION_FOR_EXCHANGE_ROUTE,
  PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE,
  PROMPT_CRYPTO_TO_EXCHANGE_ROUTE,
  PROMPT_CRYPTO_TO_RECEIVE_ROUTE,
  PROMPT_CRYPTO_TO_SEND_ROUTE,
  PROMPT_DATE_OF_HISTORY_PERIOD_ROUTE,
  PROMPT_DOCUMENT_ROUTE,
  PROMPT_EXCHANGE_RECEIPT_ROUTE,
  PROMPT_FEEDBACK_ROUTE,
  PROMPT_INPUT_NETWORK_ROUTE,
  PROMPT_LANGUAGE_ROUTE,
  PROMPT_LIMIT_REMOVAL_ROUTE,
  PROMPT_LIMIT_UPDATE_ROUTE,
  PROMPT_NEW_LIMIT_ROUTE,
  PROMPT_NEW_WALLET_ROUTE,
  PROMPT_OTP_TO_ADD_IP_ROUTE,
  PROMPT_OTP_TO_DELETE_IP_ROUTE,
  PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE,
  PROMPT_OTP_TO_EXCHANGE_ROUTE,
  PROMPT_OTP_TO_LIMIT_CREATE_ROUTE,
  PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE,
  PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE,
  PROMPT_OTP_TO_SEND_CRYPTO_ROUTE,
  PROMPT_OTP_TO_UPDATE_IP_ROUTE,
  PROMPT_OUTPUT_ADDRESS_ROUTE,
  PROMPT_OUTPUT_NETWORK_ROUTE,
  PROMPT_PERIOD_TO_FILTER_HISTORY_ROUTE,
  PROMPT_RATE_ROUTE,
  PROMPT_RECEIVE_NETWORK_ROUTE,
  PROMPT_SESSION_DELETION,
  PROMPT_SIGN_OUT_ROUTE,
  PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE,
  PROMPT_THEME_ROUTE,
  PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE,
  PROMPT_TO_DELETE_IP_ROUTE,
  PROMPT_UPDATE_IP_ROUTE,
  PROMPT_WALLET_TO_FILTER_HISTORY_ROUTE,
  REDIRECT_TO_SEND_ROUTE,
  SHOW_LIMIT_ROUTE,
  SHOW_QR_CODE_SCANNER_ROUTE,
  SHOW_QR_ROUTE,
  SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE,
  SHOW_TRANSACTION_REPORT_ROUTE,
  SHOW_TRANSACTION_ROUTE,
  SHOW_TWO_FA_GENERATED_SECRET_ROUTE,
  SHOW_WALLET_ROUTE,
} from '../CommonState';
import type {Path} from '../Path';
import {PathImpl, pushPathToChain} from '../Path';
import type {LoosePathParams} from '../Path/PathImpl';
import routePathMap from './routePathMap';

export default function stateToPath(state: ShallowCommonState): Path {
  const {route: top, path} = last(state);
  if (path !== undefined) {
    return path;
  }
  const pathname: [string] = [routePathMap[top.kind]];
  switch (top.kind) {
    case REDIRECT_TO_SEND_ROUTE: {
      const _pathname = [...pathname, top.params.address];
      const {amount, crypto, addressNetwork} = top.params;
      if (crypto !== undefined) {
        _pathname.push(crypto);
      }
      if (amount !== undefined) {
        _pathname.push(amount);
      }
      if (addressNetwork !== undefined) {
        _pathname.push(addressNetwork);
      }
      return new PathImpl(_pathname);
    }
    case LIST_MENU_CATEGORIES_ROUTE:
    case PROMPT_THEME_ROUTE:
    case PROMPT_LANGUAGE_ROUTE:
    case LIST_NOTIFICATIONS_ROUTE:
    case PROMPT_DOCUMENT_ROUTE:
    case PROMPT_ACCOUNT_DELETION_ROUTE:
    case SHOW_QR_CODE_SCANNER_ROUTE:
    case PROMPT_AFFILIATE_PROGRAM_ROUTE:
    case PROMPT_SIGN_OUT_ROUTE:
    case PROMPT_RATE_ROUTE: {
      const current = new PathImpl(pathname);
      return pushToChainIfPossible(current, state);
    }
    case LIST_SECURITY_SETTINGS_ROUTE:
    case SHOW_TWO_FA_GENERATED_SECRET_ROUTE:
      return new PathImpl(pathname, {
        onEnabled: top.params?.onTwoFaEnabled?.toString(),
        c: top.params?.code?.toString(),
      });
    case PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE:
      return new PathImpl(pathname, {
        onEnabled: top.params.onTwoFaEnabled?.toString(),
        s: top.params.secret,
      });
    case PROMPT_CRYPTO_TO_SEND_ROUTE:
      return new PathImpl(pathname, {
        s: top.params?.search,
        ato: top.params?.addressTo,
      });
    case PROMPT_NEW_WALLET_ROUTE:
    case PROMPT_BUY_CRYPTO_ROUTE:
    case PROMPT_CRYPTO_TO_RECEIVE_ROUTE:
    case PROMPT_SOURCE_CRYPTO_TO_EXCHANGE_ROUTE:
    case PROMPT_WALLET_TO_FILTER_HISTORY_ROUTE:
      return new PathImpl(pathname, {s: top.params?.search});
    case SHOW_WALLET_ROUTE:
      return new PathImpl([...pathname, top.params.walletId], {
        c: top.params.currencyCode,
        p: top.params.period,
      });
    case PROMPT_ADDRESS_FORMAT_ROUTE: {
      const current = new PathImpl([...pathname, top.params.walletId]);
      return pushToChainIfPossible(current, state);
    }
    case PROMPT_RECEIVE_NETWORK_ROUTE: {
      return new PathImpl([...pathname, top.params.walletId], {
        a: top.params.address,
        c: top.params.addressCurrency,
        net: top.params.addressNetwork,
        snet: top.params.networkFilter,
        sc: top.params.currencyFilter,
      });
    }
    case LIST_INPUT_ADDRESSES_ROUTE:
      return new PathImpl([...pathname, top.params.walletId], {
        a: top.params.address,
        net: top.params.addressNetwork,
        c: top.params.addressCurrency,
        snet: top.params.networkFilter,
        sc: top.params.currencyFilter,
      });
    case PROMPT_INPUT_NETWORK_ROUTE:
      return new PathImpl([...pathname, top.params.walletId]);
    case SHOW_QR_TO_RECEIVE_CRYPTO_ROUTE:
      return new PathImpl([...pathname, top.params.walletId], {
        a: top.params.address,
        c: top.params.addressCurrency,
        net: top.params.addressNetwork,
      });
    case PROMPT_SESSION_DELETION:
      return new PathImpl(pathname, {
        sessionId: top.params.sessionId,
      });

    case PROMPT_OUTPUT_ADDRESS_ROUTE:
      return new PathImpl([...pathname, top.params.walletId], {
        ato: top.params.addressTo,
        c: top.params.addressCurrency,
        sum: top.params.amount,
        mes: top.params.comment,
        net: top.params.addressNetwork,
        mfwa: top.params.minFreeWithdrawAmount,
        edit: translateBoolean(top.params.isEditable),
        ...translateFeeParams(top.params),
      });
    case PROMPT_COMMENT_TO_SEND_ROUTE:
      return new PathImpl([...pathname, top.params.walletId], {
        ato: top.params.addressTo,
        sum: top.params.amount,
        mes: top.params.comment,
        ...translateBaseSendParams(top.params),
        ...translateFeeParams(top.params),
      });
    case PROMPT_AMOUNT_TO_SEND_ROUTE:
    case PROMPT_OUTPUT_NETWORK_ROUTE:
      return new PathImpl([...pathname, top.params.walletId], {
        ato: top.params.addressTo,
        sum: top.params.amount,
        edit: translateBoolean(top.params.isEditable),
        ...translateBaseSendParams(top.params),
        ...translateFeeParams(top.params),
      });
    case SHOW_QR_ROUTE: {
      return new PathImpl([...pathname, top.params.walletId], {
        a: top.params.address,
        net: top.params.addressNetwork,
        c: top.params.addressCurrency,
      });
    }
    case GENERATE_PAY_ME_LINK_ROUTE: {
      return new PathImpl(pathname, {
        amount: top.params.amount?.toString() || '',
        address: top.params.address,
        currency: top.params.currency,
      });
    }
    case PROMPT_COMMISSION_FOR_SENDING_ROUTE:
      return new PathImpl([...pathname, top.params.walletId], {
        fee: top.params.fee,
        edit: translateBoolean(top.params.isEditable),
        ato: top.params.addressTo,
        sum: top.params.amount,
        com: translateBoolean(top.params.hasCommission),
        mes: top.params.comment,
        ...translateBaseSendParams(top.params),
      });
    case PROMPT_CONFIRMATION_TO_SEND_CRYPTO_ROUTE:
      return new PathImpl([...pathname, top.params.walletId], {
        ato: top.params.addressTo,
        sum: top.params.amount,
        mes: top.params.comment,
        ...translateBaseSendParams(top.params),
        ...translateFeeParams(top.params),
      });
    case PROMPT_OTP_TO_SEND_CRYPTO_ROUTE:
      return new PathImpl([...pathname, top.params.withdrawId], {
        ato: top.params.addressTo,
        sum: top.params.amount,
        ccc: top.params.currency,
      });
    case NOTIFY_ABOUT_SUCCESSFUL_SENDING_ROUTE:
      return new PathImpl(pathname, {
        ato: top.params.addressTo,
        sum: top.params.amount,
        ccc: top.params.currency,
        e: top.params.error,
      });
    case PROMPT_EXCHANGE_RECEIPT_ROUTE:
      return new PathImpl(
        [...pathname, top.params.walletIdFrom],
        translateExchangeReceipt(top.params),
      );
    case PROMPT_OTP_TO_DELETE_IP_ROUTE:
      return new PathImpl(pathname, {
        id: top.params.id,
        type: top.params.type,
      });
    case PROMPT_TO_DELETE_IP_ROUTE:
      return new PathImpl(pathname, {
        id: top.params.id,
        ip: top.params.ip,
        type: top.params.type,
      });
    case PROMPT_ADD_IP_ROUTE:
      return new PathImpl(pathname, {
        type: top.params.type,
      });
    case PROMPT_OTP_TO_ADD_IP_ROUTE:
      return new PathImpl(pathname, {
        ip: top.params.ip,
        n: top.params.name,
        type: top.params.type,
      });
    case PROMPT_UPDATE_IP_ROUTE:
    case PROMPT_OTP_TO_UPDATE_IP_ROUTE:
      return new PathImpl(pathname, {
        id: top.params.id,
        ip: top.params.ip,
        n: top.params.name,
        type: top.params.type,
      });
    case PROMPT_CRYPTO_TO_EXCHANGE_ROUTE:
      return new PathImpl([...pathname, top.params.walletIdFrom], {
        src: translateBoolean(top.params.isSourceRequested),
        disabled: top.params.disabledCrypto,
        s: top.params.search,
        ...translateExchangeReceipt(top.params),
      });
    case PROMPT_OTP_TO_EXCHANGE_ROUTE:
    case PROMPT_CONFIRMATION_FOR_EXCHANGE_ROUTE:
      return new PathImpl(pathname, {
        token: top.params.token,
        r: top.params.exchangeRate,
        d: top.params.date.toString(36),
        fee: top.params.fee,
        wFrom: top.params.walletIdFrom,
        wTo: top.params.walletIdTo,
        isT: translateBoolean(top.params.isTargetValueLastChanged),
        ...translateExchangeSummary(top.params),
      });
    case NOTIFY_ABOUT_SUCCESSFUL_EXCHANGE_ROUTE:
      return new PathImpl(pathname, translateExchangeSummary(top.params));
    case LIST_HISTORY_ROUTE:
      return new PathImpl(pathname, {
        wId: top.params?.walletId,
        ...translateBaseFilterParams(top.params),
      });
    case IDENTITY_VERIFICATION_UPLOAD_DOCUMENT_ROUTE:
      return new PathImpl(pathname, {
        type: top.params.type,
      });
    case PROMPT_TO_DELETE_DOCUMENT_FILE_ROUTE:
      return new PathImpl(pathname, {
        documentId: top.params.documentId,
        file: top.params.file,
      });
    case LIST_HISTORY_FILTERS_ROUTE:
      return new PathImpl(pathname, {
        wid: top.params?.walletId,
        ...translateBaseFilterParams(top.params),
      });
    case PROMPT_PERIOD_TO_FILTER_HISTORY_ROUTE:
      return new PathImpl(pathname, {
        from: top.params?.from?.toString(36),
        to: top.params?.to?.toString(36),
      });
    case PROMPT_DATE_OF_HISTORY_PERIOD_ROUTE: {
      const current = new PathImpl(pathname, {
        s: translateBoolean(top.params.isSourceRequested),
        value: top.params.value?.toString(36),
      });
      return pushToChainIfPossible(current, state);
    }
    case SHOW_TRANSACTION_ROUTE:
      return new PathImpl([...pathname, top.params.id]);
    case SHOW_TRANSACTION_REPORT_ROUTE:
      return new PathImpl(pathname, {
        id: top.params.id,
      });
    case PROMPT_FEEDBACK_ROUTE: {
      const current = new PathImpl(pathname, {
        r: top.params.rating.toString(10),
      });
      return pushToChainIfPossible(current, state);
    }
    case LIST_QR_CODE_HISTORY_ROUTE:
      return new PathImpl(pathname, {
        address: top.params?.address,
        error: top.params?.error?.toString(10),
      });
    case SHOW_LIMIT_ROUTE:
      return new PathImpl(pathname, {
        w: top.params.walletId,
        p: top.params.period,
      });
    case PROMPT_NEW_LIMIT_ROUTE:
      return new PathImpl(pathname, {
        w: top.params.walletId,
        c: top.params.currency,
        p: top.params.period,
      });

    case PROMPT_OTP_TO_LIMIT_CREATE_ROUTE:
    case PROMPT_OTP_TO_LIMIT_UPDATE_ROUTE:
      return new PathImpl(pathname, {
        w: top.params.walletId,
        p: top.params.period,
        l: top.params.limit,
      });

    case PROMPT_LIMIT_UPDATE_ROUTE: {
      return new PathImpl(pathname, {
        w: top.params.walletId,
        p: top.params.period,
        l: top.params.limit,
        c: top.params.currency,
      });
    }
    case PROMPT_OTP_TO_LIMIT_REMOVAL_ROUTE:
    case PROMPT_LIMIT_REMOVAL_ROUTE:
      return new PathImpl(pathname, {
        id: top.params.limitId,
        a: top.params.action.toString(10),
      });
  }
  return new PathImpl(pathname);
}

function pushToChainIfPossible(current: Path, state: ShallowCommonState): Path {
  if (state.length < 2) {
    return current;
  }
  const previous = stateToPath(state.slice(0, -1) as ShallowCommonState);
  return pushPathToChain(current, previous);
}

function translateBoolean(_: boolean | undefined): null | '0' | undefined {
  return _ === undefined ? _ : _ ? null : '0';
}

function translateFeeParams(_: FeeParams): LoosePathParams {
  return {
    fee: _.fee,
    fixed: translateBoolean(_.feeChangeDisabled),
  };
}

function translateBaseSendParams(_: BaseSendParams): LoosePathParams {
  return {
    ccc: _.currency,
    net: _.addressNetwork,
    ac: _.addressCurrency,
    mfwa: _.minFreeWithdrawAmount,
  };
}

function translateExchangeReceipt(_: ExchangeReceipt): LoosePathParams {
  return {
    to: _.walletIdTo,
    cto: _.currencyTo,
    v: _.value,
    t: translateBoolean(_.isTargetValue),
  };
}

function translateExchangeSummary(_: ExchangeSummary): LoosePathParams {
  return {
    vFrom: _.valueFrom,
    vTo: _.valueTo,
    cFrom: _.currencyFrom,
    cTo: _.currencyTo,
  };
}

function translateBaseFilterParams(
  _?: BaseFilterParams,
): LoosePathParams | undefined {
  return (
    _ && {
      from: _.from?.toString(36),
      to: _.to?.toString(36),
      kind: _.transactionFilterKind,
    }
  );
}
