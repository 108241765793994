import {BottomSheet} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import type {FinalBuyMessageProps} from './FinalBuyMessage';
import FinalMessageContent from './FinalMessageContent';

export type SmallFinalBuyMessageProps = FinalBuyMessageProps;

export default observer(function FinalBuyMessage({
  onConfirm,
  onCancel,
}: SmallFinalBuyMessageProps) {
  const onChange = useCallback(
    (index: number) => {
      if (index === -1) {
        onCancel();
      }
    },
    [onCancel],
  );

  return (
    <BottomSheet
      index={0}
      onChange={onChange}
      animateOnMount
      enablePanDownToClose
      enableDynamicSizing
      enableAutoHeight
      backdropProps={{appearsOnIndex: 0, disappearsOnIndex: -1}}
      onClose={onCancel}
      children={
        <FinalMessageContent onConfirm={onConfirm} onCancel={onCancel} />
      }
    />
  );
});
