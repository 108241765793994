import type {AvailableLanguageCode} from '../Localization/constant';
import type {Tagged} from '../Opaque';
import type {Second} from '../Time';
import type {Uri, Url} from '../units';

export enum AdvertType {
  Inapp = 'inapp',
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export type Advert = {
  is_i18n: boolean;
  id: AdvertId;
  project_id: string;
  spot: AdvertSpot;
  type: AdvertType;
  size: AdvertSize;
  title: string | Dictionary;
  body: string | Dictionary;
  icon: ImageBySize;
  image: ImageBySize;
  html: null;
  link?: Url | null;
  options?: AdvertOptions | null;
  actions?: AdvertAction[];
};

export type AdvertOptions = {
  can_close?: boolean | null;
  close_time?: Second | null;
  force?: boolean | null;
  can_exclude?: boolean | null;
  ttl?: Second | null;
};

export type AdvertAction = {
  title: string | Dictionary;
  action: AdvertActionId;
  link?: Url | null;
  icon?: Uri | null;
};

export type Dictionary = {
  [P in AvailableLanguageCode]?: string;
} & Partial<Record<string, string>>;

export type ImageBySize = {
  [P in AdvertSize]?: Uri;
};

export type AdvertSpot =
  | 'splash_mobile'
  | 'splash_desktop'
  | 'new_modal_mobile'
  | 'new_modal_desktop'
  | 'install_native_mobile'
  | 'install_native_desktop'
  | 'install_telegram_mobile'
  | 'install_telegram_desktop';

export type AdvertSize = 'xs' | 'm' | 'l' | 'xl';

export const ADVERT_ID = Symbol();
export type AdvertId = Tagged<string, typeof ADVERT_ID>;

export const ADVERT_ACTION_ID = Symbol();
export type AdvertActionId = Tagged<string, typeof ADVERT_ACTION_ID>;
