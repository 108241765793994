import {type CryptoCurrencyCode, FULFILLED, useRoot} from '@ncwallet-app/core';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {noop} from 'lodash';
import {observable, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useLayoutEffect, useState} from 'react';

import {useSelectionForBuyCryptoBindingState} from '../../../../../Navigation/HomeStack/SelectionForBuyCryptoBinding/useSelectionForBuyCryptoBindingState';
import {filterCurrencySelectionItems} from '../../../../../Navigation/hooks';
import {EXTRA_BOTTOM_OFFSET} from '../../../../../screens/constants';
import {
  ExtraDetailsComponent,
  SelectionForAddScreen,
} from '../../../../../screens/SelectionForAddScreen';
import {ScreenType} from '../../../../../screens/SelectionForExchangeScreen/ScreenType';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type PromptBuyCryptoBindingProps =
  LargeSwitchBindingProps<'PromptBuyCrypto'>;

export default observer(function PromptBuyCryptoBinding(
  props: PromptBuyCryptoBindingProps,
) {
  const {route, navigation} = props;
  const root = useRoot();
  const state = useSelectionForBuyCryptoBindingState();

  const [searchBox] = useState(() =>
    observable.box<string | undefined>(route.params?.search),
  );

  useLayoutEffect(() => {
    runInAction(() => {
      searchBox.set(route.params?.search);
    });
  }, [searchBox, route.params?.search]);
  const getSearch = useCallback(() => searchBox.get(), [searchBox]);
  const setSearch = useCallback(
    (search: string) => {
      navigation.setParams({search});
    },
    [navigation],
  );

  const onRefresh = useCallback(async () => {
    return Promise.all([
      (async () => {
        if (root.accountStore.state?.status !== FULFILLED) {
          return;
        }
        return state.refresh(root.accountStore.state.result.base_fiat);
      })(),
    ]);
  }, [root, state]);

  const onCurrencyPress = useCallback(
    async (item: CurrencySelectionListItemData) => {
      if (!state.isSubmitting()) {
        await state.createWalletIfNeeded(item);

        const addressParams = state.getAddressParamsByCurrencyCode(
          item.cryptoCurrency.code as CryptoCurrencyCode,
        );

        if (addressParams) {
          navigation.navigate('ShowQrToReceiveCrypto', addressParams);
        }
      }
    },
    [navigation, state],
  );

  const handleBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    } else {
      navigation.replace('Root');
    }
  }, [navigation]);

  const getFilteredList = useCallback(() => {
    return filterCurrencySelectionItems(state.currencies, searchBox.get());
  }, [searchBox, state.currencies]);

  return (
    <SelectionForAddScreen
      currencyListTitle={root.localization.getTranslation('buyCrypto.title')}
      screenType={ScreenType.Modal}
      getIsLoading={state.isLoading}
      onRefresh={onRefresh}
      getCurrencies={state.getCurrencies}
      getShownCurrencies={getFilteredList}
      onBack={handleBack}
      onCurrencyPress={onCurrencyPress}
      getSearch={getSearch}
      setSearch={setSearch}
      goToReceive={noop}
      goToExchange={noop}
      goToSend={noop}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      ExtraDetailsComponent={<ExtraDetailsComponent />}
    />
  );
});
