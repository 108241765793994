import type {
  CryptoCurrencyCode,
  CurrenciesRateHistoryStore,
  CurrenciesRateStore,
  CurrencyCode,
  CurrencyStore,
  DecimalString,
  FiatCurrencyCode,
  GlobalError,
  ISODateString,
  JsonRpcClient,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  OnTimeoutError,
  RateValue,
  Wallet,
  WalletId,
  WalletStore,
} from '@ncwallet-app/core';
import {
  compact,
  getHistoryItemChange,
  getMaxAmountForExchange,
  isEnoughMoneyForSendOrExchange,
  maxAmountRestrictedByLimit,
  MoneyStatic,
  RateHistoryPeriod,
  TIMEOUT_ERROR,
  walletsByCurrencyPopularitySorter,
} from '@ncwallet-app/core';
import {getHistoryFromResponse} from '@ncwallet-app/core/src/dataStores/CurrenciesRateHistoryStore/AcceptedRateHistoryResponse';
import type {ErrorParser} from '@ncwallet-app/core/src/ErrorParser';
import type {CryptoCurrency} from '@ncwallet-app/core/src/NCWalletServer/CryptoCurrency';
import type {TransactionCreateErrorData} from '@ncwallet-app/core/src/NCWalletServer/WalletsTransactionsExchangeTokenCreate';
import type {SentryLog} from '@ncwallet-app/core/src/SentryLog';
import createJsonRpcTimeoutErrorMessage from '@ncwallet-app/core/src/SentryLog/createJsonRpcTimeoutErrorMessage';
import {BigNumber} from 'bignumber.js';
import {debounce, isNil} from 'lodash';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import type {CurrencyHistoryRefresher} from '../../../shared/CurrencyHistoryRefresher';
import type {ExchangeFormClientSideValidator} from './ExchangeFormClientSideValidator';
import type {WalletLimitHelper} from './WalletLimitHelper';

export enum ValueKind {
  From,
  To,
}

export class ExchangeFormBindingState implements OnTimeoutError {
  private static TOKEN_REQUEST_DEBOUNCE_FOR_FROM_VALUE = 300;
  // обновление данных полей мешает вводу в поле to,
  // debounce увеличен, чтобы избежать этого
  private static TOKEN_REQUEST_DEBOUNCE_FOR_TO_VALUE = 1200;

  @observable private _walletFrom: Wallet | undefined;
  @observable errorAction: (() => void) | undefined;
  @observable minAmount: string | undefined;
  @observable lastExchangeToken: string | undefined;

  // Перевод денег на несуществующий у пользователя кошелек возможeн
  // (кошелек будет создан при переводе)
  @observable private _walletTo?: Wallet | undefined;
  @observable private _currencyTo: CryptoCurrencyCode | undefined;

  @observable private _exchangeRate:
    | RateValue<CurrencyCode, CurrencyCode>
    | undefined;

  @observable private _isExchangeRateLoaded = false;
  @observable private _isLoading = false;

  @observable private _valueTo: DecimalString | undefined;
  @observable private _valueFrom: DecimalString | undefined;

  // flushed after  input
  @observable private _backendError: string | undefined;
  @observable private isSubmitting = false;
  @observable private isSubmitted = false;

  _lastChangedByUserValue: ValueKind = ValueKind.From;
  @observable private _baseFiat: FiatCurrencyCode | undefined;

  @observable private _isTimeoutError = false;

  @computed get isTimeoutError() {
    return this._isTimeoutError;
  }

  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
      readonly currenciesRateStore: CurrenciesRateStore;
      readonly currenciesRateHistoryStore: CurrenciesRateHistoryStore;
      readonly errorParser: ErrorParser;
      readonly sentryLog: SentryLog;
    },
    private readonly _historyRefresher: CurrencyHistoryRefresher,
    private readonly _walletLimitHelper: WalletLimitHelper,
    private readonly _clientSideValidator: ExchangeFormClientSideValidator,
  ) {
    makeObservable(this);
  }

  @computed get isLoading() {
    return this._isLoading;
  }

  @computed get currencyFrom() {
    return this._walletFrom?.currency;
  }

  @computed get isExchangeRateLoaded() {
    return this._isExchangeRateLoaded;
  }

  @computed get maxValueFrom() {
    return (
      this._walletFrom &&
      getMaxAmountForExchange(this._walletFrom, this._remainingLimit)
    );
  }

  @computed get maxRestrictedByWalletLimit() {
    return (
      !this._walletFrom ||
      maxAmountRestrictedByLimit(this._walletFrom, this._remainingLimit)
    );
  }

  @computed
  private get _remainingLimit() {
    return (
      this._walletFrom &&
      this._walletLimitHelper.getCurrentRemainingLimit(this._walletFrom.id)
    );
  }

  @computed get currencyTo() {
    return this._currencyTo;
  }

  @computed get walletIdFrom() {
    return this._walletFrom?.id;
  }

  @computed get walletIdTo() {
    return this._walletTo?.id;
  }

  @computed get maxValueTo() {
    return isNil(this.maxValueFrom) || isNil(this._exchangeRate)
      ? undefined
      : MoneyStatic.convert(this.maxValueFrom, this._exchangeRate);
  }

  @computed get exchangeRate() {
    return this._exchangeRate;
  }

  @computed get valueFrom() {
    return this._valueFrom;
  }

  @computed get valueTo() {
    return this._valueTo;
  }

  @computed get baseFiat() {
    return this._baseFiat;
  }

  @computed get isGraphsDataLoaded(): boolean {
    return (
      !!this.currencyFromRateHistory &&
      !!this.currencyToRateHistory &&
      !isNil(this.currencyFromToFiatRate) &&
      !isNil(this.currencyToToFiatRate)
    );
  }

  @computed get currencyFromRateHistory() {
    return this.getHistory(this.currencyFrom);
  }

  @computed get currencyToRateHistory() {
    return this.getHistory(this.currencyTo);
  }

  @computed get currencyFromDiff() {
    return this.getChartDiff(this.currencyFrom);
  }

  @computed get currencyToDiff() {
    return this.getChartDiff(this.currencyTo);
  }

  @computed get currencyFromToFiatRate() {
    return this.getRateToFiat(this.currencyFrom);
  }

  @computed get currencyToToFiatRate() {
    return this.getRateToFiat(this.currencyTo);
  }

  @action.bound
  async updateParams(
    walletIdFrom: WalletId,
    walletIdTo: WalletId | undefined,
    currencyTo: CryptoCurrencyCode | undefined,
    baseFiat: FiatCurrencyCode,
    value?: DecimalString,
    isValueTo?: boolean,
  ) {
    if (
      this._isSameParams(
        walletIdFrom,
        walletIdTo,
        currencyTo,
        baseFiat,
        value,
        isValueTo,
      )
    ) {
      return;
    }

    this._isLoading = true;
    this._baseFiat = baseFiat;
    try {
      await this.baseRefresh(
        walletIdFrom,
        walletIdTo,
        currencyTo,
        value,
        isValueTo,
      );
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  isLastChangedFieldTo() {
    return this._lastChangedByUserValue === ValueKind.To;
  }

  @action.bound
  async submit(): Promise<boolean> {
    this.isSubmitted = true;
    if (this.isSubmitting || this._hasError()) {
      return false;
    }

    this.isSubmitting = true;
    if (!this.lastExchangeToken || this._hasError()) {
      this.isSubmitting = false;
      return false;
    }
    this.isSubmitting = false;

    return !this._hasError();
  }

  private _hasError(): boolean {
    return !!this._backendError || this._clientSideValidator.hasError(this);
  }

  @action.bound
  changeValueFrom(valueFrom: DecimalString | undefined) {
    this._backendError = undefined;
    this.minAmount = undefined;
    this.isSubmitted = false;
    void this.valueChanged(valueFrom, ValueKind.From);
  }

  @action.bound
  changeValueTo(valueTo: DecimalString | undefined) {
    this._backendError = undefined;
    this.minAmount = undefined;
    this.isSubmitted = false;
    void this.valueChanged(valueTo, ValueKind.To);
  }

  isExchangeEnabled(): boolean {
    return !(this.isSubmitted && this.getShownError());
  }

  isSwapCurrenciesEnabled(): boolean {
    return (
      !this._isLoading &&
      !!this._walletTo &&
      isEnoughMoneyForSendOrExchange(this._walletTo)
    );
  }

  @action.bound
  swapCurrencies() {
    const walletFrom = this._walletFrom;
    this._walletFrom = this._walletTo;
    this._walletTo = walletFrom;
    this._currencyTo = this._walletTo?.currency;
    this.isSubmitted = false;
    this._backendError = undefined;
    this.minAmount = undefined;
    this._swapValues();
    void this.refreshExchangeRate();
  }

  getShownError(): string | undefined {
    if (this._backendError) {
      return this._backendError;
    } else if (this.isSubmitted) {
      return this._clientSideValidator.validateAfterSubmit(this);
    } else {
      return this._clientSideValidator.validateOnChange(this);
    }
  }

  hasValueFromError(): boolean {
    return (
      this._lastChangedByUserValue === ValueKind.From && !!this.getShownError()
    );
  }

  hasValueToError(): boolean {
    return (
      this._lastChangedByUserValue === ValueKind.To && !!this.getShownError()
    );
  }

  async activateRateHistoryLoad() {
    this._historyRefresher.activate();
  }

  deactivateRateHistoryLoad() {
    this._historyRefresher.deactivate();
  }

  @action.bound
  async refreshExchangeRate() {
    const currencyFrom = this.currencyFrom;
    const currencyTo = this.currencyTo;
    if (currencyFrom === undefined || currencyTo === undefined) {
      return;
    }

    this.lastExchangeToken = undefined;
    const rateRes = await this._root.currenciesRateStore.refreshRate(
      currencyFrom,
      currencyTo,
    );
    if (!rateRes.success) {
      this._isExchangeRateLoaded = true;
      return;
    }

    runInAction(() => {
      this._exchangeRate = rateRes.right.rate;
      this._isExchangeRateLoaded = true;
      this._historyRefresher.setStaleCodes(
        compact([this.currencyFrom, this.currencyTo]),
      );
      this.updateValuesAfterRateAndMaxValueUpdate();
    });
  }

  private _isSameParams(
    walletIdFrom: WalletId,
    walletIdTo: WalletId | undefined,
    currencyTo: CryptoCurrencyCode | undefined,
    baseFiat: FiatCurrencyCode,
    value?: DecimalString,
    isValueTo?: boolean,
  ) {
    const isSameWalletTo =
      (this.walletIdTo && this.walletIdTo === walletIdTo) ||
      (this.currencyTo && this.currencyTo === currencyTo);

    const valueKind = isValueTo ? ValueKind.To : ValueKind.From;
    const isSameValue = value === this.getValueByKind(valueKind);
    return (
      this.baseFiat === baseFiat &&
      this.walletIdFrom &&
      this.walletIdFrom === walletIdFrom &&
      isSameWalletTo &&
      isSameValue
    );
  }

  @action.bound
  private async valueChanged(
    value: DecimalString | undefined,
    kind: ValueKind,
  ) {
    const n = value && BigNumber(value);
    if (n && (!n.isFinite() || n.comparedTo(0) === -1)) {
      return;
    }

    this._lastChangedByUserValue = kind;
    this.lastExchangeToken = undefined;

    if (!n || n.comparedTo(0) === 0) {
      this._valueFrom = value;
      this._valueTo = value;
      return;
    }

    this.setFieldByKind(value, kind);

    if (this._clientSideValidator.hasError(this)) {
      if (kind === ValueKind.From) {
        this._valueTo = '0';
      } else {
        this._valueFrom = '0';
      }
      return;
    }

    if (kind === ValueKind.From) {
      return this.requestTokenThenSyncValueFieldsForFromValue(value, kind);
    } else {
      return this.requestTokenThenSyncValueFieldsForToValue(value, kind);
    }
  }

  private requestTokenThenSyncValueFields = async (
    value: DecimalString | undefined,
    kind: ValueKind,
  ) => {
    const from_wallet_id = this._walletFrom?.id;
    const to_wallet_id = this._walletTo?.id;
    const from_currency = this.currencyFrom;
    const to_currency = this.currencyTo;
    if (
      from_wallet_id === undefined ||
      from_currency === undefined ||
      to_currency === undefined
    ) {
      return;
    }
    const base = {
      from_wallet_id,
      to_wallet_id,
      from_currency,
      to_currency,
    };

    const reqParams =
      kind === ValueKind.From
        ? {...base, from_amount: value}
        : {...base, to_amount: value};

    const res = await this._root.ncWalletJsonRpcClient.call(
      'wallets.transactions.exchange_token.create',
      reqParams,
    );

    if (
      this._lastChangedByUserValue !== kind ||
      this.getValueByKind(kind) !== value
    ) {
      return;
    }

    runInAction(() => {
      this._isExchangeRateLoaded = true;
      if (this.errorAction || this._backendError) {
        this._clearAmountErrors();
      }
      if (res.success) {
        this._valueTo = res.right.to_amount;
        this._valueFrom = res.right.from_amount;
        this._exchangeRate = res.right.rate;
        this.lastExchangeToken = res.right.token;
      } else {
        if (res.left.kind === TIMEOUT_ERROR) {
          this._isTimeoutError = true;
          this._root.sentryLog.write(
            createJsonRpcTimeoutErrorMessage(
              '[wallets.transactions.exchange_token.create]',
              reqParams,
            ),
          );
        }
        this._setAmountError(res.left);
        this._backendError = this._root.errorParser.describe(res.left).summary;
        if (kind === ValueKind.From) {
          this._valueTo = '0';
        } else {
          this._valueFrom = '0';
        }
      }
    });
  };

  private _setAmountError = (error: GlobalError) => {
    const body = (Reflect.get(error, 'body') ?? {}) as object;
    const data = Reflect.get(body, 'data') as object | undefined;
    if (!data) {
      return;
    }
    const {min_from_amount, min_to_amount} = data as TransactionCreateErrorData;
    const minAmount = min_from_amount || min_to_amount;
    if (minAmount) {
      this.minAmount = minAmount;
      this.errorAction = () => {
        if (min_from_amount) {
          this.changeValueFrom(min_from_amount);
        } else {
          this.changeValueTo(min_to_amount);
        }
        this.minAmount = undefined;
      };
    }
  };

  private _clearAmountErrors = () => {
    this.minAmount = undefined;
    this.errorAction = undefined;
    this._backendError = undefined;
  };

  private requestTokenThenSyncValueFieldsForFromValue = debounce(
    this.requestTokenThenSyncValueFields,
    ExchangeFormBindingState.TOKEN_REQUEST_DEBOUNCE_FOR_FROM_VALUE,
  );

  private requestTokenThenSyncValueFieldsForToValue = debounce(
    this.requestTokenThenSyncValueFields,
    ExchangeFormBindingState.TOKEN_REQUEST_DEBOUNCE_FOR_TO_VALUE,
  );

  private async baseRefresh(
    walletIdFrom: WalletId,
    walletIdTo: WalletId | undefined,
    currencyTo: CryptoCurrencyCode | undefined,
    value?: DecimalString,
    isValueTo?: boolean,
  ) {
    this._lastChangedByUserValue = isValueTo ? ValueKind.To : ValueKind.From;
    this.setFieldByKind(value, this._lastChangedByUserValue);
    this.setFieldByKind(undefined, isValueTo ? ValueKind.From : ValueKind.To);

    const responses = await Promise.all([
      this._root.walletStore.refreshWallets(),
      this._root.currencyStore.refreshCryptoCurrencies(),
      this._walletLimitHelper.refresh(),
    ]);
    this._isTimeoutError = responses.some(
      it => !it.success && it.left.kind === TIMEOUT_ERROR,
    );

    const [walletsRes, cryptosResult, limitsResult] = responses;
    if (
      !walletsRes.success ||
      !cryptosResult.success ||
      !limitsResult.success
    ) {
      return;
    }

    const cryptos = cryptosResult.right;
    const popularityMap = new Map(cryptos.map((crypto, i) => [crypto.code, i]));
    const wallets = walletsRes.right
      .slice()
      .sort((w1, w2) =>
        walletsByCurrencyPopularitySorter(w1, w2, popularityMap),
      );

    runInAction(() => {
      this._walletFrom = wallets.find(w => w.id === walletIdFrom);
      if (!this._walletFrom) {
        this._isExchangeRateLoaded = true;
        throw new Error(`No wallet for walletIdFrom: ${walletIdFrom} `);
      }
      if (this.currencyFrom === undefined) {
        this._isExchangeRateLoaded = true;
        throw new Error(`No source currency`);
      }
      const [walletTo_, currencyTo_] = getWalletAndCurrencyTo(
        this.currencyFrom,
        walletIdTo,
        currencyTo,
        cryptos,
        wallets,
      );
      this._walletTo = walletTo_;
      this._currencyTo = currencyTo_;

      void this.refreshExchangeRate();
    });
  }

  private updateValuesAfterRateAndMaxValueUpdate() {
    const lastChangedValue = this.getValueByKind(this._lastChangedByUserValue);
    const maxValue = this.getMaxValueByKind(this._lastChangedByUserValue);
    const usedValue =
      !isNil(lastChangedValue) &&
      !isNil(maxValue) &&
      BigNumber(lastChangedValue).comparedTo(maxValue) === 1
        ? maxValue
        : lastChangedValue;

    void this.valueChanged(usedValue, this._lastChangedByUserValue);
  }

  private getValueByKind(kind: ValueKind) {
    return kind === ValueKind.From ? this.valueFrom : this.valueTo;
  }

  private getMaxValueByKind(kind: ValueKind) {
    return kind === ValueKind.From ? this.maxValueFrom : this.maxValueTo;
  }

  private setFieldByKind(value: DecimalString | undefined, kind: ValueKind) {
    if (kind === ValueKind.From) {
      this._valueFrom = value;
    } else {
      this._valueTo = value;
    }
  }

  private _swapValues() {
    const valueFrom = this.valueFrom;
    this._valueFrom = this.valueTo;
    this._valueTo = valueFrom;
    this._lastChangedByUserValue =
      this._lastChangedByUserValue === ValueKind.From
        ? ValueKind.To
        : ValueKind.From;
  }

  private getChartDiff(code: CryptoCurrencyCode | undefined) {
    const rate = this.getRateToFiat(code);
    const history = this.getHistory(code);
    return (
      history &&
      rate &&
      getHistoryItemChange(
        [...history, {rate: rate, timestamp: '' as ISODateString}],
        true,
      )
    );
  }

  private getHistory(code: CryptoCurrencyCode | undefined) {
    if (!code || !this._baseFiat) {
      return undefined;
    }

    const historyRes =
      this._root.currenciesRateHistoryStore.getLatestHistoryRes(
        code,
        this._baseFiat,
        RateHistoryPeriod.Month,
      );
    return historyRes && getHistoryFromResponse(historyRes);
  }

  private getRateToFiat(
    code: CryptoCurrencyCode | undefined,
  ): RateValue<CurrencyCode, CurrencyCode> | undefined {
    if (!code || !this._baseFiat) {
      return undefined;
    }
    return this._root.currenciesRateStore.getRate(code, this._baseFiat)?.rate;
  }
}

function getWalletAndCurrencyTo(
  currencyFrom: CryptoCurrencyCode,
  walletIdTo: WalletId | undefined,
  currencyTo: CryptoCurrencyCode | undefined,
  cryptos: CryptoCurrency[],
  wallets: Wallet[],
): [walletTo: Wallet | undefined, currencyTo: CryptoCurrencyCode] {
  if (walletIdTo) {
    const walletTo = wallets.find(w => w.id === walletIdTo);
    if (!walletTo) {
      throw new Error(`no wallet for walletIdFrom: ${walletIdTo} `);
    }
    return [walletTo, walletTo.currency];
  }
  if (currencyTo) {
    return [undefined, currencyTo];
  }

  const walletTo = wallets.find(w => w.currency !== currencyFrom);
  if (walletTo) {
    return [walletTo, walletTo.currency];
  }

  const currencyTo_ = cryptos.find(c => c.code !== currencyFrom)?.code;

  if (!currencyTo_) {
    throw new Error('no currency available for initial currencyTo');
  }
  return [undefined, currencyTo_];
}
