import type {JsonString} from '../Json';
import type {AdvertId} from '../NCWalletServer';
import type {Millisecond, Second} from '../Time';
import type {FilterOption} from '../WalletsStateService';
import type {DeviceRecord} from './DeviceRecord';

export type KeyValueMap = {
  [K in string]: string;
} & {
  /**
   * @deprecated
   * The key has been moved to the secure storage
   */
  device: JsonString<DeviceRecord>;
  addressHistoryCollection: JsonString<AddressHistoryCollection>;
  lastBlockchainNetworkWarningSuggestionTs: JsonString<LastBlockChianNetworkSuggestionTs>;
  ads: JsonString<AdsRecord>;
  INSTALL_IDENTIFICATION: JsonString<InstallIdentificationRecord>;
  PREFERENCES: never;
  AUTH: never;
  LAST_AFFILIATE_SHOW: never;
  ADVERT_BANNERS_HISTORY: never;
  filterOption: JsonString<FilterOption>;
};

type InstallIdentificationRecord = {
  installReferrer?: string;
};

export type AddressHistoryCollection = {
  entries: AddressHistoryEntry[];
};

export type AddressHistoryEntry = [address: string, updatedAt: Millisecond];

export type LastBlockChianNetworkSuggestionTs = {
  [network: string]: {ts?: Millisecond} | undefined;
};

export type AdsRecord = {
  bySpotById?: {
    [Id in AdvertId]?: {
      bySpot?: {
        [Spot in CompactAdvertSpot]?: AdRecord;
      };
    };
  };
};

export enum CompactAdvertSpot {
  splash_mobile = 0,
  splash_desktop = 1,
  new_modal_mobile = 2,
  new_modal_desktop = 3,
  install_native_mobile = 4,
  install_native_desktop = 5,
  install_telegram_mobile = 6,
  install_telegram_desktop = 7,
}

export type AdRecord = {
  /**
   * The time the ad was suspended at.
   */
  at?: Second;
};
