import {isNil} from 'lodash';

import type {CryptoCurrencyCode, DecimalString} from '../Money';
import type {AddressNetwork, AddressType} from './AddressInfo';
import type {InOutCurrency} from './InOutCurrency';

export type CryptoCurrency<C extends CryptoCurrencyCode = CryptoCurrencyCode> =
  {
    code: C;
    /**
     * @example "Bitcoin"
     */
    decimals?: number;
    name: string;
    options: CryptoCurrencyOptions;
  };

export type CryptoCurrencyExtra<
  C extends CryptoCurrencyCode = CryptoCurrencyCode,
> = {
  currency: C;
  blockchain: string;
  network: string;
  decimals: number;
  chain_id: number | null;
  contract: string | null;
};

export type CryptoCurrencyOptions = {
  withdraw: boolean;
  incoming: boolean;
  change: boolean;
  addable: boolean;
  add_addresses: boolean;
  // if undefined, free withdrawal is unavailable
  min_free_withdrawal_amount: DecimalString | undefined;
  min_withdrawal_amount: DecimalString;
  is_visible_changeable: boolean;
  show_network: boolean;
  default_network: AddressNetwork | undefined;
  default_purchase_network: AddressNetwork | undefined;
  default_type: AddressType | undefined;
  // currencies_in and currencies_out are non-empty arrays
  currencies_in: InOutCurrency[];
  currencies_out: InOutCurrency[];
  deposit_is_active?: boolean;
};

export const isFreeWithdrawalAvailable = (c: CryptoCurrency) =>
  !isNil(c.options.min_free_withdrawal_amount);

export const isNetworkChangePossible = (
  currency: CryptoCurrency,
  direction: 'in' | 'out',
) => {
  const options = currency.options;
  const currencies =
    direction === 'out' ? options.currencies_out : options.currencies_in;
  return currencies.length > 1;
};

export const isNetworkShown = (
  currency: CryptoCurrency,
  direction: 'in' | 'out',
): boolean => {
  return (
    currency.options.show_network ||
    isNetworkChangePossible(currency, direction)
  );
};
