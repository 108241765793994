import type {
  CryptoAddress,
  CryptoCurrency,
  CryptoCurrencyCode,
  CurrenciesRateHistoryStore,
  CurrenciesRateStore,
  CurrencyStore,
  FiatCurrencyCode,
  JsonRpcClient,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  WalletId,
  WalletStore,
} from '@ncwallet-app/core';
import {
  getHistoryItemChange,
  RateHistoryPeriod,
  toCurrencyDescriptionFromCrypto,
  toCurrencyDescriptionFromFiat,
  toLineChartDatum,
} from '@ncwallet-app/core';
import type {AddressParams} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {DepositType} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {getHistoryFromResponse} from '@ncwallet-app/core/src/dataStores/CurrenciesRateHistoryStore/AcceptedRateHistoryResponse';
import type {FlashMessage} from '@ncwallet-app/core/src/FlashMessage';
import type {Localization} from '@ncwallet-app/core/src/Localization';
import {getDefaultAddressInfo} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import type {FiatCurrency} from '@ncwallet-app/core/src/NCWalletServer/FiatCurrency';
import {
  getDefaultAddressParams,
  getDefaultBuyCurrency,
} from '@ncwallet-app/core/src/NCWalletServer/InOutCurrency';
import type {CurrencySelectionListItemData} from '@ncwallet-app/ui';
import {isEmpty} from 'lodash';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import type {CurrencyHistoryRefresher} from '../../../shared/CurrencyHistoryRefresher';

// eslint-disable-next-line import-x/prefer-default-export
export class SelectionForBuyCryptoBindingState {
  @observable private _baseFiat: FiatCurrencyCode | undefined;

  @observable private _isLoading = false;
  @observable private _isSubmitting = false;

  @computed get currencies(): CurrencySelectionListItemData[] | undefined {
    const currencies = this._root.currencyStore.getCryptoCurrencies();
    if (!this._baseFiat || currencies === undefined) {
      return;
    }
    const addableCurrencies = currencies.filter(
      ({options}) => options.deposit_is_active,
    );
    const baseFiat = this._root.currencyStore.getFiatCurrency(this._baseFiat);

    if (!baseFiat) {
      return;
    }

    return addableCurrencies.map(c =>
      this.toCurrencySelectionListItemData(baseFiat, c),
    );
  }

  constructor(
    protected readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly walletStore: WalletStore;
      readonly currencyStore: CurrencyStore;
      readonly currenciesRateStore: CurrenciesRateStore;
      readonly currenciesRateHistoryStore: CurrenciesRateHistoryStore;
      readonly flashMessage: FlashMessage;
      readonly localization: Localization;
    },
    private readonly historyRefresher: CurrencyHistoryRefresher,
  ) {
    makeObservable(this);
    this.historyRefresher.activate();
  }

  subscribe() {
    return this.historyRefresher.subscribe({doNotRefreshRates: true});
  }

  getCurrencies = () => this.currencies;

  isLoading = () => this._isLoading;
  isSubmitting = () => this._isSubmitting;

  @action.bound
  async refresh(baseFiat: FiatCurrencyCode) {
    this._baseFiat = baseFiat;

    this._isLoading = true;
    const [fiatsRes, cryptosRes, walletsRes] = await Promise.all([
      this._root.currencyStore.refreshFiatCurrencies(),
      this._root.currencyStore.refreshCryptoCurrencies(),
      this._root.walletStore.refreshWallets(),
    ]);

    runInAction(() => {
      this._isLoading = false;
    });

    if (!fiatsRes.success || !cryptosRes.success || !walletsRes.success) {
      return;
    }

    const _currencies = (this.currencies || []).map(({cryptoCurrency}) => {
      return cryptoCurrency.code as CryptoCurrencyCode;
    });

    await this._root.currenciesRateStore.batchRefreshRates(
      _currencies.map(code => ({from: code, to: baseFiat})),
    );

    this.historyRefresher.setStaleCodes(_currencies);
  }

  getAddressParamsByCurrencyCode(code: CryptoCurrencyCode):
    | (AddressParams & {
        depositType: DepositType;
        walletId: WalletId;
        address: CryptoAddress;
      })
    | null {
    const currency = this._root.currencyStore.getCryptoCurrency(code);

    if (!currency) {
      return null;
    }

    const wallets = this._root.walletStore.getWalletsByCode(code);
    const defaultBuyCurrency = getDefaultBuyCurrency(currency);
    const addressInfo = getDefaultAddressInfo(
      wallets[0].addresses,
      defaultBuyCurrency.network,
      defaultBuyCurrency.default_type,
    );

    if (!addressInfo) {
      return null;
    }

    return {
      depositType: DepositType.Buy,
      walletId: wallets[0].id,
      address: addressInfo.address,
      ...getDefaultAddressParams(defaultBuyCurrency),
    };
  }

  @action.bound
  async createWalletIfNeeded(item: CurrencySelectionListItemData) {
    this._isSubmitting = true;

    const cryptoCode = item.cryptoCurrency.code as CryptoCurrencyCode;
    const wallets = this._root.walletStore.getWalletsByCode(cryptoCode);

    if (isEmpty(wallets)) {
      const res = await this._root.walletStore.createWallet(cryptoCode);

      if (res.success) {
        this._root.flashMessage.showMessage({
          title: this._root.localization.executeTemplate('wallet.added', {
            currency: item.cryptoCurrency.name,
          }),
          variant: 'success',
        });
      }
    }

    runInAction(() => {
      this._isSubmitting = false;
    });
  }

  private toCurrencySelectionListItemData(
    baseFiat: FiatCurrency,
    c: CryptoCurrency,
  ): CurrencySelectionListItemData {
    const getHistory = () => {
      const res = this._root.currenciesRateHistoryStore.getLatestHistoryRes(
        c.code,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._baseFiat!,
        RateHistoryPeriod.Month,
      );
      return res && getHistoryFromResponse(res);
    };

    return {
      id: c.code,
      valueCurrency: toCurrencyDescriptionFromFiat(baseFiat),
      cryptoCurrency: toCurrencyDescriptionFromCrypto(c),
      value: (
        this._root.currenciesRateStore.getRate(c.code, baseFiat.code)?.rate ?? 0
      ).toString(),
      getFiatCurrency: () => {
        const fiat = this._root.currencyStore.getFiatCurrency(baseFiat.code);
        return fiat && toCurrencyDescriptionFromFiat(fiat);
      },
      getChartData: () => getHistory()?.map(toLineChartDatum),
      getDiff: () => {
        const rate = this._root.currenciesRateStore.getRate(
          c.code,
          baseFiat.code,
        );
        const history = getHistory();
        return (
          rate &&
          history &&
          getHistoryItemChange(
            [...history, {rate: rate.rate, timestamp: rate.last_updated}],
            true,
          )
        );
      },
      getRate: () => {
        return this._root.currenciesRateStore.getRate(c.code, baseFiat.code)
          ?.rate;
      },
    };
  }
}
