import {FULFILLED, useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, runInAction} from 'mobx';
import {useEffect, useState} from 'react';

import {CurrencyHistoryRefresher} from '../../../shared/CurrencyHistoryRefresher';
import {useNavigationGetIsFocused} from '../../hooks';
import {SelectionForBuyCryptoBindingState} from './SelectionForBuyCryptoBindingState';

// eslint-disable-next-line import-x/prefer-default-export
export const useSelectionForBuyCryptoBindingState = () => {
  const root = useRoot();
  const [state] = useState(
    () =>
      new SelectionForBuyCryptoBindingState(
        root,
        new CurrencyHistoryRefresher(root),
      ),
  );

  useEffect(() => state.subscribe(), [state]);

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  useEffect(
    () =>
      autorun(() => {
        if (
          getIsReady() &&
          getIsFocused() &&
          root.accountStore.state?.status === FULFILLED
        ) {
          const {base_fiat} = root.accountStore.state.result;
          runInAction(() => {
            void state.refresh(base_fiat);
          });
        }
      }),
    [getIsFocused, getIsReady, root, state],
  );

  return state;
};
