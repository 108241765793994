import {useStrings} from '@ncwallet-app/core';
import {createSwitchNavigator} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {LargeSwitchParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import {observer} from 'mobx-react-lite';
import React from 'react';

import CompositeSwitchBinding from './bindings/CompositeSwitchBinding';

export default observer(function LargeSwitchBinding() {
  const strings = useStrings();

  return (
    <Navigator initialRouteName="ListWallets">
      <Screen
        name="FallBack"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.home'],
        }}
      />
      <Screen
        name="ListWallets"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.home'],
        }}
      />
      <Screen
        name="ListNotificationSettings"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.notifications'],
        }}
      />
      <Screen
        name="ShowProfile"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.profile'],
        }}
      />
      <Screen
        name="PromptAffiliateProgram"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.profile'],
        }}
      />
      <Screen
        name="ListSecuritySettings"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.security'],
        }}
      />
      <Screen
        name="TwoFaWelcome"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.security'],
        }}
      />
      <Screen
        name="ListLimits"
        component={CompositeSwitchBinding}
        options={{
          title: withAppSuffix(strings['screenTitle.limitsSettings']),
        }}
      />
      <Screen
        name="PromptNewWallet"
        component={CompositeSwitchBinding}
        options={{
          title: withAppSuffix(strings['currencySelection.addCoin']),
        }}
      />
      <Screen
        name="PromptBuyCrypto"
        component={CompositeSwitchBinding}
        options={{
          title: withAppSuffix(strings['buyCrypto.title']),
        }}
      />
      <Screen
        name="ShowWallet"
        component={CompositeSwitchBinding}
        options={{
          title: withAppSuffix(strings['screenTitle.wallets']),
        }}
      />
      <Screen
        name="ListInputAddresses"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.receive'],
        }}
      />
      <Screen
        name="ShowQrToReceiveCrypto"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.receive'],
        }}
      />
      <Screen
        name="PromptCryptoToReceive"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.receive'],
        }}
      />

      <Screen
        name="PromptInputNetwork"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.receive'],
        }}
      />

      <Screen
        name="PromptAddressFormat"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.receive'],
        }}
      />

      <Screen
        name="PromptReceiveNetwork"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.receive'],
        }}
      />
      <Screen
        name="PromptCryptoToSend"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.sendCrypto'],
        }}
      />
      <Screen
        name="PromptOutputAddress"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.sendCrypto'],
        }}
      />
      <Screen
        name="PromptOutputNetwork"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.sendCrypto'],
        }}
      />
      <Screen
        name="PromptCommissionForSending"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.sendCrypto'],
        }}
      />
      <Screen
        name="PromptConfirmationToSendCrypto"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.sendCrypto'],
        }}
      />
      <Screen
        name="PromptOtpToSendCrypto"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.sendCrypto'],
        }}
      />
      <Screen
        name="NotifyAboutSuccessfulSending"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.sendCrypto'],
        }}
      />
      <Screen
        name="PromptSourceCryptoToExchange"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.exchange'],
        }}
      />
      <Screen
        name="PromptExchangeReceipt"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.exchange'],
        }}
      />
      <Screen
        name="PromptCryptoToExchange"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.exchange'],
        }}
      />
      <Screen
        name="PromptConfirmationForExchange"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.exchange'],
        }}
      />
      <Screen
        name="PromptOtpToExchange"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.exchange'],
        }}
      />

      <Screen
        name="NotifyAboutSuccessfulExchange"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.exchange'],
        }}
      />
      <Screen
        name="ListHistory"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.history'],
        }}
      />
      <Screen
        name="ListHistoryFilters"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.history'],
        }}
      />
      <Screen
        name="PromptWalletGroupToFilterHistory"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.history'],
        }}
      />
      <Screen
        name="PromptWalletToFilterHistory"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.history'],
        }}
      />
      <Screen
        name="PromptTransactionKindToFilterHistory"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.history'],
        }}
      />
      <Screen
        name="PromptPeriodToFilterHistory"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.history'],
        }}
      />
      <Screen
        name="ShowTransaction"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenBaseTitle.history'],
        }}
      />
      <Screen
        name="ListQrCodeHistory"
        component={CompositeSwitchBinding}
        options={{
          title: strings['screenTitle.qrCodeHistory'],
        }}
      />

      <Screen
        name="GeneratePayMeLink"
        component={CompositeSwitchBinding}
        options={{
          title: strings['generatePayMeLink.screenTitle'],
        }}
      />
      <Screen name="Debug" component={CompositeSwitchBinding} />
      <Screen name="ListLogs" component={CompositeSwitchBinding} />
      <Screen name="ListEnvironment" component={CompositeSwitchBinding} />
      <Screen name="PromptEnvironmentForm" component={CompositeSwitchBinding} />
      <Screen name="RedirectToSend" component={CompositeSwitchBinding} />
    </Navigator>
  );
});

const withAppSuffix = (text: string) => {
  return `${text} | NC Wallet`;
};

const {Navigator, Screen} = createSwitchNavigator<LargeSwitchParamList>();
