import type {WalletId, WalletListItemData} from '@ncwallet-app/core';
import {useRoot} from '@ncwallet-app/core';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import {autorun, reaction, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {useTabBarLayout} from '../../../../../components';
import {useNavigationGetIsFocused} from '../../../../../Navigation/hooks';
import SmallListWalletsScreen from '../../../../../screens/SmallListWalletsScreen';
import {WalletListFilter} from '../../../../../screens/SmallListWalletsScreen/SmallListWalletsScreen';
import type {SmallBottomTabBindingProps} from '../SmallBottomTabBindingProps';

export type ListWalletsBindingProps = SmallBottomTabBindingProps<'ListWallets'>;

export default observer(function ListWalletsBinding(
  props: ListWalletsBindingProps,
) {
  const {navigation} = props;
  const onWalletSelect = useCallback(
    (d: WalletListItemData) => {
      navigation.navigate('ShowWallet', {
        walletId: d.id as WalletId,
        currencyCode: d.cryptoCurrencyCode,
      });
    },
    [navigation],
  );
  const layout = useTabBarLayout();
  const extra = useMemo(() => ({bottom: layout.height}), [layout]);
  const {walletsState, lastTransactionStore, rpcTimeoutErrorVisibility} =
    useRoot();
  const [walletsListFilter] = useState(WalletListFilter.All);
  const onCurrencyAdd = useCallback(() => {
    navigation.navigate('PromptNewWallet');
  }, [navigation]);

  const onBuyCrypto = useCallback(() => {
    navigation.navigate('PromptBuyCrypto');
  }, [navigation]);

  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();

  const controllerRef = useRef<AbortController | undefined>();
  useEffect(() => {
    const autorunDisposer = autorun(() => {
      controllerRef.current?.abort();
      controllerRef.current = new AbortController();
      if (getIsReady() && getIsFocused()) {
        runInAction(() => {
          void walletsState.refresh({signal: controllerRef.current?.signal});
          walletsState.activateBackgroundRefresh();
        });
      } else {
        runInAction(() => {
          walletsState.deactivateBackgroundRefresh();
        });
      }
    });
    return () => {
      autorunDisposer();
      walletsState.deactivateBackgroundRefresh();
      controllerRef.current?.abort();
    };
  }, [getIsFocused, getIsReady, walletsState, lastTransactionStore]);

  const handleRefresh = useCallback(() => {
    if (getIsReady() && getIsFocused()) {
      runInAction(() => {
        void walletsState.manualRefresh({
          signal: controllerRef.current?.signal,
        });
      });
    }
  }, [getIsFocused, getIsReady, walletsState]);

  useEffect(
    () =>
      reaction(
        () => walletsState.isTimeoutError,
        shouldFire => {
          if (shouldFire) {
            rpcTimeoutErrorVisibility.registerAction(handleRefresh);
          }
        },
      ),
    [walletsState, rpcTimeoutErrorVisibility, handleRefresh],
  );

  const navigateToTransaction = useCallback(() => {
    navigation.navigate('ListHistory');
  }, [navigation]);

  const handleFilterPress = useCallback(() => {
    navigation.navigate('ListWalletOptions');
  }, [navigation]);

  return (
    <SmallListWalletsScreen
      getTotal={walletsState.getTotal}
      getWallets={walletsState.getWallets}
      getIsManualRefresh={walletsState.getIsManualRefresh}
      onWalletSelect={onWalletSelect}
      onCurrencyAdd={onCurrencyAdd}
      onBuyCrypto={onBuyCrypto}
      extra={extra}
      getIsLoading={walletsState.getIsLoading}
      onRefresh={handleRefresh}
      hideWallet={walletsState.hideWallet}
      onLastTransactionPress={navigateToTransaction}
      isWalletFilterAvailable={walletsState.isWalletsFilterAvailable}
      selectedWalletListFilter={walletsListFilter}
      selectedFilterOption={walletsState.walletsFilterOption}
      onFilterPress={handleFilterPress}
    />
  );
});
