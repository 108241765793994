import {urlLinks, useRoot, useStrings} from '@ncwallet-app/core';
import {useAccountState} from '@ncwallet-app/core/src/AccountStore';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import UserSettingsScreen from '../../../screens/UserSettingsScreen/UserSettingsScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ListMenuCategoriesBindingProps =
  SmallHomeStackBindingProps<'ListMenuCategories'>;

export default observer(function ListMenuCategoriesBinding(
  props: ListMenuCategoriesBindingProps,
) {
  const {navigation} = props;
  const root = useRoot();
  const strings = useStrings();

  const signOut = useCallback(() => {
    navigation.navigate('PromptSignOut');
  }, [navigation]);
  const goBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.replace('Root');
  }, [navigation]);

  const goToLanguageSettings = useCallback(() => {
    navigation.navigate('PromptLanguage');
  }, [navigation]);

  const goToProfileSettings = useCallback(() => {
    navigation.navigate('ShowProfile');
  }, [navigation]);

  const goToSecuritySettings = useCallback(() => {
    navigation.navigate('ListSecuritySettings');
  }, [navigation]);

  const goToLimitsSettings = useCallback(() => {
    navigation.navigate('ListLimits');
  }, [navigation]);

  const goToNotificationSettings = useCallback(() => {
    navigation.navigate('ListNotificationSettings');
  }, [navigation]);

  const goToStyleSelect = useCallback(() => {
    navigation.navigate('PromptThemeSelect');
  }, [navigation]);

  const goToAffiliate = useCallback(() => {
    navigation.navigate('PromptAffiliateProgram');
  }, [navigation]);

  const goToRate = useCallback(() => {
    navigation.navigate('PromptRate');
  }, [navigation]);

  const goToHelp = useCallback(
    () => root.location.open(urlLinks.support),
    [root.location],
  );

  const state = useAccountState();
  return (
    <UserSettingsScreen
      onAffiliatePress={goToAffiliate}
      onClosePress={goBack}
      goToStyle={goToStyleSelect}
      onLanguagePress={goToLanguageSettings}
      onProfilePress={goToProfileSettings}
      onSecurityPress={goToSecuritySettings}
      onLimitsPress={goToLimitsSettings}
      onNotificationPress={goToNotificationSettings}
      onHelpPress={goToHelp}
      onRatePress={goToRate}
      onLogoutPress={signOut}
      displayName={state.account?.name || strings['noNickname']}
      userId={state.account?.user_id || ''}
      insets={SafeAreaInset.BOTTOM}
    />
  );
});
