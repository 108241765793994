import type {LocaleKeys} from '@nc-wallet/localization/locale/LocaleStrings';
import type {
  CryptoAddress,
  CryptoCurrencyCode,
  CurrencyStore,
  DecimalString,
  JsonRpcClient,
  LinkingOptionsProvider,
  Location,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  Url,
} from '@ncwallet-app/core';
import type {Appearance} from '@ncwallet-app/core/src/Appearance';
import type {
  ErrorDetails,
  ErrorParser,
} from '@ncwallet-app/core/src/ErrorParser';
import type {Localization} from '@ncwallet-app/core/src/Localization';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {DepositProvider} from '@ncwallet-app/core/src/NCWalletServer/WalletDeposit/WalletDepositCreateLink';
import {isChromeExtension} from '@ncwallet-app/navigation/src/Navigation/hooks/isChromeExtension';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {Platform} from 'react-native';
// eslint-disable-next-line import-x/no-extraneous-dependencies
import {InAppBrowser} from 'react-native-inappbrowser-reborn';

enum BuyLinkError {
  DepositForbidden = 7001,
  DepositCreationError = 7002,
  DepositSettingsNotFound = 7003,
  DepositInactive = 7004,
  InvalidDepositIp = 7005,
  InvalidDepositAmount = 7006,
}

export const BUY_LINK_ERROR_TO_LOCALE: Record<BuyLinkError, LocaleKeys> = {
  [BuyLinkError.DepositForbidden]: 'error.buyCrypto.DepositForbidden',
  [BuyLinkError.InvalidDepositIp]: 'error.buyCrypto.DepositForbidden',
  [BuyLinkError.DepositCreationError]: 'error.buyCrypto.DepositCreationError',
  [BuyLinkError.DepositSettingsNotFound]:
    'error.buyCrypto.DepositSettingsNotFound',
  [BuyLinkError.DepositInactive]: 'error.buyCrypto.DepositInactive',
  [BuyLinkError.InvalidDepositAmount]: 'error.buyCrypto.Limits',
};

const VALID_AMOUNT_REGEXP = /^[0-9]*[,.]?[0-9]{0,8}$/;

export class BuyLinkBindingState {
  @observable private _amount: DecimalString | undefined;
  @observable private _provider: DepositProvider = DepositProvider.Moonpay;
  @observable private _currency: CryptoCurrencyCode | undefined;
  @observable private _isLoading = false;
  @observable private _errorDetails: ErrorDetails | undefined;
  @observable private _address: CryptoAddress | undefined;
  @observable private _network: AddressNetwork | undefined;

  constructor(
    private readonly _root: {
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
      readonly errorParser: ErrorParser;
      readonly currencyStore: CurrencyStore;
      readonly linkingOptionsProvider: LinkingOptionsProvider;
      readonly location: Location;
      readonly localization: Localization;
      readonly appearance: Appearance;
    },
  ) {
    makeObservable(this);
  }

  @computed get amount() {
    return this._amount;
  }

  @computed get currencyCode() {
    return this._currency;
  }

  @computed get errorText() {
    return this._errorDetails?.summary;
  }

  @computed get isDepositAllowed() {
    if (!this._currency) {
      return;
    }
    return (
      this._root.currencyStore.getCryptoCurrency(this._currency)?.options
        .deposit_is_active === true
    );
  }

  @computed get disabled() {
    return (
      this._amount === undefined || this._amount === '0' || this._isLoading
    );
  }

  @action.bound
  setAmount(amount: DecimalString | undefined): void {
    if (this._errorDetails) {
      this._errorDetails = undefined;
    }

    if (amount === undefined) {
      this._amount = undefined;
      return;
    }

    const isValid = VALID_AMOUNT_REGEXP.test(amount);
    if (!isValid) {
      return;
    }
    this._amount = amount.replace(',', '.');
  }

  @action.bound
  setCurrency(currency: CryptoCurrencyCode): void {
    this._currency = currency;
  }

  @action.bound
  setAddress(address: CryptoAddress): void {
    this._address = address;
  }

  @action.bound
  setNetwork(network: AddressNetwork): void {
    this._network = network;
  }

  @action.bound
  private async _getWebViewLink(): Promise<Url | undefined> {
    const currency = this._currency as CryptoCurrencyCode;
    const amount = this._amount;
    const provider = this._provider;

    runInAction(() => {
      this._isLoading = true;
      this._errorDetails = undefined;
    });
    const res = await this._root.ncWalletJsonRpcClient.call(
      'wallets.deposit.create_link',
      {
        currency,
        provider,
        address: this._address,
        network: this._network,
        ...((Number(amount) === 0 || Number(amount) > 0) && {amount}),
      },
    );

    if (res.success) {
      runInAction(() => {
        this._isLoading = false;
      });
    }

    if (!res.success) {
      const error = this._root.errorParser.describe(res.left);
      const errorCode = error.code as BuyLinkError | undefined;

      if (errorCode && Object.values(BuyLinkError).includes(errorCode)) {
        runInAction(() => {
          this._isLoading = false;
          this._errorDetails = {
            code: error.code,
            summary: this._parseLimitsError(
              errorCode,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
              (res.left as any).body?.data,
            ).replace('{currency}', this._currency as string),
          };
        });
      }
      return;
    }

    return res.right.link as Url;
  }

  @action.bound
  async onSubmit(): Promise<boolean> {
    const url = await this._getWebViewLink();

    if (!url) {
      return false;
    }

    if (isChromeExtension() || Platform.OS === 'web') {
      await this._root.location.open(url);
      return true;
    }

    if (await InAppBrowser.isAvailable()) {
      await InAppBrowser.open(url, {
        preferredBarTintColor: this._root.appearance.theme.palette.primary,
        showInRecents: true,
      });
    } else {
      await this._root.location.open(url);
    }
    return true;
  }

  private _parseLimitsError = (
    erorCode: BuyLinkError,
    data?: {
      min_coin: string;
      max_coin: string;
    },
  ): string => {
    if (erorCode !== BuyLinkError.InvalidDepositAmount) {
      return this._root.localization.getTranslation(
        BUY_LINK_ERROR_TO_LOCALE[erorCode],
      );
    }

    let res = this._root.localization.getTranslation(
      'error.buyCrypto.DepositForbidden',
    );
    if (data === undefined) {
      return res;
    }

    const {min_coin, max_coin} = data;

    if (min_coin && max_coin) {
      res = this._root.localization
        .getTranslation('error.buyCrypto.Limits')
        .replace('{min}', min_coin)
        .replace('{max}', max_coin);
    } else if (min_coin) {
      res = this._root.localization
        .getTranslation('error.buyCrypto.Limits.Min')
        .replace('{min}', min_coin);
    } else if (max_coin) {
      res = this._root.localization
        .getTranslation('error.buyCrypto.Limits.Max')
        .replace('{max}', max_coin);
    }
    return res;
  };
}
