import type {AdvertAction, Millisecond} from '@ncwallet-app/core';
import {AdvertType, useStyles, variance} from '@ncwallet-app/core';
import type {AdvertContext} from '@ncwallet-app/core/src/Advert';
import type {Uri} from '@ncwallet-app/core/src/units';
import {
  Button,
  ButtonVariant,
  MD_BREAKPOINT,
  ModalContainer,
  TouchableOpacity,
} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import type {ImageProps, ImageStyle, StyleProp} from 'react-native';
import {Image, Platform, StyleSheet, Text, View} from 'react-native';
import type {SvgProps} from 'react-native-svg';
import {SvgUri} from 'react-native-svg';

import Markdown from '../../shared/Markdown';
import PushAdvertSplashClose from './PushAdvertSplashClose';

export type PushAdvertSplashScreen = {
  onButtonActionPress: (action: AdvertAction) => void;
  onClose: () => void;
  deadline?: Millisecond;
  context: AdvertContext;
};

export default observer(function AdvertSplashScreen({
  context,
  onButtonActionPress,
  onClose,
  deadline,
}: PushAdvertSplashScreen) {
  const isDesktop = context.advert.type === AdvertType.Desktop;
  const markdownStyles = useStyles(theme => ({
    paragraph: {
      ...theme.fontByWeight('400'),
      textAlign: 'center',
      color: theme.palette.textAdditional1,
      marginTop: 0,
      marginBottom: 20,
      fontSize: 16,
      lineHeight: 24,
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          fontSize: 18,
          lineHeight: 28,
        },
      }),
    },
  }));

  const actionList = useMemo(() => {
    if (!context.advert.actions) {
      return [];
    }
    return context.advert.actions.map((action, index) => (
      <ModalButton
        isDesktop={isDesktop}
        key={action.link}
        Icon={
          action.icon
            ? _ => <ButtonIcon {..._} uri={action.icon as Uri} />
            : undefined
        }
        variant={
          index % 2 === 0 ? ButtonVariant.Primary : ButtonVariant.Default
        }
        onPress={() => {
          onButtonActionPress(action);
        }}
        title={context.text.actions?.[index].title || ''}
      />
    ));
  }, [
    context.advert.actions,
    context.text.actions,
    isDesktop,
    onButtonActionPress,
  ]);

  return (
    <Backdrop>
      <OutsideClickArea onPress={onClose} />
      <Container>
        {context.images.image && (
          <Picture source={{uri: context.images.image}} resizeMode="cover" />
        )}
        <Body isDesktop={isDesktop}>
          <Title>{context.text.title}</Title>
          {context.text.body && (
            <Markdown styles={{paragraph: markdownStyles.paragraph}}>
              {context.text.body}
            </Markdown>
          )}
          <FooterView isDesktop={isDesktop}>{actionList}</FooterView>
        </Body>
        <CloseBtn>
          <PushAdvertSplashClose onClose={onClose} deadline={deadline} />
        </CloseBtn>
      </Container>
    </Backdrop>
  );
});

export const OutsideClickArea = variance(TouchableOpacity)(
  () => ({
    root: {},
  }),
  () => ({
    containerStyle: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'transparent',
    },
  }),
);

const ButtonIcon = (
  props: SvgProps & {
    uri: Uri;
  },
) => {
  const adoptSvgProps = (_: SvgProps): ImageProps => ({
    style: StyleSheet.flatten([
      _.style as StyleProp<ImageStyle>,
      styles.buttonIcon,
    ]),
  });
  if (Platform.OS === 'web') {
    return <Image {...adoptSvgProps(props)} source={{uri: props.uri}} />;
  }
  return <SvgUri {...props} style={[props.style, styles.buttonIcon]} />;
};

const styles = StyleSheet.create({
  buttonIcon: {
    width: 20,
    height: 20,
  },
});

const Backdrop = variance(ModalContainer)(theme => ({
  root: {
    backgroundColor: theme.chroma('#000000').alpha(0.7).hex(),
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
}));

const Container = variance(View)(theme => ({
  root: {
    position: 'relative',
    maxWidth: undefined,
    width: '100%',
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        maxWidth: 620,
        flex: undefined,
        borderRadius: 8,
        overflow: 'hidden',
        width: '100%',
      },
    }),
  },
}));

const Picture = variance(Image)(() => ({
  root: {
    width: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 252,
  },
}));

const Body = variance(View)(theme => ({
  root: {
    paddingTop: 24,
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: theme.palette.background,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  isDesktop: {
    paddingHorizontal: 32,
    paddingBottom: 24,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 22,
    lineHeight: 32,
    marginBottom: 10,
    textAlign: 'center',
    color: theme.palette.textPrimary,
  },
}));

const FooterView = variance(View)(theme => ({
  root: {
    backgroundColor: theme.palette.background,
    flexDirection: 'column',
    marginVertical: -5,
    alignItems: 'stretch',
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        alignItems: 'center',
      },
    }),
  },
  isDesktop: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: -10,
  },
}));

const ModalButton = variance(Button)(theme => ({
  root: {
    marginVertical: 5,
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        minWidth: 345,
      },
    }),
  },
  isDesktop: {
    marginHorizontal: 10,
  },
}));

const CloseBtn = variance(View)(() => ({
  root: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
}));
