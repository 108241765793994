import {type FilterOption, useStrings, variance} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/core/src/Layout';
import {Separator} from '@ncwallet-app/ui/';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Platform, View} from 'react-native';

import ListWalletOptionsItem from './ListWalletOptionsItem';

export type ListWalletOptionsProps = {
  onFilterOptionPress: (selectedOption: FilterOption) => void;
  selectedFilterOption: FilterOption;
};

export default observer(function ListWalletOptions(
  props: ListWalletOptionsProps,
) {
  const {selectedFilterOption, onFilterOptionPress} = props;
  const strings = useStrings();
  return (
    <RootView>
      <ListWalletOptionsItem
        isActive={selectedFilterOption === 'all'}
        kind={'all'}
        label={strings['coinFilter.All']}
        onPress={() => {
          onFilterOptionPress('all');
        }}
      />
      <ListSeparator />
      <ListWalletOptionsItem
        isActive={selectedFilterOption === 'positive_funds'}
        kind={'positive_funds'}
        label={strings['coinFilter.withFunds']}
        onPress={() => {
          onFilterOptionPress('positive_funds');
        }}
      />
      {Platform.OS !== 'web' && <ListSeparator />}
    </RootView>
  );
});

const RootView = variance(View)(theme => ({
  root: {
    paddingBottom: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingBottom: 0,
      },
    }),
  },
}));

const ListSeparator = variance(Separator)(() => ({
  root: {
    marginLeft: -25,
    marginRight: -25,
  },
}));
