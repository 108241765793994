import {
  isEnoughMoneyForSendOrExchange,
  RateHistoryPeriod,
  useRoot,
  useStrings,
} from '@ncwallet-app/core';
import type {ExchangeReceipt} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {DepositType} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {getDefaultAddressParams} from '@ncwallet-app/core/src/NCWalletServer/InOutCurrency';
import {setStringAsync} from 'expo-clipboard';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useRedirectToNotFoundIfNoWalletWithId} from '../../../../../CommonNavigationContainers/hooks/redirectToNotFoundOnWrongDataHooks';
import {useCurrencyBindingState} from '../../../../../Navigation/HomeStack/CurrencyBinding/useCurrencyBindingState';
import {CurrencyScreen} from '../../../../../screens/CurrencyScreen';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type ShowWalletBindingProps = LargeSwitchBindingProps<'ShowWallet'>;

export default observer(function ShowWalletBinding(
  props: ShowWalletBindingProps,
) {
  const {navigation} = props;
  const {walletId, period = RateHistoryPeriod.Month} = props.route.params;
  const state = useCurrencyBindingState(walletId, period);
  const {flashMessage, walletStore} = useRoot();
  const strings = useStrings();
  useRedirectToNotFoundIfNoWalletWithId(walletId);

  const navigateToReceive = useCallback(() => {
    if (!state.addressInfo) {
      return null;
    }
    navigation.jumpTo('ShowQrToReceiveCrypto', {
      walletId,
      address: state.addressInfo.address,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...getDefaultAddressParams(state.getDefaultOutCurrency()!),
    });
  }, [state, navigation, walletId]);

  const navigateToBuy = useCallback(() => {
    if (!state.addressInfo) {
      return null;
    }
    const defaultCurrency = state.getDefaultBuyCurrency();
    const defaultAddressInfo = state.getDefaultBuyAddressInfo();
    if (defaultCurrency && defaultAddressInfo) {
      navigation.jumpTo('ShowQrToReceiveCrypto', {
        walletId,
        depositType: DepositType.Buy,
        address: defaultAddressInfo.address,
        ...getDefaultAddressParams(defaultCurrency),
      });
    }
  }, [state, navigation, walletId]);

  const navigateToSend = useCallback(() => {
    navigation.jumpTo('PromptOutputAddress', {
      walletId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...getDefaultAddressParams(state.getDefaultOutCurrency()!),
    });
  }, [navigation, state, walletId]);

  const navigateToHistory = useCallback(() => {
    const wallet = state.wallet;
    if (!wallet) {
      return;
    }
    navigation.navigate('ListHistory', {walletId: wallet.id});
  }, [state, navigation]);

  const navigateToExchange = useCallback(() => {
    const params: ExchangeReceipt = {
      walletIdFrom: walletId,
    };
    if (state.wallet && !isEnoughMoneyForSendOrExchange(state.wallet)) {
      const nextWallet = walletStore
        .getWallets()
        ?.find(it => it.id !== walletId);
      params.walletIdTo = nextWallet?.id;
    }
    navigation.jumpTo('PromptExchangeReceipt', params);
  }, [navigation, state.wallet, walletId, walletStore]);

  const handleCopy = useCallback(() => {
    void setStringAsync(state.addressInfo?.address || '');
    flashMessage.showMessage({
      title: strings.copied,
      variant: 'success',
    });
  }, [state.addressInfo?.address, flashMessage, strings]);

  const handleGraphPeriodChange = useCallback(
    (p: RateHistoryPeriod) => {
      navigation.setParams({period: p});
    },
    [navigation],
  );

  return (
    <CurrencyScreen
      walletName={strings['currencyScreen.walletTitle']}
      cryptoCurrency={state.cryptoCurrency}
      cryptoValue={state.cryptoValue}
      fiatRate={state.baseFiatRate}
      fiatCurrency={state.baseFiatCurrency}
      fiatValue={state.baseFiatValue}
      receiveEnabled={state.receiveEnabled()}
      exchangeEnabled={state.exchangeEnabled()}
      buyEnabled={state.buyEnabled()}
      sendEnabled={state.sendEnabled()}
      rateHistory={state.rateHistory}
      onCopy={handleCopy}
      isGraphLoading={state.isGraphLoading}
      onBuyPress={navigateToBuy}
      address={state.addressInfo?.address}
      activePeriod={state.period ?? RateHistoryPeriod.Month}
      onReceivePress={navigateToReceive}
      onSendPress={navigateToSend}
      onExchangePress={navigateToExchange}
      onHistoryPress={navigateToHistory}
      onGraphPeriodChange={handleGraphPeriodChange}
    />
  );
});
