import type {AddressInfo} from '@ncwallet-app/core';
import {
  shortenWalletAddress,
  useDateFormatting,
  variance,
} from '@ncwallet-app/core';
import type {AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {
  LG_BREAKPOINT,
  TouchableListItem,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {ArrowRightSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {isEmpty} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Platform, Text, View} from 'react-native';
import {isTablet} from 'react-native-device-info';

type AddressListItemProps = {
  item: AddressInfo;
  showAddressNetwork: boolean;
  defaultNetwork?: AddressNetwork;
  last?: boolean;
  onAddressPress: () => void;
};

// eslint-disable-next-line import-x/prefer-default-export
export const AddressListItem = observer((props: AddressListItemProps) => {
  const isLg = useIsDimensions('lg');
  const {formatDateTimeWithYear} = useDateFormatting();
  const nameShown =
    props.showAddressNetwork ||
    (props.defaultNetwork && props.item.network !== props.defaultNetwork);

  return (
    <AddressListItemTouchable
      Icon={ArrowRightSvg}
      last={props.last}
      onPress={props.onAddressPress}>
      <AddressListItemBody>
        <AddressListItemTop>
          <AddressContainer>
            {isLg ? (
              isTablet() ? (
                <Address>{shortenWalletAddress(props.item.address)}</Address>
              ) : (
                <Address>{props.item.address}</Address>
              )
            ) : (
              <Address>{shortenWalletAddress(props.item.address)}</Address>
            )}
            <AddressTypeContainer>
              {props.item.type && <AddressType>{props.item.type}</AddressType>}
              {nameShown && <AddressType>{props.item.name}</AddressType>}
            </AddressTypeContainer>
          </AddressContainer>

          {!isEmpty(props.item.description) && isLg && (
            <AddressListItemComment numberOfLines={1}>
              {props.item.description}
            </AddressListItemComment>
          )}
          <CreateAt>{formatDateTimeWithYear(props.item.created_at)}</CreateAt>
        </AddressListItemTop>
        {!isEmpty(props.item.description) && !isLg && (
          <AddressListItemComment offsetTop numberOfLines={1}>
            {props.item.description}
          </AddressListItemComment>
        )}
      </AddressListItemBody>
    </AddressListItemTouchable>
  );
});

const AddressListItemTouchable = variance(TouchableListItem)(theme => ({
  root: {
    padding: 15,
    borderBottomColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
  },

  last: {
    borderBottomWidth: 0,
  },
}));

const AddressTypeContainer = variance(View)(theme => ({
  root: {
    width: 'auto',
    marginLeft: 5,
    alignItems: 'flex-start',
    paddingHorizontal: 5,
    backgroundColor: theme.palette.uiAdditional2,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    borderRadius: 20,
  },
}));

const AddressType = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 11,
    lineHeight: 15,
    color: theme.palette.textAdditional2,
  },
  active: {
    color: theme.palette.uiAdditional2,
    backgroundColor: theme.palette.primary,
    borderColor: theme.palette.primary,
  },
}));

const AddressListItemBody = variance(View)(() => ({
  root: {
    flex: 1,
    paddingRight: 20,
  },
}));

const AddressContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const AddressListItemTop = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexWrap: 'nowrap',
      },
    }),
  },
}));

const AddressListItemComment = variance(Text)(theme => ({
  root: {
    flex: 1,
    ...theme.fontByWeight('400'),
    color: theme.palette.textAdditional3,
    marginLeft: 20,
    marginRight: 20,
  },

  offsetTop: {
    marginTop: 10,
    marginLeft: 0,
    marginRight: 0,
  },
}));

const Address = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 17,
    width: 120,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 'auto',
      },
    }),
  },
}));

const CreateAt = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.textAdditional3,
    ...Platform.select({
      web: {
        whiteSpace: 'nowrap',
      },
    }),
  },
}));
